import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';

import Pagination from './Pagination';
import { KeyOfType } from 'types/typesUtils';

type PaginationType = 'none' | 'top' | 'bottom' | 'both' | 'auto';
type ListProps<T> = {
  className?: string;
  title?: string;
  items: T[];
  key?: KeyOfType<T, string>;
  itemsPerPage?: number;
  renderItem: (item: T, index: number) => JSX.Element;
  renderEmptyList?: () => JSX.Element;
  pagination?: PaginationType;
  showCount?: boolean;
  showHeader?: boolean;
  noYSpace?: boolean;
};

const List = <T,>({
  className = '',
  title,
  items,
  itemsPerPage,
  renderItem,
  renderEmptyList,
  pagination = 'auto',
  showCount = true,
  showHeader = true,
  noYSpace = false,
  key,
}: ListProps<T>): JSX.Element => {
  const [pages, setPages] = useState<T[][]>([]);
  const [selectedPage, setSelectedPage] = useState(0);

  const computedPagination = useMemo<PaginationType>(() => {
    return pagination === 'auto'
      ? itemsPerPage !== undefined && itemsPerPage <= 10
        ? 'bottom'
        : 'both'
      : pagination;
  }, [pagination, itemsPerPage]);

  useEffect(() => {
    const pages =
      itemsPerPage && computedPagination !== 'none'
        ? _.chunk(items, itemsPerPage)
        : [items];
    setPages(pages);
  }, [items, itemsPerPage, computedPagination]);

  const displayedItems =
    pages && pages.length > selectedPage ? pages[selectedPage] : [];

  const renderPagination = (context: PaginationType) => {
    return pages.length > 1 &&
      (context === computedPagination || computedPagination === 'both') ? (
      <Pagination
        currentPage={selectedPage}
        nbPages={pages.length}
        onPageChange={(newPage) => setSelectedPage(newPage)}
      />
    ) : null;
  };

  return (
    <div className={`w-full ${className}`}>
      {showHeader && (
        <div className="flex flex-row items-center justify-between">
          {title && (
            <h3 className="text-xl font-semibold">
              {`${title}`}{' '}
              {showCount && items.length > 1 ? (
                <span className="text-gray-400">{`(${items.length})`}</span>
              ) : null}
            </h3>
          )}
          {renderPagination('top')}
        </div>
      )}
      <div className={noYSpace ? '' : 'space-y-4'}>
        {displayedItems.length > 0
          ? displayedItems.map((item, index) => {
              return (
                <div
                  className="w-full"
                  key={key ? (item[key] as string) : index}
                >
                  {renderItem(item, index)}
                </div>
              );
            })
          : renderEmptyList
          ? renderEmptyList()
          : null}
      </div>
      {pages.length > 1 ? (
        <div className="mt-4">{renderPagination('bottom')}</div>
      ) : null}
    </div>
  );
};

export default List;
