import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/typesUtils';
import _ from 'lodash';
import { ActionRecord, Users } from 'types/types';
import { computeOrderedVotes } from 'model/votes';
import PostBlock from './blocks/PostBlock';
import Button from 'components/Button';
import { database as db } from 'services/firebase';
import Copyable from 'components/Copyable';
import { Quote } from 'assets/icons';

export type BestFeedbacksProps = {
  sessionId: string;
  postsSummaries: Nullable<Record<string, string>>;
  posts: Nullable<Record<string, string>>;
  votes: Nullable<Record<string, string>>;
  postTitles: Nullable<Record<string, string>>;
  participants: Users;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
  marginClasses: string;
  groupOfUser: Nullable<Record<string, string>>;
};

const BestPosts = ({
  sessionId,
  postsSummaries,
  votes,
  posts,
  postTitles,
  participants,
  allowAutomatedSynthesis,
  marginClasses,
  groupOfUser,
}: BestFeedbacksProps) => {
  const [viewAll, setViewAll] = useState(false);
  const { t } = useTranslation();

  const orderedVotes = computeOrderedVotes(votes, posts || {});

  const selectableVotes = orderedVotes.filter(
    ([userId]) => !!groupOfUser?.[userId]
  );

  const resultingVotes =
    selectableVotes.length > 0 ? selectableVotes : orderedVotes;

  const top3PostsIds = resultingVotes.slice(0, viewAll ? undefined : 3);

  const copyExperience = (userId: string) => {
    const userName = participants[userId]?.name || t('users:UnknownUser');
    const postTitle = postTitles?.[userId];
    const post = posts?.[userId];
    const postSummary = postsSummaries?.[userId];

    const textToCopy = postTitle
      ? `${postTitle} - ${userName}
  ${post}`
      : `${userName} - ${post}`;

    const summaryToCopy = postTitle
      ? `${postTitle} - ${userName}
  ${postSummary || ''}`
      : `${userName} - ${post}`;

    return postSummary && postSummary !== 'error' ? summaryToCopy : textToCopy;
  };

  const textToCopy = `${t('sessions:Top3BestPosts')}

${top3PostsIds
  .map(
    ([userId]) => `
${copyExperience(userId)}
`
  )
  .join('')}`;

  const regenerate = async () => {
    const toReset = _.mapValues(
      _.pickBy(postsSummaries || {}, (sum) => sum === 'error'),
      () => null
    );

    if (_.size(toReset) > 0) {
      await db
        .ref(`sessionsNextData/${sessionId}/debrief/summaries/post/`)
        .update(toReset);
    }
  };

  return _.size(top3PostsIds) > 0 ? (
    <div className={`${marginClasses}`}>
      <Copyable top={0} textToCopy={textToCopy}>
        <div className="flex flex-row space-x-2">
          <h2 className="text-xl font-semibold">
            {t('sessions:Top3BestPosts')}
          </h2>

          <Button
            text={viewAll ? t('common:seeLess') : t('common:seeMore')}
            onClick={() => setViewAll((viewAll) => !viewAll)}
            size="sm"
          />
          {allowAutomatedSynthesis ? (
            <Button
              color="gray"
              text={t('sessions:Regenerate')}
              onClick={() => regenerate()}
              size="sm"
            />
          ) : null}
        </div>
      </Copyable>
      <div className="relative rounded-md bg-gray-50 px-4 mt-4 py-8 pb-2 text-lg italic">
        <Quote className="absolute left-0 -top-1 h-10 w-10 text-primary" />
        <div className="space-y-4">
          {top3PostsIds.map(([userId]) => {
            return posts?.[userId] ? (
              <PostBlock
                key={userId}
                sessionId={sessionId}
                allowAutomatedSynthesis={allowAutomatedSynthesis}
                userId={userId}
                post={posts[userId]}
                postSummary={postsSummaries?.[userId] || null}
                postTitle={postTitles?.[userId]}
                user={participants[userId]}
              />
            ) : null;
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default BestPosts;
