import React from 'react';

import AppAdminSessionBrowseScreen from './screens/AppAdminSessionBrowseScreen';
import AppAdminTemplateBrowseScreen from './screens/AppAdminTemplateBrowseScreen';
import AppAdminEditUserTypeScreen from './screens/AppAdminEditUserTypeScreen';
import AppAdminSessionCommunitiesManagementScreen from './screens/AppAdminSessionCommunitiesManagementScreen';
import AppAdminMetricsDashboardScreen from './screens/AppAdminMetricsDashboardScreen';
import AppAdminGlobalParameters from './screens/AppAdminGlobalParameters';
import { AppAdminUserActivityScreen } from './screens/AppAdminUserActivityScreen';

export const TABS = [
  {
    url: 'all_sessions',
    title: 'Sessions',
    renderTab: (props) => <AppAdminSessionBrowseScreen {...props} />,
  },
  {
    url: 'all_templates',
    title: 'Templates',
    renderTab: (props) => <AppAdminTemplateBrowseScreen {...props} />,
  },
  {
    url: 'user_activity',
    title: 'User Activity',
    renderTab: (props) => <AppAdminUserActivityScreen {...props} />,
  },
  {
    url: 'edit_rights',
    title: 'Users',
    renderTab: (props) => <AppAdminEditUserTypeScreen {...props} />,
  },
  {
    url: 'manage_communities',
    title: 'Communities',
    renderTab: (props) => (
      <AppAdminSessionCommunitiesManagementScreen {...props} />
    ),
  },
  {
    url: 'metrics',
    title: 'Metrics',
    renderTab: (props) => <AppAdminMetricsDashboardScreen {...props} />,
  },
  {
    url: 'parameters',
    title: 'Parameters',
    renderTab: (props) => <AppAdminGlobalParameters {...props} />,
  },
];
