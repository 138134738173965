import React, { useState } from 'react';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Activities, SessionLanguage } from 'types/types';
import {
  getActivitiesSessionId,
  isValidNewActivity,
} from 'model/activitiesManagement';

import Selector from 'frameworks/formik/Selector';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';

import { Cog, Plus, Trash } from 'assets/icons';

const InsertActivityForm = ({
  lng,
  close,
  onSubmit,
  activities,
}: {
  lng: SessionLanguage;
  close: () => void;
  onSubmit: (name: string, sourceActivity: string) => Promise<void>;
  activities: Activities;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const sourceSessionKey = getActivitiesSessionId(lng);

  useFirebaseConnect(`sessionsNextTemplates/${sourceSessionKey}/activities`);

  const sourceActivities: Activities | undefined = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextTemplates?.[sourceSessionKey]?.activities
  );

  const filteredSourceActivities = _.pickBy(sourceActivities, (activity) =>
    isValidNewActivity(activity, activities)
  );

  return (
    <Formik
      initialValues={{ name: '', source: 'share' }}
      validateOnMount
      validationSchema={Yup.object({
        name: Yup.string().required(t('form:fieldRequired')),
        source: Yup.string().required(t('form:fieldRequired')),
      })}
      onSubmit={(values) => {
        setLoading(true);
        const { name, source } = values;
        try {
          onSubmit(name, source);
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
          close();
        }
      }}
    >
      <Form className="flex h-full flex-col space-y-4 pb-4">
        <div>
          <Field
            className="w-full border p-2"
            type="text"
            name="name"
            placeholder={t('sessions:activityName')}
          />
          <ErrorMessage
            name="name"
            render={(msg) => <div className="text-xs text-danger">{msg}</div>}
          />
        </div>
        {isLoaded(sourceActivities) ? (
          <Selector
            label={t('sessions:activityType')}
            name="source"
            options={filteredSourceActivities}
            descriptionKey="humanName"
          />
        ) : (
          <Spinner />
        )}
        <div className="flex-grow" />
        <div className="flex w-full items-center justify-end space-x-4">
          <Button
            text={t('common:Cancel')}
            size="sm"
            design="secondary"
            onClick={close}
          />
          <Button
            text={t('common:Insert')}
            type="submit"
            size="md"
            loading={loading}
          />
        </div>
      </Form>
    </Formik>
  );
};

const InsertButton = ({
  onClickInsert,
  lng,
  activities,
}: {
  onClickInsert: (name: string, sourceActivity: string) => Promise<void>;
  lng: SessionLanguage;
  activities: Activities;
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(false);

  const hide = () => setVisible(false);
  const show = () => setVisible(true);

  return (
    <>
      <Modal
        open={visible}
        className="h-[450px] pb-2"
        body={
          <InsertActivityForm
            lng={lng}
            close={hide}
            onSubmit={onClickInsert}
            activities={activities}
          />
        }
      />
      <Tooltip content={t('sessions:insertActivityBelow')}>
        <Plus className="h-5 w-5 stroke-2" onClick={show} />
      </Tooltip>
    </>
  );
};

const DeleteButton = ({
  onClickDelete,
}: {
  onClickDelete: () => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(false);

  const hide = () => setVisible(false);
  const show = () => setVisible(true);

  const handleOk = async () => {
    try {
      await onClickDelete();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Modal
        title={t('sessions:deleteActivityNameTitle')}
        footer={
          <div className="mb-4 flex items-center space-x-4">
            <Button
              text={t('common:Cancel')}
              size="sm"
              design="secondary"
              onClick={hide}
            />
            <Button text={t('common:Ok')} size="md" onClick={handleOk} />
          </div>
        }
        open={visible}
      />
      <Tooltip content={t('common:Delete')}>
        <Trash className="h-5 w-5 stroke-2" onClick={show} />
      </Tooltip>
    </>
  );
};

const ButtonsBar = ({
  onClickDelete,
  onClickInsert,
  onClickSettings,
  lng,
  activities,
}: {
  onClickDelete?: () => Promise<void>;
  onClickInsert?: (name: string, sourceActivityName: string) => Promise<void>;
  onClickSettings: () => void;
  lng: SessionLanguage;
  activities: Activities;
}): JSX.Element => {
  return (
    <div className="ml-2 inline-flex items-center space-x-2">
      {onClickDelete && <DeleteButton onClickDelete={onClickDelete} />}
      {onClickInsert && (
        <InsertButton
          lng={lng}
          onClickInsert={onClickInsert}
          activities={activities}
        />
      )}
      <Cog className="h-5 w-5" onClick={onClickSettings} />
    </div>
  );
};

export default ButtonsBar;
