import List from 'components/List';
import Listbox from 'components/Listbox';
import SearchInput from 'components/SearchInput';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalSearch } from 'services/localSearching';
import { useDeepEqualMemo } from 'utils/utils';

export type UserRow = {
  id: string;
  creator: string;
  creatorEmail: string;
  group: string;
};

export type GroupListViewProps = {
  users: UserRow[];
};

type SortMode = 'group' | 'firstname' | 'lastname';

const sortUsers = (users: UserRow[], sortMode: SortMode): UserRow[] => {
  switch (sortMode) {
    case 'group':
      return _.sortBy(users, 'group');
    case 'firstname':
      return _.sortBy(users, (user) => {
        const firstName = user.creator.split(' ')[0] || user;
        return firstName;
      });
    case 'lastname':
      return _.sortBy(users, (user) => {
        const firstName = user.creator.split(' ')[1] || user;
        return firstName;
      });
  }
};

const GroupListView = ({ users }: GroupListViewProps): JSX.Element => {
  const [sortMode, setSortMode] = useState<SortMode>('group');
  const [searchedUser, setSearchedUser] = useState<string>('');
  const { t } = useTranslation();

  const searchKeys: (keyof UserRow)[] = useMemo(
    () => ['creator', 'creatorEmail', 'group'],
    []
  );

  const usersMemoized = useDeepEqualMemo(_.keyBy(users, 'id')) || {};

  const filteredUsers = useLocalSearch(usersMemoized, searchKeys, searchedUser);

  const sortedUsers = sortUsers(Object.values(filteredUsers), sortMode);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-row space-x-4 flex-wrap">
        <div className="flex-grow">
          <SearchInput
            value={searchedUser}
            onChange={setSearchedUser}
            placeholder={t('facilitator:SearchParticipant')}
          />
        </div>
        <div className="flex flex-row items-center">
          <p className="px-2">{t('common:SortBy')}</p>
          <div className="w-40">
            <Listbox
              size="sm"
              defaultValue={sortMode}
              setValue={setSortMode}
              items={[
                {
                  value: 'group',
                  description: t('facilitator:Group'),
                },
                {
                  value: 'firstname',
                  description: t('facilitator:Firstname'),
                },
                {
                  value: 'lastname',
                  description: t('facilitator:Lastname'),
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="overflow-y-scroll h-full">
        <List
          noYSpace
          key="id"
          items={sortedUsers}
          renderItem={(item, index): JSX.Element => {
            return (
              <div
                className={`py-2 px-4 flex flex-row w-full ${
                  index % 2 === 0 ? 'bg-primary-soft' : ''
                }`}
              >
                <p className="flex-grow">
                  {item.creator}
                  <span className="ml-2 text-sm text-black-soft">
                    {item.creatorEmail}
                  </span>
                </p>

                <p className="pr-4">{item.group}</p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default GroupListView;
