import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import MenuButtons, { Action } from 'components/MenuButtons';
import Button from 'components/Button';

import {
  exportAllUsersProduction,
  exportUsers,
  exportFeedbacks,
} from 'services/export';
import { exportSessionDocs } from 'services/docExport';
import { useParticipants } from 'model/sessions';
import { useTopics } from 'model/productions';

import { ChevronUp, DocumentText, Table } from 'assets/icons';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';

export type ExportsButtonProps = {
  sessionId: string;
  showParticipantsExport?: boolean;
  showFeedbackExport?: boolean;
  showDocsExport?: boolean;
  secondary?: boolean;
};

type ExportState = 'exporting' | 'error' | 'null';

const ExportsButton = ({
  sessionId,
  showParticipantsExport,
  showFeedbackExport,
  showDocsExport,
  secondary = false,
}: ExportsButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const [participants, isParticipantLoaded] = useParticipants(sessionId);
  const [topics] = useTopics(sessionId);
  const actions: Action[] = [];
  const [exportState, setExportState] = useState<ExportState>('null');

  const withExportModal = async (fn: () => Promise<void>): Promise<void> => {
    setExportState('exporting');
    try {
      await fn();
      setExportState('null');
    } catch (e) {
      console.error(e);
      setExportState('error');
    }
  };

  if (showDocsExport && isParticipantLoaded) {
    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() => exportSessionDocs(sessionId, participants, true));
      },
      label: t('sessions:exportAllDocuments'),
      icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
    });

    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() =>
          exportSessionDocs(
            sessionId,
            participants,
            true,
            undefined,
            undefined,
            true
          )
        );
      },
      label: t('sessions:exportAllDocumentsWithAI'),
      icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
    });

    if (_.size(topics) > 0) {
      actions.push({
        type: 'button',
        onClick: () => {
          withExportModal(() =>
            exportSessionDocs(
              sessionId,
              participants,
              true,
              undefined,
              'byTopics'
            )
          );
        },
        label: t('sessions:exportThematicSynthsesis'),
        icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
      });

      actions.push({
        type: 'button',
        onClick: () => {
          withExportModal(() =>
            exportSessionDocs(
              sessionId,
              participants,
              true,
              undefined,
              'byTopics',
              true
            )
          );
        },
        label: t('sessions:exportThematicSynthsesisWithIA'),
        icon: <DocumentText className="h-6 w-6 stroke-2 text-[#2b579a]" />,
      });
    }
  }

  if (showFeedbackExport) {
    if (showDocsExport) {
      actions.push({ type: 'divider' });
    }
    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() => exportFeedbacks(sessionId));
      },
      label: t('sessions:exportParticipantsFeedbacks'),
      icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
    });
  }

  if (showParticipantsExport) {
    if (showFeedbackExport || showDocsExport) {
      actions.push({ type: 'divider' });
    }
    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() => exportAllUsersProduction(sessionId));
      },
      label: t('sessions:exportRawData'),
      icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
    });

    actions.push({
      type: 'button',
      onClick: () => {
        withExportModal(() => exportUsers(sessionId));
      },
      label: t('sessions:exportTimesheet'),
      icon: <Table className="h-6 w-6 stroke-2 text-[#217346]" />,
    });
  }

  const modalTitle =
    exportState === 'exporting'
      ? t('sessions:ExportInProgress')
      : exportState === 'error'
      ? t('sessions:ExportError')
      : null;

  const modalBody =
    exportState === 'exporting' ? (
      <Spinner className="w-16 h-16 text-primary" />
    ) : exportState === 'error' ? (
      <p>{t('sessions:ExportErrorRetryDescription')}</p>
    ) : null;

  return (
    <>
      <Modal
        open={exportState !== 'null'}
        title={modalTitle}
        body={
          <div className="flex flex-col items-center min-h-[94px]">
            {modalBody}
            {exportState === 'error' ? (
              <Button
                text={t('common:Close')}
                onClick={() => setExportState('null')}
              />
            ) : null}
          </div>
        }
      />
      <MenuButtons
        menuButton={
          <Button
            design={(secondary && 'secondary') || undefined}
            size={secondary ? 'sm' : 'md'}
            disabled={actions.length === 0}
            text={t('sessions:exportData')}
            icon={<ChevronUp className="rotate-180 stroke-2" />}
            iconPlacement="right"
          />
        }
        size="sm"
        verticalGap={false}
        iconPlacement="left"
        actions={actions}
      />
    </>
  );
};

export default ExportsButton;
