import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { templateReplace } from 'utils/utils';

import MDReactComponent from 'componentsOld/MDReactComponent';
import Disclosure from 'components/Disclosure';
import { Mode } from 'types/ui';
import { Activity, Group, RSet } from 'types/types';
import { useTextReplaceSession } from 'model/sessions';
import { useTopics } from 'model/productions';
import Button from 'components/Button';
import { ChevronDoubleUp } from 'assets/icons';
import { Nullable } from 'types/typesUtils';
import Card from './blocs/Card';
import AugmentedPost from './blocs/AugmentedPost';
import { database as db } from 'services/firebase';
import _ from 'lodash';
import { useUsers } from 'model/users';

type AugmentPostScreenProps = {
  instructions: string;
  posts: RSet<string>;
  postTitles: RSet<string>;
  sessionId: string;
  userId: string;
  userName: string;
  editable: boolean;
  setInstructions?: (value: string) => Promise<void>;
  context: Record<string, string>;
  mode: Mode;
  activity: Activity;
  group: Nullable<Group>;
  experienceTitle: string;
  setExperienceTitle?: (title: string) => void;
  titleLabel: string;
  setTitleLabel?: (title: string) => void;
  postLabel: string;
  setPostLabel?: (title: string) => void;
};

const AugmentPostScreen = ({
  instructions,
  sessionId,
  userId,
  userName,
  editable,
  setInstructions,
  context,
  mode,
  activity,
  group,
  experienceTitle = '',
  setExperienceTitle,
  posts,
  postTitles,
  titleLabel,
  setTitleLabel,
  postLabel,
  setPostLabel,
}: AugmentPostScreenProps) => {
  const { t } = useTranslation();

  const inGroups = activity.grouping.mode === 'Groups';

  const [openStatuses, setOpenStatuses] = useState<RSet<boolean>>({});

  const fakeUsers: Record<string, { name: string }> = {
    me: {
      name: userName,
    },
    ...(inGroups
      ? _.keyBy(
          ['Natasha', 'Jérémy', 'Antoine', 'Mathilde'].map((name) => ({
            name,
          })),
          'name'
        )
      : {}),
  };

  const fakePosts = _.mapValues(fakeUsers, ({ name }) =>
    t('misc:ShareExample', { name })
  );

  const fakeTitles = _.mapValues(fakeUsers, ({ name }) =>
    t('misc:ShareTitleExample', { name })
  );

  const useFakeData = (!inGroups || !group?.users) && mode === 'preview';

  const groupData = useFakeData
    ? _.mapValues(fakeUsers, () => true)
    : group?.users || (inGroups ? {} : { [userId]: true });

  const [users] = useUsers(groupData);

  console.log('Users', users);

  const usersData = useFakeData ? fakeUsers : users;
  const postsData = useFakeData ? fakePosts : posts;
  const titlesData = useFakeData ? fakeTitles : postTitles;

  const setPost = async (userId: string, value: string) => {
    await db
      .ref(
        `sessionsNextData/${sessionId}/activities/post/productions/post/${userId}`
      )
      .set(value);
  };

  const setPostTitle = async (userId: string, value: string) => {
    await db
      .ref(
        `sessionsNextData/${sessionId}/activities/${activity.name}/productions/title/${userId}`
      )
      .set(value);
  };

  const toggleStatus = (userId: string) => {
    setOpenStatuses((statuses) => ({
      ...statuses,
      [userId]: !statuses[userId],
    }));
  };

  const closeAll = () => {
    setOpenStatuses({});
  };

  const openAll = () => {
    setOpenStatuses({ ...groupData });
  };

  const textReplaceSession = useTextReplaceSession(
    sessionId,
    activity.name,
    userId
  );

  const [topics] = useTopics(sessionId);
  const topicsArray = Object.values(topics);
  const topicDescription =
    topicsArray?.[0]?.description || t('misc:TopicExample');

  const helper = useMemo(() => {
    return editable
      ? instructions
      : textReplaceSession(
          templateReplace(instructions, {
            ...context,
            topic:
              !context.topic && mode === 'preview'
                ? topicDescription
                : context.topic,
          })
        );
  }, [
    context,
    mode,
    instructions,
    editable,
    textReplaceSession,
    topicDescription,
  ]);

  return (
    <div className="m-auto max-w-3xl flex-col">
      {(editable || helper) && (
        <Disclosure
          defaultOpen
          collapsable={mode === 'editor'}
          title={
            <div className="flex items-center space-x-2">
              <h2 className="text-2xl font-semibold uppercase text-black">
                {t('editor:goals')}
              </h2>
            </div>
          }
        >
          <MDReactComponent
            text={helper}
            editable={editable}
            setText={setInstructions}
          />
        </Disclosure>
      )}
      {editable ? (
        <Card
          collapsable={false}
          title={experienceTitle}
          setTitle={setExperienceTitle}
          open={true}
          editable={editable}
        >
          <AugmentedPost
            editable
            post=""
            setPost={() => {}}
            postTitle=""
            setPostTitle={() => {}}
            titleLabel={titleLabel}
            setTitleLabel={setTitleLabel}
            postLabel={postLabel}
            setPostLabel={setPostLabel}
          />
        </Card>
      ) : (
        <>
          <div className="sticky top-0 z-70 flex flex-row items-center space-x-4 border-b border-white bg-white p-2">
            <Button
              size="sm"
              color="primary"
              design="secondary"
              icon={<ChevronDoubleUp className="rotate-180 stroke-2" />}
              text={t('common:ExpandAll')}
              onClick={openAll}
            />
            <Button
              size="sm"
              color="primary"
              design="secondary"
              icon={<ChevronDoubleUp className="stroke-2" />}
              text={t('common:CollapseAll')}
              onClick={closeAll}
            />
          </div>
          {Object.keys(groupData).map((userId) => {
            const experienceTitleInterpol = templateReplace(experienceTitle, {
              ...context,
              name: usersData[userId]?.name || t('users:UnknownUser'),
            });

            return (
              <Card
                collapsable={inGroups}
                key={userId}
                title={experienceTitleInterpol}
                open={!!openStatuses[userId]}
                toggle={() => toggleStatus(userId)}
              >
                <AugmentedPost
                  post={postsData?.[userId]}
                  setPost={(post) =>
                    mode === 'participant' && setPost(userId, post)
                  }
                  postTitle={titlesData?.[userId]}
                  setPostTitle={(title) =>
                    mode === 'participant' && setPostTitle(userId, title)
                  }
                  titleLabel={titleLabel}
                  postLabel={postLabel}
                />
              </Card>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AugmentPostScreen;
