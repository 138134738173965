import {
  Activities,
  ActivitiesMeta,
  Activity,
  OrderedActivities,
  SessionLanguage,
  SessionTemplate,
  Session,
} from 'types/types';

import { database as db, fetchData, NOW } from 'services/firebase';

import {
  Action,
  defaultGroupingSettings,
  processAction,
} from 'model/sessionEditor';

import _ from 'lodash';
import { makeKey } from 'utils/utils';
import {
  generateProductions,
  sanitizeActivityProductions,
} from './productions';

export const computeOrderedActivities = (
  activities: Activities
): OrderedActivities => _.sortBy(Object.values(activities), (el) => el.index);

export const canAddActivity = (activity: Activity): boolean => {
  return !_.includes(['post', 'choose'], activity.name);
};

export const canMoveActivity = (activity: Activity): boolean => {
  return !_.includes(['post', 'choose', 'share'], activity.name);
};

const getSessionTemplatePath = (sessionId: string) => {
  return `sessionsNextTemplates/${sessionId}`;
};

export const getActivitiesSessionId = (lng: SessionLanguage) =>
  lng === 'en' ? '!admin_activities_en' : '!admin_activities_fr';

export const addActivity = async (
  sessionId: string,
  language: SessionLanguage,
  sessionTemplate: SessionTemplate,
  prevActivityName: string,
  name: string,
  sourceActivityName: string
): Promise<string> => {
  if (!canAddActivity(sessionTemplate.activities[prevActivityName])) {
    throw new Error("Can't add after this activity");
  }

  const index = sessionTemplate.activities[prevActivityName].index + 1;

  const key = sessionTemplate.activities[sourceActivityName]
    ? makeKey(name)
    : sourceActivityName;

  if (sessionTemplate.activities[key]) {
    throw new Error('A activity with this key already exists');
  }

  let sourceActivity = await fetchData<Activity>(
    `sessionsNextTemplates/${getActivitiesSessionId(
      language
    )}/activities/${sourceActivityName}`
  );

  if (!sourceActivity) {
    throw new Error('This source activity does not exist');
  }

  sourceActivity = await sanitizeActivityProductions(sourceActivity);

  for (const screenKey in sourceActivity.screens) {
    const screen = sourceActivity.screens[screenKey];
    for (const dataBindingName in screen.dataBindings) {
      screen.dataBindings[dataBindingName] = screen.dataBindings[
        dataBindingName
      ].replace(`productions.${sourceActivityName}.`, `productions.${key}.`);
    }
  }

  // To remove votes datapath if "choose" step does no exist. To be updated if one day several choose steps can coexist
  if (
    sourceActivity?.grouping.mode === 'Groups' &&
    sourceActivity.grouping.settings.mode === 'algo' &&
    sourceActivity.grouping.settings.votes &&
    !sessionTemplate.activities['choose']?.productions?.['vote']
  ) {
    sourceActivity.grouping.settings.votes = '';
  }

  const newActivity: Activity = {
    ...sourceActivity,
    index,
    name: key,
    humanName: name,
  };

  await processAction(sessionTemplate, getSessionTemplatePath(sessionId), {
    type: 'activity:new',
    newActivity: newActivity,
  });

  return key;
};

export const canDeleteActivity = (activity: Activity): boolean => {
  return !_.includes(['feedback', 'post', 'share'], activity.name);
};

export const deleteActivity = async (
  sessionId: string,
  sessionTemplate: SessionTemplate,
  name: string
): Promise<void> => {
  if (!canDeleteActivity(sessionTemplate.activities[name])) {
    throw new Error("Can't delete activity because others depends on it");
  }

  const action: Action = {
    type: 'activity:delete',
    name,
  };

  console.log(action);
  return processAction(
    sessionTemplate,
    getSessionTemplatePath(sessionId),
    action
  );
};

// the following function are helpers and does not fit previous action pattern
// for now

export const setCanNotPost = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  // update screenbinding
  if (activity.screens.create) {
    const dbRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/create/content/template/params/allowedNotToPost`;
    await db.ref(dbRef).set(true);
  }
};

export const unsetCanNotPost = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  // remove production
  if (activity.screens.create) {
    const dbRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/create/content/template/params/allowedNotToPost`;
    await db.ref(dbRef).remove();
  }
};

export const setNoChooseLimit = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  // update screenbinding
  if (activity.screens.like) {
    const dbRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/like/content/template/params/noLimit`;
    await db.ref(dbRef).set(true);
  }
};

export const unsetNoChooseLimit = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  // remove production
  if (activity.screens.like) {
    const dbRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/like/content/template/params/noLimit`;
    await db.ref(dbRef).remove();
  }
};

export const setCriteriaRequired = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  // update screenbinding
  if (activity.screens.create) {
    const dbRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/create/content/template/params/criteriaRequired`;
    await db.ref(dbRef).set(true);
  }
};

export const unsetCriteriaRequired = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  // remove production
  if (activity.screens.create) {
    const dbRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/create/content/template/params/criteriaRequired`;
    await db.ref(dbRef).remove();
  }
};

export const startActivity = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  const activityRef = db.ref(
    `/sessionsNextData/${sessionId}/activitiesMeta/${activity.name}`
  );

  return activityRef.update({
    [`enabledAt`]: NOW,
    [`remainingTimestamp`]: NOW,
    [`remainingTimeMs`]: activity.duration ? activity.duration * 60000 : null,
    [`isPaused`]: false,
  });
};

export const pauseActivity = async (
  sessionId: string,
  activity: Activity,
  remainingTimeMs: number
): Promise<void> => {
  if (activity.duration) {
    const activityDurationMs = activity.duration * 60000;

    const activityRef = db.ref(
      `/sessionsNextData/${sessionId}/activitiesMeta/${activity.name}`
    );

    return activityRef.update({
      [`remainingTimestamp`]: NOW,
      [`remainingTimeMs`]: _.clamp(remainingTimeMs, 0, activityDurationMs),
      [`isPaused`]: true,
    });
  }
};

export const resumeActivity = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  if (activity.duration) {
    const activityRef = db.ref(
      `/sessionsNextData/${sessionId}/activitiesMeta/${activity.name}`
    );

    return activityRef.update({
      [`remainingTimestamp`]: NOW,
      [`isPaused`]: false,
    });
  }
};

export const updateActivity = async (
  sessionId: string,
  activity: Activity,
  remainingTimeMs: number
): Promise<void> => {
  if (activity.duration) {
    const activityRef = db.ref(
      `/sessionsNextData/${sessionId}/activitiesMeta/${activity.name}`
    );
    const activityDurationMs = activity.duration * 60000;

    return activityRef.update({
      [`remainingTimestamp`]: NOW,
      [`remainingTimeMs`]: _.clamp(remainingTimeMs, 0, activityDurationMs),
    });
  }
};

export const resetActivityMeta = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  const activityRef = db.ref(
    `/sessionsNextData/${sessionId}/activitiesMeta/${activity.name}`
  );

  return activityRef.update({
    [`enabledAt`]: null,
    [`remainingTimestamp`]: null,
    [`remainingTimeMs`]: null,
    [`isPaused`]: null,
  });
};

export const insertActivityNote = async (
  sessionId: string,
  activity: Activity,
  facilitationNote: string
): Promise<void> => {
  const dbRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/facilitationNote`;
  await db.ref(dbRef).set(facilitationNote);
};

export const removeActivityNote = async (
  sessionId: string,
  activity: Activity
): Promise<void> => {
  const dbRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/facilitationNote`;
  await db.ref(dbRef).remove();
};

export const activateActivity = async (
  sessionId: string,
  activityName: string
) => {
  const session: Session | null = await fetchData(`sessionsNext/${sessionId}`);

  if (!session) {
    throw new Error("Can't activate an activity for non exisiting session");
  }

  const activities: Activities =
    (await fetchData(`sessionsNextTemplates/${sessionId}/activities`)) || {};

  const orderedActivities: Activity[] = _.sortBy(
    Object.values(activities),
    (el) => el.index
  );

  const enabledActivities: Record<string, boolean> =
    (await fetchData(`sessionsNextData/${sessionId}/enabledActivities`)) || {};
  const activitiesMeta: ActivitiesMeta =
    (await fetchData(`sessionsNextData/${sessionId}/activitiesMeta`)) || {};

  const newState = {
    ..._.mapValues(enabledActivities, (_) => false),
    [activityName]: true,
  };

  const index = orderedActivities.findIndex(
    (activity) => activity.name === activityName
  );

  await Promise.all(
    orderedActivities.map((activity, ind) => {
      if (ind > index && activitiesMeta[activity.name]?.enabledAt) {
        return resetActivityMeta(sessionId, activity);
      } else {
        return Promise.resolve();
      }
    })
  );

  if (!enabledActivities[activityName]) {
    startActivity(sessionId, orderedActivities[index]);
  }

  generateProductions(
    sessionId,
    session.communityId,
    orderedActivities[index],
    activities,
    session.groupPrefix
  );

  return db
    .ref(`/sessionsNextData/${sessionId}/enabledActivities/`)
    .set(newState);
};

export const reindexSessionActivities = async (
  sessionId: string,
  orderedActivities: OrderedActivities
): Promise<void> => {
  const updates: Record<string, any> = {};
  orderedActivities.forEach((activity, index) => {
    updates[
      `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/index`
    ] = index;

    // clean group dependecies
    if (
      activity.grouping.mode === 'Groups' &&
      activity.grouping.settings.mode === 'same_as'
    ) {
      const refGroupingActivityName = activity.grouping.settings.activity;
      const referenceActivityIndex = orderedActivities.findIndex(
        (refActivity) => refActivity.name === refGroupingActivityName
      );
      if (
        referenceActivityIndex === undefined ||
        referenceActivityIndex > index
      ) {
        updates[
          `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/grouping/settings`
        ] = defaultGroupingSettings;
      }
    }

    // clean criteria dependecies
    if (
      activity.grouping.mode === 'Groups' &&
      activity.grouping.settings.mode === 'algo'
    ) {
      const criteriaKeys: string[] = Object.keys(
        activity.grouping.settings.criteria || {}
      );
      if (criteriaKeys.length > 0) {
        const newCriteria = { ...activity.grouping.settings.criteria };
        criteriaKeys.forEach((criteriaKey) => {
          const referenceActivityIndex = orderedActivities.findIndex(
            (refActivity) => {
              return Object.keys(refActivity.productions || {}).some(
                (productionKey) => productionKey === criteriaKey
              );
            }
          );
          if (
            referenceActivityIndex === undefined ||
            referenceActivityIndex > index
          ) {
            delete newCriteria[criteriaKey];
          }
        });
        updates[
          `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/grouping/settings/criteria`
        ] = newCriteria;
      }
    }
  });

  await db.ref().update(updates);
};

export type ActivityType =
  | 'post'
  | 'augment_post'
  | 'choose'
  | 'document'
  | 'content'
  | 'break'
  | 'feedback'
  | 'unknown';

export const getActivityType = (activity: Activity): ActivityType => {
  if (activity.name === 'feedback') return 'feedback';
  if (activity.screens['explore']) return 'content';
  if (activity.productions?.['post']) return 'post';
  if (activity.productions?.['title']) return 'augment_post';
  if (activity.productions?.['vote']) return 'choose';
  if (activity.screens['content']?.content.template.name === 'SimpleCardScreen')
    return 'break';
  if (
    Object.values(activity.productions || {}).some(
      (production) => production.type === 'document'
    )
  )
    return 'document';
  return 'unknown';
};

export const isValidNewActivity = (
  potentialActivityToInsert: Activity,
  sessionActivities: Activities
) => {
  if (_.includes(['choose', 'feedback'], potentialActivityToInsert.name)) {
    return false;
  }
  if (
    potentialActivityToInsert.name === 'augment_post' &&
    sessionActivities['augment_post']
  ) {
    return false;
  }
  return true;
};
