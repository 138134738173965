import React, { useState } from 'react';
import {
  Activities,
  Activity,
  GroupingProp,
  GroupSettings,
  Productions,
  DataPath,
  Criteria,
  GroupingMode,
  Grouping,
  CriteriaMode,
  CRITERIA_MODES,
  MatchingMode,
  MatchedUsers,
  UserType,
} from 'types/types';
import { ExtendedFirebaseInstance, useFirebase } from 'react-redux-firebase';
import { Checkbox } from 'antd';
import _ from 'lodash';

import EditableOption, {
  EditableOptionProps,
} from 'componentsOld/EditableOption';
import FormLine from './FormLine';
import ValidatedEditableText, {
  ValidatedEditableInteger,
} from 'componentsOld/ValidatedEditableText';
import EditableTable from 'componentsOld/EditableTable';
import { dataPathToRef } from 'model/dataPathUtils';
import { AllKeys } from 'types/typesUtils';

const CriteriaTable = ({
  criteria,
  pathValidator,
  onSave,
}: {
  criteria?: Criteria;
  pathValidator: (ignored: any, dataPath: DataPath) => Promise<void>;
  onSave: (criteria: Criteria) => Promise<void>;
}): JSX.Element => {
  type Record = {
    key: string;
    name: string;
    source: DataPath;
    mode: CriteriaMode;
  };

  const [addingRow, setAddingRow] = useState<Record | null>(null);

  const dataCriteria = Object.entries(criteria || {}).map(
    ([key, { source, mode }]) => ({
      key,
      name: key,
      source,
      mode: mode || 'ONE_OF_EACH',
    })
  );

  if (addingRow) {
    dataCriteria.push(addingRow);
  }
  const updateCriteria = ({ key, name, source, mode }: Record) => {
    if (key === addingRow?.key) {
      if (source === '' || name === '') {
        setAddingRow({ key, name, source, mode });
      } else {
        setAddingRow(null);
      }
    }
    if (source !== '' && name !== '') {
      const newCriteria: Criteria = _.keyBy(
        _.uniqBy(
          [
            {
              key,
              name,
              source,
              mode,
            },
            ...dataCriteria,
          ],
          'key'
        ).map(({ name, source, mode }) => ({ source, name, mode })),
        'name'
      );
      return onSave(newCriteria);
    }
  };

  const handleDelete = (deletedKey: string) => {
    if (deletedKey === 'trololo') {
      setAddingRow(null);
    } else {
      return onSave(_.pickBy(criteria, (_, key) => key !== deletedKey));
    }
  };

  const handleAdd = () =>
    setAddingRow({ key: 'trololo', name: '', source: '', mode: 'ONE_OF_EACH' });

  return (
    <EditableTable
      columns={[
        {
          title: 'Name',
          dataIndex: 'name',
          editable: true,
        },
        {
          title: 'Source',
          dataIndex: 'source',
          editable: true,
          rules: [
            {
              validator: pathValidator,
              message: 'This data path is not valid',
            },
          ],
        },
        {
          title: 'Mode',
          dataIndex: 'mode',
          editable: true,
          options: CRITERIA_MODES,
          // rules: [
          //   {
          //     validator: pathValidator,
          //     message: 'This data path is not valid'
          //   }
          // ]
        },
      ]}
      handleSave={updateCriteria}
      handleDelete={handleDelete}
      handleAdd={handleAdd}
      dataSource={dataCriteria}
    />
  );
};

type GroupingFormProps = {
  sessionId: string;
  productions: Record<string, Productions>;
  activities: Activities;
  settings: GroupSettings;
  availableActivities: Activities;
  availableTopicsActivities: Activities;
  onVotesChange: (productionPath: DataPath) => Promise<void>;
  onTopicsActivityChange: (topicsActivity: string | undefined) => Promise<void>;
  onMinPeopleByGroupChange: (val: number) => Promise<void>;
  onMaxPeopleByGroupChange: (val: number) => Promise<void>;
  onCriteriaChange: (criteria: Criteria) => Promise<void>;
  onChangeMatchedUsers: (val: MatchedUsers) => Promise<void>;
  onChangeSourceActivity: (val: string) => Promise<void>;
  onChangeMatchingMode: (mode: MatchingMode) => Promise<void>;
  onChangeNumberOfGroups: (mode: number) => Promise<void>;
  onMembersCanChangeAndJoin: (val: boolean) => Promise<void>;
  onSameGroupActivityChange: (activityName: string) => Promise<void>;
};

export type FormEditableOptionProps = EditableOptionProps & {
  label: string;
};

const FormEditableOption = ({
  label,
  value,
  options,
  onChange,
  editable,
}: FormEditableOptionProps) => (
  <FormLine label={label}>
    <EditableOption
      value={value}
      options={options}
      onChange={onChange}
      editable={editable}
    />
  </FormLine>
);

const GroupForm = ({
  sessionId,
  productions,
  activities,
  settings,
  availableActivities,
  availableTopicsActivities,
  onVotesChange,
  onTopicsActivityChange,
  onMinPeopleByGroupChange,
  onMaxPeopleByGroupChange,
  onCriteriaChange,
  onChangeMatchedUsers,
  onChangeSourceActivity,
  onChangeMatchingMode,
  onChangeNumberOfGroups,
  onMembersCanChangeAndJoin,
  onSameGroupActivityChange,
}: GroupingFormProps): JSX.Element => {
  const bindingValidator = async (ignored: any, dataPath: DataPath) => {
    console.log('Validating', dataPath);
    if (dataPath !== '') {
      try {
        dataPathToRef(
          sessionId,
          undefined,
          productions,
          dataPath,
          activities,
          true
        );
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  };

  const canSameGroups = _.size(availableActivities) > 0;

  const activityOptions = _.mapValues(
    availableActivities,
    ({ name, humanName }) => ({
      name,
      desc: humanName,
    })
  );

  const topicsActivityOptions = _.mapValues(
    availableTopicsActivities,
    ({ name, humanName }) => ({
      name,
      desc: humanName,
    })
  );

  return (
    <>
      <FormEditableOption
        label="Matching Mode"
        value={settings.mode}
        options={{
          algo: {
            desc: 'Algo',
          },
          ...(canSameGroups
            ? {
                same_as: {
                  desc: 'Same as',
                },
              }
            : {}),
          empty: {
            desc: 'Empty',
          },
          ...(settings.mode === 'custom_skema'
            ? {
                custom_skema: {
                  desc: 'custom skema',
                },
              }
            : {}),
        }}
        onChange={onChangeMatchingMode as (mode: string) => Promise<void>}
        editable
      />

      {settings.mode === 'algo' || settings.mode === 'custom_skema' ? (
        <>
          <FormEditableOption
            label="What users are matched ?"
            value={settings.matchedUsers || 'all'}
            options={{
              all: {
                desc: 'all',
              },
              active: {
                desc: 'active',
              },
              activity: {
                desc: 'activity',
              },
            }}
            onChange={onChangeMatchedUsers as (mode: string) => Promise<void>}
            editable
          />

          {settings.matchedUsers === 'activity' && settings.sourceActivity ? (
            <FormEditableOption
              label="Source Matching Activity"
              value={settings.sourceActivity}
              options={activityOptions}
              onChange={onChangeSourceActivity}
              editable
            />
          ) : null}

          <FormLine label="Votes source">
            <ValidatedEditableText
              value={settings.votes}
              rules={[
                {
                  validator: bindingValidator,
                  message: 'This data path is not valid',
                },
              ]}
              onSave={onVotesChange}
            />
          </FormLine>

          <FormEditableOption
            label="Topics Activity"
            value={settings.topicsActivityName || '###undefined###'}
            options={{
              '###undefined###': {
                desc: 'No value',
              },
              ...topicsActivityOptions,
            }}
            onChange={(val) =>
              onTopicsActivityChange(
                val === '###undefined###' ? undefined : val
              )
            }
            editable
          />

          <FormLine label="Min People by group">
            <ValidatedEditableInteger
              value={settings.minPeopleByGroup}
              onSave={onMinPeopleByGroupChange}
            />
          </FormLine>
          <FormLine label="Max People by group">
            <ValidatedEditableInteger
              value={settings.maxPeopleByGroup}
              onSave={onMaxPeopleByGroupChange}
            />
          </FormLine>

          <FormLine label="Criteria" />
          <CriteriaTable
            criteria={settings.criteria}
            pathValidator={bindingValidator}
            onSave={onCriteriaChange}
          />
        </>
      ) : settings.mode === 'empty' ? (
        <FormLine label="Number of groups">
          <ValidatedEditableInteger
            value={settings.numberOfGroups || 0}
            onSave={onChangeNumberOfGroups}
          />
        </FormLine>
      ) : settings.mode === 'same_as' ? (
        <FormEditableOption
          label="Activity"
          value={settings.activity}
          options={activityOptions}
          onChange={onSameGroupActivityChange}
          editable
        />
      ) : null}
      <FormLine label="Users can join/change groups">
        <Checkbox
          checked={!!settings.membersCanChangeAndJoin}
          onChange={(e) => onMembersCanChangeAndJoin(e.target.checked)}
        />
      </FormLine>
    </>
  );
};

export type GroupingRendererProps = {
  sessionId: string;
  userType: UserType;
  grouping: Grouping;
  productions: Record<string, Productions>;
  activities: Activities;
  hasDepedencies: boolean;
  availableModes: GroupingMode[];
  availableActivities: Activities;
  availableTopicsActivities: Activities;
  onVotesChange: (productionPath: DataPath) => Promise<void>;
  onTopicsActivityChange: (topicsActivity: string | undefined) => Promise<void>;
  onMinPeopleByGroupChange: (val: number) => Promise<void>;
  onMaxPeopleByGroupChange: (val: number) => Promise<void>;
  onCriteriaChange: (criteria: Criteria) => Promise<void>;
  onSameGroupActivityChange: (activityName: string) => Promise<void>;
  onChangeGroupMode: (mode: GroupingMode) => Promise<void>;
  onChangeMatchedUsers: (val: MatchedUsers) => Promise<void>;
  onChangeSourceActivity: (val: string) => Promise<void>;
  onChangeMatchingMode: (mode: MatchingMode) => Promise<void>;
  onChangeNumberOfGroups: (mode: number) => Promise<void>;
  onMembersCanChangeAndJoin: (val: boolean) => Promise<void>;
};

const GroupParametersRenderer = ({
  sessionId,
  grouping,
  productions,
  activities,
  hasDepedencies,
  availableModes,
  availableActivities,
  availableTopicsActivities,
  onVotesChange,
  onTopicsActivityChange,
  onMinPeopleByGroupChange,
  onMaxPeopleByGroupChange,
  onCriteriaChange,
  onSameGroupActivityChange,
  onChangeGroupMode,
  onChangeMatchedUsers,
  onChangeSourceActivity,
  onChangeMatchingMode,
  onChangeNumberOfGroups,
  onMembersCanChangeAndJoin,
}: GroupingRendererProps) => {
  const options = _.pickBy(
    {
      All: {
        desc: 'Do not use groups in this activity',
      },
      Groups: {
        desc: 'Use new matching',
      },
      SameGroups: {
        desc: 'Use groups of another activity',
      },
    },
    (_val, key) => _.includes(availableModes, key)
  );

  const renderForm = () => {
    switch (grouping.mode) {
      case 'Groups':
        return (
          <GroupForm
            sessionId={sessionId}
            productions={productions}
            activities={activities}
            settings={grouping.settings}
            availableActivities={availableActivities}
            availableTopicsActivities={availableTopicsActivities}
            onVotesChange={onVotesChange}
            onTopicsActivityChange={onTopicsActivityChange}
            onMinPeopleByGroupChange={onMinPeopleByGroupChange}
            onMaxPeopleByGroupChange={onMaxPeopleByGroupChange}
            onCriteriaChange={onCriteriaChange}
            onChangeMatchedUsers={onChangeMatchedUsers}
            onChangeSourceActivity={onChangeSourceActivity}
            onChangeMatchingMode={onChangeMatchingMode}
            onChangeNumberOfGroups={onChangeNumberOfGroups}
            onMembersCanChangeAndJoin={onMembersCanChangeAndJoin}
            onSameGroupActivityChange={onSameGroupActivityChange}
          />
        );
      case 'All':
      default:
        return null;
    }
  };

  const changeGroup = (val: String) => {
    if (val !== 'Groups' && val !== 'All') {
      throw new Error('Option is not valid');
    }
    return onChangeGroupMode(val as GroupingMode);
  };

  return (
    <>
      <FormEditableOption
        label="Mode"
        value={grouping.mode}
        options={options}
        onChange={changeGroup}
        editable={!hasDepedencies}
      />

      {renderForm()}
    </>
  );
};

type GroupingParametersProps = {
  sessionId: string;
  userType: UserType;
  productions: Record<string, Productions>;
  activity: Activity;
  activities: Activities;
  activityRef: string;
};

const fixDocumentProductions = async (
  db: ExtendedFirebaseInstance,
  mode: GroupingMode,
  activityRef: string,
  activity: Activity
) => {
  return Promise.all(
    Object.values(activity.productions || {}).map(async (production) => {
      if (production.type === 'document') {
        const ref = `${activityRef}/productions/${production.name}`;
        if (mode === 'All') {
          await db.ref(ref).update({
            mode: 'ByAll',
            multiplicity: 'Each',
          });
        } else {
          await db.ref(`${ref}/mode`).set('ByGroup');
        }
      }
    })
  );
};

export const GroupingParametersGeneric = ({
  sessionId,
  userType,
  productions,
  activity,
  activities,
  activityRef,
  GroupRenderer,
}: GroupingParametersProps & {
  GroupRenderer: React.ComponentType<GroupingRendererProps>;
}): JSX.Element => {
  const db = useFirebase();

  const baseRef = `${activityRef}/grouping`;
  const setter = (prop: GroupingProp) => (val: string | GroupSettings) => {
    return db.ref(`${baseRef}/${prop}`).set(val);
  };

  const previousGroups = _.pickBy(
    activities,
    (act: Activity) =>
      act.index < activity.index && act.grouping.mode === 'Groups'
  );

  const validTopicsActivities = _.pickBy(
    activities,
    (act: Activity) =>
      act.index < activity.index &&
      act.grouping.mode === 'All' &&
      act.productions?.post
  );

  const currentGrouping = activity.grouping;

  const nextGroupsDepends =
    currentGrouping.mode === 'Groups' &&
    Object.values(activities).some(
      (act) =>
        act.index > activity.index &&
        act.grouping.mode === 'Groups' &&
        act.grouping.settings.mode === 'same_as' &&
        act.grouping.settings.activity === activity.name
    );

  const setSetting =
    (prop: AllKeys<GroupSettings>) =>
    (val: string | number | Criteria | boolean | null) => {
      return db.ref(`${baseRef}/settings/${prop}`).set(val);
    };

  const changeGroupMode = async (mode: GroupingMode) => {
    if (mode === 'Groups') {
      await setter('settings')({
        mode: 'algo',
        matchedUsers: 'active',
        votes:
          activity.name === 'share' && productions?.['choose']?.['vote']?.name
            ? 'productions.choose.vote.*'
            : '',
        criteria: {},
        minPeopleByGroup: 4,
        maxPeopleByGroup: 5,
      });
    }

    await fixDocumentProductions(db, mode, activityRef, activity);

    return setter('mode')(mode);
  };

  const availableModes: GroupingMode[] = ['Groups'];
  if (!nextGroupsDepends) {
    availableModes.push('All');
  }

  const changeMatchingMode = async (matchingMode: MatchingMode) => {
    if (matchingMode === 'empty') {
      await Promise.all([
        setSetting('numberOfGroups')(5),
        setSetting('membersCanChangeAndJoin')(true),
      ]);
    } else {
      setSetting('membersCanChangeAndJoin')(false);
    }
    if (matchingMode === 'same_as' && _.size(previousGroups) > 0) {
      await setSetting('activity')(Object.keys(previousGroups)[0]!);
    }
    if (matchingMode === 'algo') {
      await setSetting('minPeopleByGroup')(4);
      await setSetting('maxPeopleByGroup')(5);
    }
    await setSetting('mode')(matchingMode);
  };

  const changeMatchedUsers = async (matchedUsers: MatchedUsers) => {
    if (matchedUsers === 'activity' && _.size(previousGroups) > 0) {
      await setSetting('sourceActivity')(Object.keys(previousGroups)[0]!);
    }
    await setSetting('matchedUsers')(matchedUsers);
  };

  const changeTopicsActivity = async (topicsActivity?: string) => {
    const grouping = activity.grouping;
    if (grouping.mode === 'Groups') {
      const settings = grouping.settings;
      if (topicsActivity && settings.mode === 'algo' && settings.criteria) {
        console.log(settings.criteria);

        const criteria = _.pickBy(
          settings.criteria,
          (crit) => crit.mode !== 'HARD_SPLIT'
        );

        console.log(criteria);

        await setSetting('criteria')(criteria);
      }

      await setSetting('topicsActivityName')(topicsActivity || null);
    }
  };

  const changeCriteria = async (criteria: Criteria) => {
    const grouping = activity.grouping;
    if (grouping.mode === 'Groups') {
      const settings = grouping.settings;
      if (settings.mode === 'algo' && settings.topicsActivityName) {
        const hardSplitCriteria = _.find(
          criteria,
          (crit) => crit.mode === 'HARD_SPLIT'
        );

        if (hardSplitCriteria) {
          await setSetting('topicsActivityName')(null);
        }
      }

      await setSetting('criteria')(criteria);
    }
  };

  return (
    <GroupRenderer
      sessionId={sessionId}
      userType={userType}
      grouping={currentGrouping}
      productions={productions}
      activities={activities}
      hasDepedencies={nextGroupsDepends}
      availableModes={availableModes}
      availableTopicsActivities={validTopicsActivities}
      availableActivities={previousGroups}
      onVotesChange={setSetting('votes')}
      onTopicsActivityChange={changeTopicsActivity}
      onMinPeopleByGroupChange={setSetting('minPeopleByGroup')}
      onMaxPeopleByGroupChange={setSetting('maxPeopleByGroup')}
      onCriteriaChange={changeCriteria}
      onSameGroupActivityChange={setSetting('activity')}
      onChangeGroupMode={changeGroupMode}
      onChangeMatchedUsers={changeMatchedUsers}
      onChangeSourceActivity={setSetting('sourceActivity')}
      onChangeMatchingMode={changeMatchingMode}
      onChangeNumberOfGroups={setSetting('numberOfGroups')}
      onMembersCanChangeAndJoin={setSetting('membersCanChangeAndJoin')}
    />
  );
};

const GroupingParameters = (props: GroupingParametersProps): JSX.Element => (
  <GroupingParametersGeneric
    {...props}
    GroupRenderer={GroupParametersRenderer}
  />
);

export default GroupingParameters;
