import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFirebase } from 'react-redux-firebase';

import {
  Activity,
  Productions,
  Activities,
  RoleBar,
  Screen as ScreenType,
  ActivityMeta,
  Group,
  Session,
} from 'types/types';
import { Mode, Selection } from 'types/ui';

import { useContext, useGroup } from 'model/dataHooks';
import { addDoc } from 'model/docManagement';
import { getActivityType } from 'model/activitiesManagement';

import Screen from './Screen';
import AdminMatchScreen from 'screens/AdminMatchScreen';
import FeedbackView from 'screens/SessionScreen/SessionDashboardScreen/views/FeedbackView';
import SimpleCardScreen from 'screens/ContentScreen/screens/SimpleCardScreen';
import ChooseFacilitationScreen from './screens/ChooseFacilitationScreen';
import PostFacilitationDashboard from './screens/PostFacilitationDashboard';
import GroupAssignementView from './views/GroupAssignment';

import GroupModal from 'blocks/GroupModal';
import MDReactComponent from 'componentsOld/MDReactComponent';

import Disclosure from 'components/Disclosure';
import DebriefView from '../SessionDashboardScreen/views/DebriefView/DebriefView';
import GroupsOverview from './views/GroupsOverviewView';
import DocumentFacilitationView from './views/DocumentFacilitationView';
import GroupingFacilitationLayout from './views/GroupingFacilitationLayout';
import AugmentedPostFacilitationView from './views/AugmentedPostFacilitationView';

const useProductions = (
  sessionId: string,
  communityId: string | undefined,
  activity: Activity,
  activities: Activities,
  userId: string,
  role: RoleBar,
  groupPrefix: string | undefined,
  groupId?: string,
  group?: Group
) => {
  const db = useFirebase();

  const productions = activity.productions;
  const groupingMode = activity.grouping.mode;
  const activityName = activity.name;
  const activityHumanName = activity.humanName;
  useEffect(() => {
    if (productions) {
      Object.entries(productions).forEach(([key, production]) => {
        if (production.type === 'document') {
          if (
            (production.mode === 'ByGroup' && groupId && group) ||
            (production.mode === 'ByAll' && role === 'participant') ||
            (production.mode === 'ByUser' && role === 'participant')
          ) {
            (async () => {
              let ref: string = `sessionsNextData/${sessionId}/activities/${activityName}/productions/${production.name}/`;
              if (production.mode === 'ByGroup') {
                ref += `${groupId}/`;
              }
              if (
                production.multiplicity === 'Each' ||
                production.mode === 'ByUser'
              ) {
                ref += `${userId}/`;
              }

              const docIdObj: Record<string, boolean> = (
                await db.ref(ref).once('value')
              ).val();

              if (!docIdObj) {
                /// make doc

                const template = production.template;
                await addDoc(
                  template,
                  ref,
                  communityId,
                  sessionId,
                  activityName,
                  groupId,
                  production.docName,
                  production.docTitle,
                  userId,
                  {
                    group,
                    group_number: `${groupPrefix || ''}${group?.number || ''}`,
                    activities: activities,
                  },
                  activityHumanName || activityName,
                  production.visibility || 'Public'
                );
              } else {
                if (production.multiplicity !== 'Each') {
                  // add author to doc
                  // TODO: verify that userId is in groupId

                  await db
                    .ref(
                      `contentDocsMeta/${
                        Object.keys(docIdObj)[0]
                      }/authorsIds/${userId}`
                    )
                    .set(true);
                }
              }
            })();
          }
        }
      });
    }
  }, [
    productions,
    groupingMode,
    userId,
    groupId,
    group,
    activityName,
    activityHumanName,
    communityId,
    db,
    sessionId,
    role,
    activities,
    groupPrefix,
  ]);
};

const ActivityElement = ({
  sessionId,
  sessionDataId,
  userId,
  userName,
  userEmail,
  role,
  mode,
  productions,
  activity,
  activityMeta,
  activities,
  selection,
  lastActivity,
  session,
  groupModalVisible,
  showGroupModal,
  hideGroupModal,
  videoGroupId,
  selectVideoGroupId,
  resetVideoGroupId,
}: {
  sessionId: string;
  sessionDataId: string;
  session: Session;
  userId: string;
  userName: string;
  userEmail: string;
  role: RoleBar;
  mode: Mode;
  productions: Record<string, Productions>;
  activity: Activity;
  activityMeta: ActivityMeta;
  activities: Activities;
  selection: Selection;
  lastActivity: boolean;
  groupModalVisible: boolean;
  showGroupModal: () => void;
  hideGroupModal: () => void;
  videoGroupId: string | null;
  selectVideoGroupId: (groupId: string) => void;
  resetVideoGroupId: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  type VirtualScreen = {
    name: string;
    humanName?: string;
    fullscreen?: boolean;
  };

  type Screens = Screen[] | VirtualScreen[];

  const { screens } = activity;

  const history = useHistory();
  const db = useFirebase();
  const { state } = useLocation();

  const path = `/${selection.sessionPath}/${selection.selectedActivity}`;

  const groupingScreen: Screens =
    activity.grouping.mode !== 'All'
      ? [
          {
            name: 'grouping/edit_groups',
            humanName: 'Group management',
          },
          {
            name: 'grouping/all_groups',
            humanName: 'All Groups',
          },
          {
            name: 'grouping/overview',
            humanName: 'Overview',
          },
        ]
      : [];

  const orderedScreens: Screens =
    mode === 'facilitator'
      ? []
      : _.sortBy(Object.values(screens), (el) => el.index);

  if (
    mode === 'facilitator' &&
    (activity.productions || orderedScreens.length === 0)
  ) {
    orderedScreens.push({
      name: 'facilitate',
      humanName: 'Facilitate',
    });
  }

  if (mode === 'facilitator') {
    orderedScreens.push(...groupingScreen);
  }

  const selectedScreen =
    (selection.selectedScreen &&
      selection.selectedActivity &&
      activities[selection.selectedActivity]?.screens[selection.selectedScreen]
        ?.index) ||
    0;

  const goToNext =
    mode !== 'facilitator' && selectedScreen < orderedScreens.length - 1
      ? (state: unknown) =>
          history.push(
            `${path}/${orderedScreens[selectedScreen + 1].name}`,
            state
          )
      : undefined;

  const goToPrev =
    mode !== 'facilitator' && selectedScreen > 0
      ? (state: unknown) =>
          history.push(
            `${path}/${orderedScreens[selectedScreen - 1].name}`,
            state
          )
      : undefined;

  const [group, groupId, groupLoaded, groupActivityName] = useGroup(
    sessionDataId,
    userId,
    activity
  );

  const context = useContext(sessionId, userId, activity, {
    userName: userName,
    activities: activities,
    groupPrefix: session.groupPrefix,
  });

  const handleManageGroupView = (edit: boolean) => {
    history.push(`${path}/${edit ? 'grouping/edit_groups' : 'facilitate'}`);
  };

  const showMindMapView = (showOverview: boolean) => {
    history.push(
      `${path}/${showOverview ? 'grouping/overview' : 'facilitate'}`
    );
  };

  const renderFacilitation = (path: string) => {
    return (
      <Switch>
        <Route path={`${path}/grouping/edit_groups`} render={renderGrouping} />
        <Route path={`${path}/grouping/all_groups`} render={renderGrouping} />
        <Route
          exact
          path={`${path}/grouping/overview`}
          render={renderGroupsOverview}
        />
        <Route
          path={`${path}/facilitate`}
          render={renderFacilitatorDashboard}
        />
        <Route path={`${path}/*`}>
          <Redirect to={`${path}/facilitate`} />
        </Route>
      </Switch>
    );
  };

  const renderGroupsOverview = () => {
    if (activity.grouping.mode === 'Groups') {
      return (
        <GroupsOverview
          sessionId={sessionId}
          activities={activities}
          activity={activity}
          groupPrefix={session.groupPrefix || ''}
          showMindmap={showMindMapView}
        />
      );
    } else {
      throw new Error('Rendering grouping overview on non grouped activity');
    }
  };

  const renderGrouping = () => {
    if (activity.grouping.mode === 'Groups') {
      return (
        <AdminMatchScreen
          sessionId={sessionId}
          groupPrefix={session.groupPrefix || ''}
          activity={activity}
          activities={activities}
          productions={productions}
          manageGroup={handleManageGroupView}
        />
      );
    } else {
      throw new Error('Rendering grouping on non grouped activity');
    }
  };
  const user = { id: userId, name: userName, email: userEmail };

  const renderFacilitatorDashboard = () => {
    const activityProductions = _.sortBy(
      Object.values(productions[activity.name] || {}),
      'index'
    );
    switch (getActivityType(activity)) {
      case 'post':
        return (
          <PostFacilitationDashboard
            sessionId={sessionId}
            activity={activity}
          />
        );
      case 'choose':
        return (
          <ChooseFacilitationScreen
            sessionId={sessionId}
            activity={activity}
            postActivity={activities['post']}
          />
        );
      case 'augment_post':
        return activityProductions.map((production, index) => (
          <GroupingFacilitationLayout
            key={index}
            sessionId={sessionId}
            activity={activity}
            production={production}
            groupPrefix={session.groupPrefix}
            user={user}
            manageGroup={handleManageGroupView}
            showMindmap={showMindMapView}
            videoGroupId={videoGroupId}
            selectVideoGroupId={selectVideoGroupId}
            resetVideoGroupId={resetVideoGroupId}
            hasVideo={session.internalVisio || false}
          >
            {(props) => (
              <AugmentedPostFacilitationView
                sessionId={sessionId}
                activity={activity}
                groupPrefix={session.groupPrefix}
                user={user}
                {...props}
              />
            )}
          </GroupingFacilitationLayout>
        ));
      case 'document':
        return activityProductions.map((production, index) => (
          <GroupingFacilitationLayout
            key={index}
            sessionId={sessionId}
            activity={activity}
            production={production}
            groupPrefix={session.groupPrefix}
            user={user}
            manageGroup={handleManageGroupView}
            showMindmap={showMindMapView}
            videoGroupId={videoGroupId}
            selectVideoGroupId={selectVideoGroupId}
            resetVideoGroupId={resetVideoGroupId}
            hasVideo={session.internalVisio || false}
          >
            {(props) => (
              <DocumentFacilitationView
                sessionId={sessionId}
                activity={activity}
                groupPrefix={session.groupPrefix}
                user={user}
                {...props}
              />
            )}
          </GroupingFacilitationLayout>
        ));
      case 'break':
        return (
          <SimpleCardScreen
            editable={false}
            cardContent={
              activity.screens['content']?.content.template.editableContent
                .cardContent || ''
            }
            facilitationInstruction={
              activity.screens['content']?.content.template.editableContent
                .facilitationInstruction || ''
            }
            mode={mode}
          />
        );
      case 'content':
        return (
          <DebriefView
            sessionId={sessionId}
            userId={userId}
            readOnly={role === 'participant' || mode === 'preview'}
            margin="max-w-4xl"
          />
        );
      case 'feedback':
        return (
          <div className="mx-auto max-w-3xl">
            <FeedbackView
              sessionId={sessionId}
              showExportButton={false}
              anonymous={true}
            />
          </div>
        );
      case 'unknown':
        return (
          <div className="mx-auto max-w-3xl">
            {t('sessions:neutralActivityDescription')}
          </div>
        );
    }
  };

  const missingGroup = !!(groupActivityName && groupLoaded && !groupId);

  useEffect(() => {
    if (!groupId) {
      hideGroupModal();
    } else {
      showGroupModal();
    }
  }, [groupId, showGroupModal, hideGroupModal]);

  useProductions(
    sessionId,
    session.communityId,
    activity,
    activities,
    userId,
    role,
    session.groupPrefix,
    groupId || undefined,
    group || undefined
  );

  const setGroupModalContent = (content: string) =>
    db
      .ref(
        `sessionsNextTemplates/${sessionId}/activities/${activity.name}/groupingModalContent`
      )
      .set(content);

  const renderScreen = (screen: ScreenType | VirtualScreen) => {
    const { name } = screen;

    return (
      <Route
        key={name}
        path={`${path}/${name}`}
        render={(props) => (
          <div>
            {mode !== 'facilitator' ? (
              <Screen
                sessionId={sessionId}
                communityId={session.communityId}
                sessionDataId={sessionDataId}
                userId={userId}
                userName={userName}
                productions={productions}
                screen={screen as unknown as ScreenType}
                activities={activities}
                activity={activity}
                editable={mode === 'editor'}
                navigation={{ goToNext, goToPrev, state }}
                mode={mode}
                context={context}
                group={group}
                {...props}
              />
            ) : (
              renderFacilitation(`${path}`)
            )}
          </div>
        )}
      />
    );
  };

  const grouping = groupActivityName
    ? activities[groupActivityName]?.grouping
    : undefined;
  const groupSettings =
    grouping?.mode === 'Groups' ? grouping.settings : undefined;

  return (
    <>
      <div className="relative w-full">
        {(mode === 'participant' ||
          mode === 'preview' ||
          mode === 'editor') && (
          <>
            {activity.grouping.mode !== 'All' && mode === 'editor' && (
              <div className="m-auto max-w-3xl flex-col">
                <Disclosure
                  defaultOpen
                  title={
                    <div className="flex items-center space-x-2">
                      <h2 className="text-2xl font-semibold uppercase text-black">
                        {t('sessions:groupAndrole')}
                      </h2>
                    </div>
                  }
                >
                  <MDReactComponent
                    text={activity.groupingModalContent || ''}
                    editable={true}
                    setText={setGroupModalContent}
                    zIndex={1000}
                  />
                </Disclosure>
              </div>
            )}
            <GroupModal
              open={groupModalVisible}
              onClose={hideGroupModal}
              group={group}
              content={activity.groupingModalContent || ''}
              setContent={setGroupModalContent}
              editable={false}
              groupPrefix={session.groupPrefix}
            />
          </>
        )}
        {mode === 'participant' && missingGroup ? (
          <GroupAssignementView
            sessionId={sessionId}
            userId={userId}
            activity={activity}
            canJoinGroup={groupSettings?.membersCanChangeAndJoin}
            groupPrefix={session.groupPrefix || ''}
          />
        ) : (
          <Switch>
            {orderedScreens.map((screen) => renderScreen(screen))}
            <Route
              path={`${path}/*`}
              render={() => {
                return <Redirect to={`${path}/${orderedScreens[0].name}`} />;
              }}
            />
            <Route
              path={`${path}/`}
              render={() => {
                return <Redirect to={`${path}/${orderedScreens[0].name}`} />;
              }}
            />
          </Switch>
        )}
      </div>
    </>
  );
};

export default ActivityElement;
