import React, { useState } from 'react';

import {
  ActivitiesMeta,
  Activity,
  OrderedActivities,
  SessionLanguage,
  UserType,
} from 'types/types';
import { Mode } from 'types/ui';
import { getSelectableActivities } from 'model/dataHooks';
import {
  canAddActivity,
  canDeleteActivity,
  canMoveActivity,
} from 'model/activitiesManagement';
import { formatTime } from 'utils/utils';

import ActivityStep from './Step';
import Dropzone from './DropzoneStep';

import { Clock, Puzzle, MoveVertically } from 'assets/icons';
import _ from 'lodash';

const ActivityStepper = ({
  userType,
  orderedActivities,
  selectedActivity,
  mode,
  toggleActivity,
  toggleNextActivity,
  enabledActivities,
  activitiesMeta,
  onClickStepSelect,
  onDeleteActivity,
  onInsertActivityBelow,
  onActivitySettings,
  onResetActivityTime,
  autoSwitchActivites,
  onStopActivityTime,
  onResumeActivityTime,
  onUpdateActivityTime,
  forceHideStatus = false,
  lng,
  smallMode = false,
  reindexActivities,
}: {
  userType: UserType;
  orderedActivities: OrderedActivities;
  selectedActivity: string;
  mode: Mode;
  toggleActivity: (name: string) => void;
  toggleNextActivity: (name: string) => void;
  enabledActivities: Record<string, boolean>;
  activitiesMeta: ActivitiesMeta;
  onClickStepSelect?: (name: string) => void;
  onDeleteActivity: (name: string) => Promise<void>;
  onInsertActivityBelow: (
    prevName: string,
    newName: string,
    sourceActivity: string
  ) => Promise<string>;
  onActivitySettings: (activityName: string) => void;
  onResetActivityTime: (activity: Activity) => Promise<void>;
  onStopActivityTime: (
    activity: Activity,
    remainingMs: number
  ) => Promise<void>;
  onResumeActivityTime: (activity: Activity) => Promise<void>;
  autoSwitchActivites: boolean;
  onUpdateActivityTime: (
    activity: Activity,
    remainingMs: number
  ) => Promise<void>;
  forceHideStatus?: boolean;
  lng: SessionLanguage;
  smallMode: boolean;
  reindexActivities: (orderedActivities: OrderedActivities) => void;
}) => {
  const [draggedArray, setDragged] = useState<[number, boolean] | null>(null);
  const dragged = draggedArray?.[0] ?? null;
  const fluid = draggedArray?.[1] ?? null;

  const [shadowActivities, setShadowActivities] =
    useState<OrderedActivities | null>(null);

  const activities = shadowActivities ? shadowActivities : orderedActivities;
  const selectableActivity = getSelectableActivities(
    enabledActivities,
    activities,
    selectedActivity
  );

  return (
    <div className="flex w-full flex-col justify-start px-4">
      <>
        {activities.map((step, i) => {
          const selectable =
            mode !== 'participant' ||
            (mode === 'participant' && selectableActivity(step));

          const canDelete = canDeleteActivity(step);
          const canAdd = canAddActivity(step);
          const canMove = canMoveActivity(step);
          const draggable = mode === 'editor';

          const onDragStart = () => {
            setTimeout(() => {
              setDragged([
                i,
                step.screens['content']?.content.template.name ===
                  'SimpleCardScreen',
              ]);
              setShadowActivities([...orderedActivities]);
            });
          };

          const onDragEnd = async () => {
            if (shadowActivities) {
              await reindexActivities(shadowActivities);
            }
            setDragged(null);
            setShadowActivities(null);
          };

          const onDragEnter = (newIndex: number) => () => {
            if (_.isNumber(dragged) && shadowActivities) {
              const targetIndex = dragged < newIndex ? newIndex - 1 : newIndex;
              const newShadowActivities =
                shadowActivities.reduce<OrderedActivities>(
                  (prev, activity, index) => {
                    if (index !== dragged) {
                      prev.push(activity);
                    }
                    return prev;
                  },
                  []
                );

              newShadowActivities.splice(
                targetIndex,
                0,
                shadowActivities[dragged]
              );
              setDragged([targetIndex, !!fluid]);
              setShadowActivities(newShadowActivities);
            }
          };

          return !smallMode || selectedActivity === step.name ? (
            <div key={step.name}>
              {draggable &&
              ((canMove &&
                step.index >
                  (activities.find((act) => act.name === 'share')?.index ??
                    9999)) ||
                fluid) &&
              dragged !== null &&
              dragged !== i &&
              dragged !== i - 1 ? (
                <Dropzone onDragEnter={onDragEnter(i)} />
              ) : null}
              <div
                className="flex items-center"
                draggable={draggable && canMove}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
              >
                {draggable ? (
                  <div
                    className={`flex cursor-move justify-center rounded-full bg-surfaces-strong p-1 text-center hover:bg-surfaces-strongest 
                ${canMove ? 'visible' : 'invisible'}`}
                  >
                    <MoveVertically className="h-4 w-4 stroke-2" />
                  </div>
                ) : null}
                <ActivityStep
                  userType={userType}
                  mode={mode}
                  activity={step}
                  activities={_.keyBy(activities, 'name')}
                  lng={lng}
                  forceHideStatus={forceHideStatus}
                  isEnabled={enabledActivities[step.name]}
                  isSelectable={selectable || false}
                  isSelected={selectedActivity === step.name}
                  activityMeta={activitiesMeta[step.name]}
                  toggleActivity={() => {
                    if (mode === 'facilitator') {
                      toggleActivity(step.name);
                    }
                  }}
                  selectActivity={() =>
                    selectable &&
                    onClickStepSelect &&
                    onClickStepSelect(step.name)
                  }
                  deleteActivity={
                    canDelete ? () => onDeleteActivity(step.name) : undefined
                  }
                  inserActivity={
                    canAdd
                      ? async (name: string, source: string) => {
                          const key = await onInsertActivityBelow(
                            step.name,
                            name,
                            source
                          );
                          onClickStepSelect?.(key);
                          onActivitySettings(key);
                        }
                      : undefined
                  }
                  resetActivityTimer={() => onResetActivityTime(step)}
                  showActivitySettings={() => onActivitySettings(step.name)}
                  stopActivityTime={(remainingMs: number) =>
                    onStopActivityTime(step, remainingMs)
                  }
                  resumeActivityTime={() => onResumeActivityTime(step)}
                  updateActivityTime={(remainingMs: number) =>
                    onUpdateActivityTime(step, remainingMs)
                  }
                  onActivityEnd={() => {
                    if (autoSwitchActivites) {
                      toggleNextActivity(step.name);
                    }
                  }}
                  toggleNextActivity={() => toggleNextActivity(step.name)}
                  smallMode={smallMode}
                />
              </div>
              {draggable &&
              dragged !== null &&
              i !== dragged &&
              i === activities.length - 1 ? (
                <Dropzone onDragEnter={onDragEnter(i + 1)} />
              ) : null}
            </div>
          ) : undefined;
        })}
        {mode === 'editor' && !smallMode ? (
          <div className="mx-auto mt-4 flex flex-row space-x-6 text-black">
            <div className="flex flex-row items-center space-x-1">
              <Puzzle className="h-6 w-6 stroke-2" />
              <p className="text-lg">{activities.length}</p>
            </div>
            <div className="flex flex-row items-center space-x-1">
              <Clock className="h-6 w-6 stroke-2" />
              <p className="text-lg">
                {formatTime(
                  activities.reduce(
                    (duration, activity) => duration + (activity.duration || 0),
                    0
                  )
                )}{' '}
              </p>
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
};

export default ActivityStepper;
