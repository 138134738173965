import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import intersection from 'lodash/intersection';
import { generateRandomString } from 'utils/utils';
import _ from 'lodash';

import AdminGroupPost from 'componentsOld/Group/AdminGroupPost/index';
import AdminGroupList from 'componentsOld/Group/AdminGroupList/index';
import { ChooseGroupModal } from 'componentsOld/Group/AdminGroupActions';

import {
  removeUserFromGroup,
  deleteGroup,
  addGroup as createGroup,
  resetGroups,
  addUsersToGroup,
  cleanGroupProductions,
  setMatchingStatus,
  moveUserToGroup,
} from 'model/groupManagement';

import YesNoModal from 'components/YesNoModal';
import FormattedText from 'components/FormattedText';
import RoundedButton from 'components/RoundedButton';
import { PaperPlane, PlayCircle, Plus } from 'assets/icons';
import Button from 'components/Button';
import { LinkWithIcon } from 'components/Link';
import Tooltip from 'components/Tooltip';
import SelectorInput from 'components/SelectorInput';
import GroupListModal from 'screens/AdminMatchScreen/blocks/GroupListModal';

class AdminMatchEditGroupsScreen extends Component {
  state = {
    selectedPosts: [],
    selectedTags: [],
    showModalConfirmationRestartMatching: false,
    showModalConfirmationRemoveGroup: false,
    confirmationCode: generateRandomString(6, true),
    searchedPostId: null,
    showMoveGroupsModal: false,
  };

  groupIdToRemove = null;

  // componentDidMount() {
  //   this.onUpdate(this.props);
  // }

  // componentDidUpdate(prevProps, nextProps, snapshot) {
  //   this.onUpdate(this.props);
  // }

  // onUpdate(props) {
  //   // console.log('props', props);
  //   // this.setState({ // WHY>??>>>>?>?????????
  //   //   ...props
  //   // });
  // }

  resetSelection = () => {
    this.setState({
      selectedPosts: [],
      selectedTags: [],
    });
  };

  handleTagSelect = (tagRef) => () => {
    const { tagsCounter, posts } = this.state;
    const activeTags = tagsCounter
      .filter(({ tag, active }) => active || tag === tagRef)
      .map(({ tag }) => tag);
    this.setState({
      tagsCounter: tagsCounter.map((tag) =>
        tag.tag === tagRef
          ? {
              ...tag,
              active: !tag.active,
            }
          : tag
      ),
      nbSelectedPosts: posts.filter(
        ({ locked, tags }) => !locked && intersection(tags, activeTags).length
      ).length,
    });
  };

  handleSelectPost =
    ({ id: refId }) =>
    () => {
      const { selectedPosts } = this.state;
      this.setState({
        selectedPosts: selectedPosts.includes(refId)
          ? selectedPosts.filter((id) => id !== refId)
          : [...selectedPosts, refId],
      });
    };

  handleSelectTag = (refTag) => () => {
    const { selectedTags } = this.state;
    this.setState({
      selectedTags: selectedTags.includes(refTag)
        ? selectedTags.filter((tag) => tag !== refTag)
        : [...selectedTags, refTag],
    });
  };

  handleShowMoveGroupsModal = (show) => {
    this.setState({ showMoveGroupsModal: show });
  };

  activePost = (selectedPosts) => (post) => ({
    ...post,
    active: selectedPosts.includes(post.id),
  });

  activeTags = (selectedTags) => (post) => {
    const activeTags = intersection(selectedTags, post.tags);
    return {
      ...post,
      active: post.active || (activeTags && activeTags.length > 0),
      tags:
        post.tags &&
        post.tags.map((tag) => ({ tag, active: activeTags.includes(tag) })),
    };
  };

  addGroup = () => {
    const { sessionId, activityName, activities } = this.props;
    const { selectedPosts } = this.state;

    createGroup(
      sessionId,
      activities,
      activityName,
      undefined,
      selectedPosts.map((userId) => ({ id: userId }))
    );

    this.resetSelection();
  };

  addToGroup = (group) => {
    const { sessionId, activityName } = this.props;
    const { selectedPosts } = this.state;

    addUsersToGroup(sessionId, activityName, group.id, selectedPosts);

    this.resetSelection();
  };

  onClickRemoveGroup = (groupId) => () => {
    this.groupIdToRemove = groupId;
    this.setState({
      showModalConfirmationRemoveGroup: true,
    });
  };

  onClickRestartMatching = () => {
    this.setState({
      showModalConfirmationRestartMatching: true,
      confirmationCode: generateRandomString(6, true),
    });
  };

  onConfirmResetGroups = async () => {
    const { sessionId, activityName, activities } = this.props;
    await cleanGroupProductions(sessionId, activityName, activities);
    await resetGroups(sessionId, activityName);
    return setMatchingStatus(sessionId, activityName, 'NotRun');
  };

  onCancelResetGroups = () => {
    this.setState({ showModalConfirmationRestartMatching: false });
  };

  onConfirmRemoveGroup = () => {
    const { sessionId, activityName, activities } = this.props;
    cleanGroupProductions(
      sessionId,
      activityName,
      activities,
      this.groupIdToRemove
    );
    deleteGroup(sessionId, activityName, this.groupIdToRemove);
    this.groupIdToRemove = null;
    this.setState({ showModalConfirmationRemoveGroup: false });
  };

  onCancelRemoveGroup = () => {
    this.setState({ showModalConfirmationRemoveGroup: false });
  };

  onRemovePost = ({ group, post }) => {
    const { sessionId, activityName, activities } = this.props;
    if (post && group) {
      cleanGroupProductions(
        sessionId,
        activityName,
        activities,
        group.id,
        post.id
      );
      removeUserFromGroup(sessionId, activityName, group.id, post.id);
    } else {
      console.warn('onRemovePost: post or group are not defined', {
        post,
        group,
      });
    }
  };
  onMovePost = (postId, sourceId, targetId, topicId) => {
    const { sessionId, activityName, activities } = this.props;
    moveUserToGroup(
      sessionId,
      activities,
      activityName,
      sourceId,
      targetId,
      postId,
      topicId
    );
  };

  setSearchedPostId = (postId) => {
    this.setState({
      searchedPostId: postId,
    });
  };

  render() {
    const { createGroup, groupPrefix } = this.props;
    const {
      tagsCounter = [],
      selectedPosts,
      selectedTags,
      showModalConfirmationRestartMatching,
      showModalConfirmationRemoveGroup,
      confirmationCode,
      showMoveGroupsModal,
    } = this.state;
    const { t } = this.props;
    const posts = this.props.posts
      .map(this.activePost(selectedPosts))
      .map(this.activeTags(selectedTags));

    return (
      <Fragment>
        <YesNoModal
          size="lg"
          onClickYes={this.onConfirmResetGroups}
          onClickNo={this.onCancelResetGroups}
          open={showModalConfirmationRestartMatching}
          confirmationCode={confirmationCode}
          textYes={t('common:Delete')}
          textNo={t('common:Cancel')}
          onClose={this.onCancelResetGroups}
          critical
        >
          <FormattedText>
            {t('facilitator:restartMatchingMessage')}
          </FormattedText>
        </YesNoModal>
        <YesNoModal
          size="lg"
          onClickYes={this.onConfirmRemoveGroup}
          onClickNo={this.onCancelRemoveGroup}
          open={showModalConfirmationRemoveGroup}
          textYes={t('common:Delete')}
          textNo={t('common:Cancel')}
          onClose={this.onCancelRemoveGroup}
          critical
        >
          <FormattedText>{t('facilitator:deleteGroupMessage')}</FormattedText>
        </YesNoModal>

        <div className="flex w-full flex-row items-center justify-between space-x-2 border-b px-2 pb-2">
          <div className="flex grow flex-row items-center space-x-4">
            <h2 className="text-2xl font-semibold">
              {t('facilitator:GroupManagement')}
            </h2>
            <LinkWithIcon
              text={t('facilitator:BackToGroupAnimation')}
              onClick={() => {
                this.props.manageGroup(false);
              }}
            >
              <PlayCircle className="h-6 w-6 shrink-0 stroke-3/2" />
            </LinkWithIcon>
            <GroupListModal
              users={_.flatMap(
                this.props.groups.map((group) =>
                  group.posts.map((post) => ({
                    id: post.creatorUserId,
                    creator: post.creator,
                    creatorEmail: post.creatorEmail,
                    group: t('common:groupX', {
                      groupPrefix: groupPrefix,
                      number: group.number,
                    }),
                  }))
                )
              )}
            />
          </div>
          <Button
            className="shrink-0"
            text={t('facilitator:resetGroups')}
            size="md"
            color="danger"
            onClick={this.onClickRestartMatching}
          />
        </div>
        <div className="mt-1 flex w-full space-x-2 overflow-visible">
          <div>
            <div className="flex h-12 flex-row items-center justify-between space-x-2 px-2">
              <h3 className="font-semibold">
                {t('facilitator:ungroupedPost', {
                  count: (posts && posts.length) || 0,
                })}
              </h3>
              <Tooltip
                content={
                  selectedPosts.length > 0
                    ? t('facilitator:moveToGroup')
                    : undefined
                }
              >
                <>
                  <RoundedButton
                    className={`${
                      selectedPosts.length > 0 ? 'visible' : 'invisible'
                    }`}
                    color="primary"
                    onClick={() => {
                      this.handleShowMoveGroupsModal(true);
                    }}
                  >
                    <PaperPlane className="mr-1 rotate-90 stroke-2" />
                  </RoundedButton>
                  {showMoveGroupsModal ? (
                    <ChooseGroupModal
                      active
                      groups={this.props.groups}
                      groupPrefix={groupPrefix}
                      onExit={() => {
                        this.handleShowMoveGroupsModal(false);
                      }}
                      onClickGroup={(group) => {
                        selectedPosts.forEach((postId) => {
                          this.onMovePost(
                            postId,
                            null,
                            group.id,
                            group.topicId
                          );
                        });
                        this.resetSelection();
                      }}
                      false
                    />
                  ) : null}
                </>
              </Tooltip>
            </div>
            {posts &&
              posts.map((post) => (
                <AdminGroupPost
                  groupPrefix={groupPrefix}
                  key={post.id}
                  {...post}
                  onSelectPost={this.handleSelectPost(post)}
                  onSelectTag={this.handleSelectTag}
                  groups={this.props.groups}
                  onMove={(targetId, topicId) => {
                    this.onMovePost(post.id, null, targetId, topicId);
                  }}
                  highlighted={this.state.searchedPostId === post.id}
                />
              ))}
          </div>
          <div className="w-full">
            <div className="flex h-12 w-full flex-row items-center justify-between px-2">
              <div className="flex flex-row items-center space-x-2">
                <h3 className="font-semibold">
                  {t('facilitator:GroupComposition', {
                    count: (this.props.groups && this.props.groups.length) || 0,
                  })}
                </h3>
                <Tooltip content={t('facilitator:createGroup')}>
                  <RoundedButton color="success" onClick={this.addGroup}>
                    <Plus className="stroke-2" />
                  </RoundedButton>
                </Tooltip>
              </div>

              <SelectorInput
                compact
                placeholder={t('facilitator:SearchParticipant')}
                options={this.props.allPosts.reduce((prev, post) => {
                  prev[post.id] = post;
                  return prev;
                }, {})}
                descriptionKey={'creator'}
                value={this.state.searchedPostId}
                onChange={this.setSearchedPostId}
                allowClear
              />
            </div>
            <AdminGroupList
              groups={this.props.groups}
              onRemove={this.onClickRemoveGroup}
              updateGroup={this.props.updateGroup}
              onRemovePost={this.onRemovePost}
              onMovePost={this.onMovePost}
              availableTags={tagsCounter}
              createGroupType={createGroup}
              highlight={(post) => post.id === this.state.searchedPostId}
              groupPrefix={groupPrefix}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

AdminMatchEditGroupsScreen.defaultProps = {
  groups: [],
  posts: [],
};

export default withTranslation()(AdminMatchEditGroupsScreen);
