import { Quote } from 'assets/icons';
import Button from 'components/Button';
import Copyable from 'components/Copyable';
import _ from 'lodash';
import { countVotes } from 'model/votes';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionRecord, ProductionMeta, RSet, Topic, Users } from 'types/types';
import { Nullable } from 'types/typesUtils';
import PostBlock from './blocks/PostBlock';
import { database as db } from 'services/firebase';

export type BestPostsByTopicsProps = {
  sessionId: string;
  postsSummaries: Nullable<Record<string, string>>;
  posts: Nullable<Record<string, string>>;
  postsMeta: Nullable<RSet<ProductionMeta>>;
  postTitles: Nullable<Record<string, string>>;
  votes: Nullable<Record<string, string>>;
  participants: Users;
  topics: Nullable<RSet<Topic>>;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
  marginClasses: string;
  groupOfUser: Nullable<Record<string, string>>;
};

const BestPostsByTopics = ({
  sessionId,
  postsSummaries,
  posts,
  postsMeta,
  postTitles,
  votes,
  participants,
  topics,
  allowAutomatedSynthesis,
  marginClasses,
  groupOfUser,
}: BestPostsByTopicsProps) => {
  const [viewAllTopics, setViewAllTopics] = useState<boolean>(true);
  const { t } = useTranslation();

  const toggleViewAllTopics = () => {
    setViewAllTopics((val) => !val);
  };

  const topicsPost = useMemo(() => {
    return Object.entries(postsMeta || {}).reduce<Record<string, string[]>>(
      (prev, [userId, postMeta]) => {
        const post = posts?.[userId];

        if (postMeta.topic && post) {
          if (prev[postMeta.topic]) {
            prev[postMeta.topic].push(userId);
          } else {
            prev[postMeta.topic] = [userId];
          }
        }
        return prev;
      },
      {}
    );
  }, [postsMeta, posts]);

  const countedVotesRaw = countVotes(votes, posts || {});

  const selectableVotes = _.pickBy(
    countedVotesRaw,
    (_val, userId) => !!groupOfUser?.[userId]
  );

  const countedVotes =
    _.size(selectableVotes) > 0 ? selectableVotes : countedVotesRaw;

  const bestPostsByTopics = _.mapValues(topicsPost, (posts, topicId) =>
    _.sortBy(
      posts,
      (p1, p2) => (countedVotes[p1] || 0) - (countedVotes[p2] || 0)
    ).slice(0, viewAllTopics ? undefined : 2)
  );

  const copyExperience = (userId: string) => {
    const userName = participants[userId]?.name || t('users:UnknownUser');
    const postTitle = postTitles?.[userId];
    const post = posts?.[userId];
    const postSummary = postsSummaries?.[userId];

    const textToCopy = postTitle
      ? `${postTitle} - ${userName}
  ${post}`
      : `${userName} - ${post}`;

    const summaryToCopy = postTitle
      ? `${postTitle} - ${userName}
  ${postSummary || ''}`
      : `${userName} - ${post}`;

    return postSummary && postSummary !== 'error' ? summaryToCopy : textToCopy;
  };

  const textToCopy = `${t('sessions:BestPostsByTopics')}

${Object.entries(bestPostsByTopics)
  .map(
    ([topicId, postsId]) =>
      `${topics?.[topicId].description}

${postsId
  .map(
    (userId) => `
${copyExperience(userId)}
`
  )
  .join('')}
`
  )
  .join('')}`;

  const regenerate = async () => {
    const toReset = _.mapValues(
      _.pickBy(postsSummaries || {}, (sum) => sum === 'error'),
      () => null
    );

    if (_.size(toReset) > 0) {
      await db
        .ref(`sessionsNextData/${sessionId}/debrief/summaries/post/`)
        .update(toReset);
    }
  };

  return topics && _.size(bestPostsByTopics) > 0 ? (
    <div className={`${marginClasses}`}>
      <Copyable top={0} textToCopy={textToCopy}>
        <div className="flex flex-row space-x-2">
          <h2 className="text-xl font-semibold">
            {t('sessions:BestPostsByTopics')}
          </h2>
          <Button
            text={viewAllTopics ? t('common:seeLess') : t('common:seeMore')}
            onClick={() => toggleViewAllTopics()}
            size="sm"
          />

          {allowAutomatedSynthesis ? (
            <Button
              color="gray"
              text={t('sessions:Regenerate')}
              onClick={() => regenerate()}
              size="sm"
            />
          ) : null}
        </div>
      </Copyable>

      <div className="relative rounded-md bg-gray-50 px-8 mt-4 pt-2 pb-2 text-lg italic">
        <Quote className="absolute left-0 -top-1 h-10 w-10 text-primary" />
        {Object.entries(bestPostsByTopics).map(([topicId, postsId]) => {
          return postsId.length > 0 && topics[topicId] ? (
            <div className="mt-8 space-y-2" key={topicId}>
              <h3 className="text-lg font-semibold">
                {topics[topicId].description}
              </h3>
              <div className="space-y-4">
                {postsId.map((userId) => {
                  return posts?.[userId] ? (
                    <PostBlock
                      key={userId}
                      allowAutomatedSynthesis={allowAutomatedSynthesis}
                      sessionId={sessionId}
                      userId={userId}
                      post={posts[userId]}
                      postTitle={postTitles?.[userId]}
                      postSummary={postsSummaries?.[userId] || null}
                      user={participants[userId]}
                    />
                  ) : null;
                })}
              </div>
            </div>
          ) : null;
        })}
      </div>
    </div>
  ) : null;
};

export default BestPostsByTopics;
