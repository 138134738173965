import MainBar from 'components/MainBar';
import React, { useState, useRef } from 'react';
import {
  RoleBar,
  SessionTemplate,
  User,
  UserType,
  SessionStatusState,
} from 'types/types';
import { Arrow3 } from 'assets/patterns';
import {
  Clock,
  Pencil,
  LocationMarker,
  VideoCamera,
  ChevronRight,
} from 'assets/icons';
import { useHistory, useParams } from 'react-router-dom';
import {
  useFullSessionFromAccessCode,
  useTextReplaceSession,
} from 'model/sessions';
import LoadingScreen from 'screens/LoadingScreen';
import Thumbnail from 'components/Thumbnail';
import { useOrganizersAsUsers } from 'model/dataHooks';
import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Button from 'components/Button';
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import PostList from './blocks/PostList';
import { pickBy } from 'lodash';
import Post from 'blocks/Post';
import PostModal from './blocks/PostModal';
import Tooltip from 'components/Tooltip';
import _ from 'lodash';
import { useUsers } from 'model/users';
import ContentExplorer from 'componentsOld/ContentExplorer';
import { useIntersection } from 'utils/utils';
import Disclosure from 'components/Disclosure';
import ListItem from 'components/ListItem';
import { useObfuscatePosts } from 'services/remoteConfig';
import { DEFAULT_SESSION_TYPE_VALUE } from 'constants/AppConfig';

export type PreviewSessionProps = {
  userType: UserType;
  userName: string;
  userEmail: string;
  userId: string;
  role: RoleBar;
  switchToSession: () => void;
  sessionState: SessionStatusState;
};

const PreviewSession = ({
  userId,
  userName,
  userType,
  userEmail,
  role,
  switchToSession,
  sessionState,
}: PreviewSessionProps) => {
  const { t } = useTranslation();
  const participateRef = useRef(null);
  const mainParticipateButtonInViewport = useIntersection(
    participateRef.current,
    '0px'
  );

  const history = useHistory();

  const [editingPost, setEditingPost] = useState<boolean>(false);

  const db = useFirebase();

  const { accessCode } = useParams<{ accessCode: string }>();

  const [sessionId, session, community, , loaded] =
    useFullSessionFromAccessCode(accessCode);

  const sessionType = session?.type || DEFAULT_SESSION_TYPE_VALUE;
  const [organizers, organizersLoaded] = useOrganizersAsUsers(
    sessionId || 'nowayyoufindme'
  );

  const organizersArray: User[] = Object.values(organizers || {});

  const sessionTemplateRef = [
    ...(sessionId ? [`sessionsNextTemplates/${sessionId}`] : []),
  ];

  useFirebaseConnect(sessionTemplateRef);

  const sessionTemplate: SessionTemplate | null = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextTemplates?.[sessionId || 'nowayyoufindme']
  );

  const textReplaceSession = useTextReplaceSession(
    sessionId || 'nowayyoufindme',
    'post',
    userId
  );

  const hasPosts = !!sessionTemplate?.activities?.post;
  const hasChoose = !!sessionTemplate?.activities?.post;

  const postRef = [
    ...(hasPosts
      ? [`sessionsNextData/${sessionId}/activities/post/productions/post`]
      : []),
  ];

  const voteRef = [
    ...(hasChoose
      ? [
          {
            path: `sessionsNextData/${sessionId}/activities/choose/productions/vote/${userId}`,
            storeAs: `myVotes/${sessionId}`,
          },
        ]
      : []),
  ];

  useFirebaseConnect([...postRef, ...voteRef]);

  const posts: Record<string, string> | null = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId || 'nowayyoufindme']
        ?.activities?.post?.productions?.post
  );

  const votes: Record<string, true> | null = useSelector(
    (state: any) => state.firebase.data.myVotes?.[sessionId || 'nowayyoufindme']
  );

  const [users] = useUsers(posts || {});

  const participate = () => {
    history.push(`/${session?.accessCode}/live`);
  };

  const share = () => {
    setEditingPost(true);
  };

  const isAfter = sessionState === 'finished';
  const hasBegun = sessionState === 'live';

  const toggleVote = async (targetUserId: string, curr: boolean) => {
    const ref = db.ref(
      `sessionsNextData/${sessionId}/activities/choose/productions/vote/${userId}/${targetUserId}`
    );
    try {
      if (curr) {
        await ref.remove();
      } else {
        await ref.set(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const myPost: string | null =
    (hasPosts &&
      posts &&
      Object.values(pickBy(posts, (_val, key) => key === userId))[0]) ||
    null;

  const myChoices: Record<string, string> = _.pickBy(
    posts || {},
    (_val, key) => votes?.[key]
  );

  const renderBlockSessionPreview = () => {
    return (
      <div className="rounded-xl border border-surfaces-divider bg-white p-8">
        <p className="mb-1 text-xs font-semibold uppercase text-primary">
          {`${moment(session!.scheduledAt[0]).format(t('misc:dateFormat'))} ${t(
            'misc:to'
          )} ${moment(session!.scheduledAt[0]).format(t('misc:timeFormat'))}`}
        </p>
        <div>
          <Disclosure
            defaultOpen
            collapsable={_.size(session?.goals) > 0}
            title={(open) => (
              <div className="flex items-center">
                <h2 className={`text-xl ${open ? '' : 'font-light'}`}>
                  {session!.title}
                </h2>
              </div>
            )}
            padding="py-2"
          >
            {_.size(session?.goals) > 0 ? (
              <div className="mt-2">
                <h3 className="mb-1 text-sm font-medium">
                  {t('sessions:SessionsGoal')}
                </h3>
                <ListItem
                  items={session?.goals || []}
                  textClassName={'text-sm font-light'}
                />
              </div>
            ) : null}
          </Disclosure>
        </div>
        <div className="mt-2  pt-4">
          <h2 className="font-semibold whitespace-pre-line text-xl">
            {textReplaceSession(
              sessionTemplate?.activities?.post?.screens?.create?.content
                .template.editableContent.question || ''
            )}
          </h2>
          <p className="whitespace-pre-line mt-4">
            {sessionTemplate?.activities?.post?.screens?.create?.content
              .template.editableContent.description || ''}
          </p>
        </div>
        {role === 'participant' && !myPost && !isAfter && !hasBegun ? (
          <div
            ref={participateRef}
            className="flex w-full flex-row justify-end"
          >
            <Button
              onClick={share}
              className="mt-6 px-16"
              text={t('sessions:Participate')}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderBlockMyPost = () => {
    if (myPost) {
      return (
        <div className="rounded-xl border border-surfaces-divider bg-white p-8">
          <h3 className="mb-4 flex text-xl font-semibold">
            {t('sessions:YourShare')}
          </h3>
          <Post
            content={myPost}
            userName={t('common:You')}
            actionIcon={
              <Tooltip content={t('sessions:EditMyShare')}>
                <Pencil
                  className={`m-auto  h-7 w-7 cursor-pointer stroke-1 text-black-soft hover:text-black`}
                  onClick={() => {
                    setEditingPost(true);
                  }}
                />
              </Tooltip>
            }
          />
        </div>
      );
    }
  };

  const renderBlockMyChoices = () => {
    if (hasPosts && _.size(myChoices) > 0) {
      return (
        <div className="rounded-xl border border-surfaces-divider bg-white p-8">
          <PostList
            role={role}
            title={t('sessions:defaultMyVotesTitle')}
            users={users}
            posts={myChoices}
            votes={votes || {}}
            participate={participate}
            canVote={hasChoose}
            toggleVote={toggleVote}
            voteLimit={
              sessionTemplate?.activities.choose?.screens.like?.content.template
                .params?.noLimit
                ? undefined
                : 3
            }
          />
        </div>
      );
    }
  };

  const obfuscateIfNotPosted = useObfuscatePosts();

  const obfuscate = obfuscateIfNotPosted && !myPost;

  const renderPostsList = () => {
    if (hasPosts && (_.size(posts) > 1 || !myPost)) {
      return (
        <div className="rounded-xl border border-surfaces-divider bg-white p-8">
          <PostList
            obfuscate={obfuscate}
            obfuscateAction={share}
            users={users}
            role={role}
            posts={_.pickBy(posts || {}, (_val, key) => key !== userId)}
            votes={votes || {}}
            participate={() => {
              share();
            }}
            canVote={hasChoose && !obfuscate}
            toggleVote={toggleVote}
            voteLimit={
              sessionTemplate?.activities.choose?.screens.like?.content.template
                .params?.noLimit
                ? undefined
                : 3
            }
          />
        </div>
      );
    }
  };

  const renderContentExplorer = () => {
    return (
      <ContentExplorer
        title={t('sessions:ExploreContentCreatedDuringThisSession')}
        sessionsIds={sessionId ? [sessionId] : undefined}
        user={{
          id: userId,
          name: userName,
          email: userEmail,
        }}
        userType={userType}
      />
    );
  };

  const renderCommunitiesBlock = () => {
    return (
      <div className="w-full rounded-xl border border-surfaces-divider bg-white p-5">
        <div
          className={`-ml-2 flex w-full  flex-row items-center rounded-md py-1 px-2 ${
            community ? 'cursor-pointer hover:bg-primary-soft' : ''
          }`}
          onClick={() => {
            community && history.push(`/community/${session?.communityId}`);
          }}
        >
          <Thumbnail
            name={community ? community.name : organizersArray[0]?.name || ''}
          />
          <div className="ml-3 grow">
            {community ? (
              <p className="text-sm">
                {t('communities:Xmembers', { count: community.nbMembers })}
              </p>
            ) : null}
            <p className="text-lg font-semibold">
              {community
                ? community.name
                : organizersArray[0]?.name
                ? t('communities:CommunityOf', {
                    name: organizersArray[0].name,
                  })
                : t('communities:unknownCommunity')}
            </p>
          </div>
          {community ? (
            <ChevronRight className="h-6 w-6 shrink-0 stroke-2" />
          ) : null}
        </div>

        <div className="mt-4 flex flex-row items-center text-base">
          <Clock className="mr-3 h-7 w-7 stroke-3/2" />
          {moment(session!.scheduledAt[0]).format(t('misc:dateFormat'))},{' '}
          {moment(session!.scheduledAt[0]).format(t('misc:timeFormat'))}{' '}
          {t('misc:to')}{' '}
          {moment(session!.scheduledAt[1]).format(t('misc:timeFormat'))}
        </div>

        <div className="mt-8">
          <p className="font-semibold">
            {sessionType === 'hybrid'
              ? t('sessions:hybrid')
              : sessionType === 'digital'
              ? t('sessions:digital')
              : t('sessions:onSite')}
          </p>
          {sessionType !== 'digital' ? (
            <div className="mt-2 flex flex-row items-center text-base">
              <LocationMarker className="mr-3 h-7 w-7 shrink-0 stroke-3/2" />
              <p className="whitespace-pre-wrap break-all">
                {session?.location}
              </p>
            </div>
          ) : null}

          {sessionType !== 'on_site' ? (
            <div className="mt-2 flex flex-row items-center text-base">
              <VideoCamera className="mr-3 h-7 w-7 shrink-0 stroke-3/2" />
              <a
                className="break-all underline decoration-primary decoration-2 underline-offset-2 line-clamp-3 hover:text-primary"
                href={session?.meetingUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {session?.meetingUrl}
              </a>
            </div>
          ) : null}
        </div>

        {organizersArray.length > 0 && (
          <div className="mt-8">
            <p className="font-semibold">{t('sessions:OrganizedBy')}</p>
            <div className="mt-3 flex flex-row items-center">
              <Avatar name={organizersArray[0].name} />
              <div className="ml-2 text-base">
                <p>
                  <span>{organizersArray[0].name}</span>{' '}
                  {organizersArray.length > 1 && (
                    <>
                      {t('misc:and')}{' '}
                      <span>
                        {organizersArray.length > 2
                          ? t('common:XOtherPeople', {
                              count: useOrganizersAsUsers.length - 1,
                            })
                          : organizersArray[1].name}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
        {role === 'participant' &&
        (myPost || isAfter || hasBegun || !mainParticipateButtonInViewport) ? (
          <Button
            disabled={isAfter || (!!myPost && !hasBegun)}
            onClick={() => (hasBegun || myPost ? participate() : share())}
            className="mt-6 w-full"
            text={
              isAfter
                ? t('sessions:SessionFinished')
                : hasBegun
                ? t('sessions:Join')
                : myPost
                ? t('sessions:Registered')
                : t('sessions:Participate')
            }
          />
        ) : null}
        {role !== 'participant' ? (
          <Button
            onClick={switchToSession}
            design="secondary"
            className="mt-6 w-full"
            text={t('sessions:GoToSession')}
          />
        ) : null}
      </div>
    );
  };

  return loaded && organizersLoaded ? (
    <div className="min-h-screen w-full bg-surfaces-soft pb-16">
      {hasPosts ? (
        <PostModal
          sessionId={sessionId!}
          sessionTemplate={sessionTemplate!}
          userId={userId}
          communityId={session?.communityId}
          editingPost={editingPost}
          close={() => setEditingPost(false)}
        />
      ) : null}
      <MainBar userId={userId} userName={userName} userType={userType} />
      <div className="relative h-48 w-full overflow-hidden bg-primary">
        <Arrow3 className="absolute right-0 text-black" />
        <Arrow3 className="absolute -left-8 bottom-10 rotate-180 text-black" />
      </div>
      <div className="relative mx-auto -mt-32 grid max-w-5xl grid-cols-1 gap-4 px-4 lg:grid-cols-3">
        <div className="space-y-4 lg:col-span-2 lg:col-start-1">
          {renderBlockSessionPreview()}
          <div className="lg:hidden"> {renderCommunitiesBlock()}</div>
          {renderBlockMyPost()}
          {isAfter ? (
            renderContentExplorer()
          ) : (
            <>
              {renderBlockMyChoices()}
              {renderPostsList()}
            </>
          )}
        </div>
        <div className="hidden h-max lg:sticky lg:top-8 lg:col-start-3 lg:block">
          {renderCommunitiesBlock()}
        </div>
      </div>
    </div>
  ) : (
    <LoadingScreen />
  );
};

export default PreviewSession;
