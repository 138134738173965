import React, { useCallback } from 'react';
import { database as db } from 'services/firebase';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/typesUtils';
import { ActionRecord, Session, Users } from 'types/types';
import { computeOrderedVotes } from 'model/votes';
import RevisionnedEditor from 'frameworks/plate/RevisionedEditor';
import PostBlock from './blocks/PostBlock';
import FocusBloc from './blocks/FocusBloc';
import Copyable from 'components/Copyable';
import Button from 'components/Button';

export type FocusProps = {
  sessionId: string;
  session: Session;
  focusSummaries: Nullable<Record<string, string>>;
  posts: Nullable<Record<string, string>>;
  postTitles: Nullable<Record<string, string>>;
  votes: Nullable<Record<string, string>>;
  participants: Users;
  groupOfUser: Nullable<Record<string, string>>;
  shareDocs: Nullable<Record<string, Record<string, true>>>;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
  marginClasses: string;
};

const Focus = ({
  sessionId,
  session,
  focusSummaries,
  votes,
  posts,
  postTitles,
  participants,
  groupOfUser,
  shareDocs,
  allowAutomatedSynthesis,
  marginClasses,
}: FocusProps) => {
  const { t } = useTranslation();

  const orderedVotes = computeOrderedVotes(votes);
  const selectableVotes = orderedVotes.filter(
    ([userId]) => !!groupOfUser?.[userId]
  );
  const userId = selectableVotes[0]?.[0];

  const groupId = groupOfUser?.[userId];

  const metaId =
    groupId && Object.keys(shareDocs?.[groupId]?.[userId] || {})[0];

  const resetFocus = useCallback(async () => {
    await db
      .ref(`sessionsNextData/${sessionId}/debrief/summaries/_focus/${userId}`)
      .remove();
  }, [sessionId, userId]);

  const postTitle = postTitles?.[userId];

  const textToCopy = `${postTitle ? `${postTitle} - ` : ''}${
    focusSummaries?.[userId] || posts?.[userId] || ''
  }`;

  return userId && posts?.[userId] && metaId ? (
    <div className={`${marginClasses}`}>
      <Copyable top={0} textToCopy={textToCopy}>
        <div className="flex flex-row space-x-2">
          <h2 className="text-xl font-semibold">{t('sessions:Focus')}</h2>
          {allowAutomatedSynthesis ? (
            <Button
              color="gray"
              text={t('sessions:Regenerate')}
              onClick={() => resetFocus()}
              size="sm"
            />
          ) : null}
        </div>
      </Copyable>
      {allowAutomatedSynthesis ? (
        <FocusBloc
          sessionId={sessionId}
          session={session}
          postTitle={postTitle}
          userId={userId}
          userName={participants[userId]?.name || t('users:UnknownUser')}
          docId={metaId}
          summary={focusSummaries?.[userId] || null}
        />
      ) : (
        <div className="space-y-4">
          <PostBlock
            sessionId={sessionId}
            allowAutomatedSynthesis={allowAutomatedSynthesis}
            userId={userId}
            post={posts[userId]}
            postSummary={null}
            postTitle={postTitle}
            user={participants[userId]}
          />
          <RevisionnedEditor metaId={metaId} readOnly={true} />
        </div>
      )}
    </div>
  ) : null;
};

export default Focus;
