import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useFirebaseConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import AdminMatchMakeGroupsScreen from './screens/AdminMatchMakeGroupsScreen';
import AdminMatchEditGroupsScreen from './screens/AdminMatchEditGroupsScreen';
import AdminMatchAllGroupsScreen from './screens/AdminMatchAllGroupsScreen';
import {
  Activity,
  Activities,
  Productions,
  Groups,
  User,
  Users,
  MatchingStatus,
  UserActivity,
  AlgoSettings,
  Topic,
} from 'types/types';

import _ from 'lodash';

import { dataPathToRef } from 'model/dataPathUtils';
import { Typography } from 'antd';
import { isUserActive } from 'model/users';
import { revertMaps } from 'utils/utils';

const { Title } = Typography;

type OldPost = {
  id: string;
  content: string;
  creator: string;
  creatorUserId: string;
  creatorActive: boolean;
};

type OldGroup = {
  id: string;
  number: number;
  posts: OldPost[];
};

const AdminMatchScreen = ({
  activity,
  activities,
  productions,
  sessionId,
  groupPrefix,
  manageGroup,
}: {
  activity: Activity;
  activities: Activities;
  productions: Record<string, Productions>;
  sessionId: string;
  groupPrefix: string;
  manageGroup: (edit: boolean) => void;
}): JSX.Element => {
  const grouping = activity.grouping;

  if (grouping.mode !== 'Groups') {
    throw new Error('Should not happen');
  }

  const settings = grouping.settings as AlgoSettings;

  const usersRef = `sessionsNextData/${sessionId}/users/participants`;
  const groupsRef = `sessionsNextData/${sessionId}/activities/${activity.name}/grouping/groups`;
  const groupOfUserRef = `sessionsNextData/${sessionId}/activities/${activity.name}/grouping/groupOfUser`;
  const postsRef = `sessionsNextData/${sessionId}/activities/post/productions/post`;
  const matchingStatusRef = `sessionsNextData/${sessionId}/activities/${activity.name}/grouping/status`;
  const topicsListRef = `sessionsNextData/${sessionId}/tropics`;

  let voteRef: string[] | null = null;
  if (settings.votes) {
    const [ref] = dataPathToRef(
      sessionId,
      undefined,
      productions,
      settings.votes,
      activities
    ) as [string[]];

    voteRef = ref;
  }

  const criteriaRefs: Record<string, string> = {};
  if (settings.criteria) {
    Object.entries(settings.criteria).forEach(([name, info]) => {
      const [ref] = dataPathToRef(
        sessionId,
        undefined,
        productions,
        info.source,
        activities
      ) as [string[]];

      const criteriaRef = ref.join('/');

      criteriaRefs[name] = criteriaRef;
    });
  }

  const refs = [
    matchingStatusRef,
    usersRef,
    groupsRef,
    groupOfUserRef,
    postsRef,
    topicsListRef,
  ];
  if (voteRef !== null) {
    refs.push(voteRef.join('/'));
  }
  const fullRefs = [...refs, ...Object.values(criteriaRefs)];

  useFirebaseConnect(fullRefs.map((ref) => ({ path: ref })));

  const matchingStatus: MatchingStatus = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNextData?.[sessionId]?.activities?.[
        activity.name
      ]?.grouping?.status
  );

  const groups: Groups = useSelector(
    (state: any) =>
      state.firebase?.data?.sessionsNextData?.[sessionId]?.activities?.[
        activity.name
      ]?.grouping?.groups || {}
  );

  const groupOfUser: Record<string, string> = useSelector(
    (state: any) =>
      state.firebase?.data?.sessionsNextData?.[sessionId]?.activities?.[
        activity.name
      ]?.grouping?.groupOfUser || {}
  );

  const posts: Record<string, string> = useSelector(
    (state: any) =>
      state.firebase?.data?.sessionsNextData?.[sessionId]?.activities?.post
        ?.productions?.post || {}
  );

  const topicsList: Record<string, Topic> = useSelector(
    (state: any) =>
      state.firebase?.data?.sessionsNextData?.[sessionId]?.topics || {}
  );

  const participants: Record<string, true> = useSelector(
    (state: any) =>
      state.firebase?.data?.sessionsNextData?.[sessionId]?.users
        ?.participants || {}
  );

  const votes = useSelector((state: any) =>
    voteRef === null ? null : _.get(state.firebase.data, voteRef.join('.'))
  );

  const criteria = useSelector((state: any) =>
    settings.criteria
      ? Object.entries(settings.criteria).reduce(
          (prec: Record<string, Record<string, string>>, [name, info]) => {
            const [ref] = dataPathToRef(
              sessionId,
              undefined,
              productions,
              info.source,
              activities
            ) as [string[]];

            prec[name] = _.get(state.firebase.data, ref.join('.'));

            return prec;
          },
          {}
        )
      : {}
  );

  useFirebaseConnect([
    ...Object.keys(participants).map((id) => ({ path: `/users/${id}` })),
    ...Object.keys(participants).map((id) => ({
      path: `/usersActivity/${id}`,
    })),
  ]);

  const users: Users = useSelector((state: any) =>
    Object.keys(participants).reduce((prec: Record<string, User>, key) => {
      const user = state.firebase.data.users?.[key];
      if (user) {
        prec[key] = user;
      }
      return prec;
    }, {})
  );

  const usersActivity: Record<string, UserActivity> = useSelector(
    (state: any) =>
      Object.keys(participants).reduce(
        (prec: Record<string, UserActivity>, key) => {
          const user = state.firebase.data.usersActivity?.[key];
          if (user) {
            prec[key] = user;
          }
          return prec;
        },
        {}
      )
  );

  // console.log(groups);
  // console.log(groupOfUser);
  // console.log(posts);
  // console.log(participants);
  // console.log(users);
  // console.log(votes);
  // console.log(criteria);

  let oldPosts: Record<string, OldPost> = {};
  let oldGroups: Record<string, OldGroup> = {};

  const postProductions = activities['post']?.productions || {};
  const userCriteria = (id: string): Record<string, string> =>
    Object.entries(criteria).reduce(
      (prec: Record<string, string>, [criteriaName, data]) => {
        const criteriaForUser = data?.[id];
        if (criteriaForUser) {
          const desc = postProductions[criteriaName]?.description;
          prec[desc || criteriaName.slice(5)] = criteriaForUser;
        }
        return prec;
      },
      {}
    );

  if (
    isLoaded(
      groups,
      participants,
      users,
      votes,
      ...Object.values(criteria || {})
    )
  ) {
    const votesByUser = revertMaps(votes || {});

    oldPosts = _.mapValues(participants, (v, userKey) => ({
      id: userKey,
      content: posts[userKey] || 'Unknown content',
      creator: users[userKey]?.name || 'Unknown author',
      creatorEmail: users[userKey]?.email || '',
      creatorActive: !!isUserActive(usersActivity[userKey]),
      creatorUserId: userKey,
      criteria: userCriteria(userKey),
      votes: _.mapValues(
        votesByUser[userKey] || {},
        (_value, key) => users[key]?.name || 'Unknown User'
      ),
    }));

    oldGroups = _.mapValues(groups, ({ number, users, topic }, groupId) => ({
      id: groupId,
      number,
      topicId: topic,
      topic: topic && topicsList[topic]?.description,
      name: `Group ${number}`,
      posts: Object.keys(users || {})
        .map((key) => oldPosts[key])
        .filter((value) => !!value),
      // TODO: if its the case there is an issue
    }));
  }

  const match = useRouteMatch();

  const groupsV = Object.values(oldGroups);

  const renderAdminMatchAllGroups = () => (
    <AdminMatchAllGroupsScreen groups={groupsV} groupPrefix={groupPrefix} />
  );

  const renderAdminMatchMakeOrEditGroup = () => {
    if (matchingStatus !== 'Done') {
      return (
        <AdminMatchMakeGroupsScreen
          sessionId={sessionId}
          activity={activity}
          activities={activities}
          productions={productions}
          matchingStatus={matchingStatus || 'NotRun'}
          participantsCount={_.size(participants)}
          activeParticipantsCount={
            _.countBy(usersActivity || {}, isUserActive)['true'] || 0
          }
        />
      );
    }
    return (
      <AdminMatchEditGroupsScreen
        sessionId={sessionId}
        activities={activities}
        activityName={activity.name}
        posts={Object.values(oldPosts)
          .filter(({ id }) => !groupOfUser[id])
          .sort((postA, postB) =>
            postA.creatorActive === postB.creatorActive
              ? 0
              : postA.creatorActive
              ? -1
              : 1
          )}
        allPosts={Object.values(oldPosts)}
        groups={groupsV}
        groupPrefix={groupPrefix}
        manageGroup={manageGroup}
      />
    );
  };

  const { path } = match;
  const parts = path.split('/');
  const last = parts[parts.length - 1];

  return isLoaded(matchingStatus, groups) ? (
    // <Page fullscreen style={styles.viewMain}>
    last === 'all_groups' ? (
      renderAdminMatchAllGroups()
    ) : (
      renderAdminMatchMakeOrEditGroup()
    )
  ) : (
    // </Page>
    <Title level={4}>Loading ...</Title>
  );
};

export default AdminMatchScreen;
