import React from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/typesUtils';
import _ from 'lodash';
import { Users } from 'types/types';
import Copyable from 'components/Copyable';
import { Quote } from 'assets/icons';

const extractBestFeedbacks = (
  feedbacks: Record<string, any>
): Record<string, string> => {
  const bestFeedbacks: Record<string, string> = _.pickBy(
    feedbacks?.questionAAnswer || {},
    (feedback, userId) =>
      feedbacks?.valuable?.[userId] >= 4 && feedback?.length > 75
  );

  return bestFeedbacks;
};

export type BestFeedbacksProps = {
  feedbacks: Nullable<Record<string, any>>;
  participants: Users;
  marginClasses: string;
};

const BestFeedbacks = ({
  feedbacks,
  participants,
  marginClasses,
}: BestFeedbacksProps) => {
  const { t } = useTranslation();

  const bestFeedbacks = extractBestFeedbacks(feedbacks || {});

  const textToCopy = `${t('sessions:BestFeedbacks')}
${Object.entries(bestFeedbacks)
  .slice(0, 3)
  .map(
    ([userId, feedback]) =>
      `
${
  (participants[userId]?.name || t('users:UnknownUser')).split(' ')[0]
} - ${feedback}`
  )
  .join('\n')}
`;

  return _.size(bestFeedbacks) > 0 ? (
    <div className={`${marginClasses} space-y-2`}>
      <Copyable top={0} textToCopy={textToCopy}>
        <h2 className="text-xl font-semibold">{t('sessions:BestFeedbacks')}</h2>
      </Copyable>
      <div className="relative rounded-md bg-gray-50 px-4 mt-4 py-8 text-lg italic">
        <Quote className="absolute left-0 -top-1 h-10 w-10 text-primary" />
        <div className="space-y-4">
          {Object.entries(bestFeedbacks)
            .slice(0, 3)
            .map(([userId, feedback]) => (
              <blockquote
                key={userId}
                className="relative rounded-md px-4 bg-gray-50 text-base italic"
              >
                <p className="text-gray-600 flex-grow">{feedback}</p>
                <p className="text-sm capitalize text-primary">
                  {
                    (
                      participants[userId]?.name || t('users:UnknownUser')
                    ).split(' ')[0]
                  }
                </p>
              </blockquote>
            ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default BestFeedbacks;
