import React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { Nullable } from 'types/typesUtils';
import {
  Session,
  SessionTemplate,
  ActivitiesMeta,
  ActivityData,
  ActionRecord,
} from 'types/types';
import { useParticipants } from 'model/sessions';

import { useTopics, usePostsMeta, useSessionPosts } from 'model/productions';
import KeyNumbers from './KeyNumbers';
import DebriefMindmap from './DebriefMindMap';
import EmergingQuestion from './EmergingQuestion';
import SessionObjectives from './SessionObjectives';
import SessionValuable from './SessionValuable';
import ParticipantAnalysis from './ParticipantAnalysis';
import BestFeedbacks from './BestFeedbacks';
import BestPosts from './BestPosts';
import { useSessionVotes } from 'model/votes';
import SessionInfo from './SessionInfo';
import BestPostsByTopics from './BestPostsByTopics';
import Focus from './Focus';
import Spinner from 'components/Spinner';
import AllDocumentActivities from './AllDocumentActivities';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { getFormattedDay } from 'services/datetimes';
import moment from 'moment';

export type DebriefDataProps = {
  sessionId: string;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
  marginClasses: string;
};

const DebriefData = ({
  sessionId,
  allowAutomatedSynthesis,
  marginClasses,
}: DebriefDataProps) => {
  const { t } = useTranslation();

  useFirebaseConnect([
    `sessionsNext/${sessionId}`,
    `sessionsNextTemplates/${sessionId}`,
    `sessionsNextData/${sessionId}/activities/`,
    `sessionsNextData/${sessionId}/activitiesMeta`,
    `sessionsNextData/${sessionId}/debrief/synthesisUrl`,
    `sessionsNextData/${sessionId}/debrief/summaries`,
  ]);

  const session: Nullable<Session> = useSelector(
    (state: any) => state.firebase.data.sessionsNext?.[sessionId]
  );

  const sessionTemplate: Nullable<SessionTemplate> = useSelector(
    (state: any) => state.firebase.data.sessionsNextTemplates?.[sessionId]
  );

  const activitiesData: Nullable<Record<string, ActivityData>> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.activities
  );

  const criteria: Nullable<Record<string, Record<string, string>>> =
    useSelector(
      (state: any) =>
        state.firebase.data.sessionsNextData?.[sessionId]?.activities?.post
          ?.productions || null
    );

  const activitiesMeta: Nullable<ActivitiesMeta> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.activitiesMeta
  );

  const [participants, participantsLoaded] = useParticipants(sessionId);

  const feedbacks = activitiesData?.feedback?.productions;

  const [topics, isTopicsLoaded] = useTopics(sessionId);

  const [postsMeta] = usePostsMeta(sessionId, 'post');

  const posts = useSessionPosts(sessionId);

  const postTitles = activitiesData?.['augment_post']?.productions?.title;

  const votes = useSessionVotes(sessionId);

  const summaries: Nullable<Record<string, any>> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.debrief?.summaries
  );

  const rephrasedPosts: Nullable<Record<string, string>> = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.debrief?.summaries
        ?.post
  );

  const sessionInfoToCopy = `${t('sessions:SessionInfo')}
  
${session?.title}
  
${session ? getFormattedDay(moment(new Date(session.scheduledAt[0]))) : ''}


${t('sessions:emergenceQuestion')}

${
  sessionTemplate?.activities?.post?.screens?.create?.content.template
    .editableContent.question
}


${t('sessions:goalsTitle')}

${session?.goals?.map((goal) => `* ${goal}`)?.join('\n')}


${t('sessions:UsefullSession')}

${
  feedbacks?.valuable
    ? Math.round(
        _.mean(
          Object.values(feedbacks?.valuable || {}).filter((val) =>
            _.isNumber(val)
          )
        ) * 10
      ) / 10
    : 'NA'
}/5
  `;

  return participantsLoaded &&
    isLoaded(
      session,
      sessionTemplate,
      activitiesData,
      criteria,
      activitiesMeta,
      summaries
    ) ? (
    <>
      <SessionInfo
        copyText={sessionInfoToCopy}
        title={session!.title}
        day={new Date(session!.scheduledAt[0])}
        marginClasses={marginClasses}
      />
      <EmergingQuestion
        question={
          sessionTemplate?.activities?.post?.screens?.create?.content.template
            .editableContent.question
        }
        marginClasses={marginClasses}
      />
      <KeyNumbers
        sessionTemplate={sessionTemplate}
        activitiesData={activitiesData}
        marginClasses={marginClasses}
      />
      {/* <ParticipantsList
        participants={participants}
        marginClasses={marginClasses}
      /> */}
      <SessionObjectives goals={session?.goals} marginClasses={marginClasses} />
      <SessionValuable
        valuable={feedbacks?.valuable}
        marginClasses={marginClasses}
      />
      <ParticipantAnalysis
        postProductions={sessionTemplate?.activities?.post?.productions}
        criteriaData={criteria}
        marginClasses={marginClasses}
      />
      <DebriefMindmap
        isTopicsLoaded={isTopicsLoaded}
        topics={topics}
        question={
          sessionTemplate?.activities?.post?.screens?.create?.content.template
            .editableContent.question
        }
        participants={participants}
        posts={posts}
        postsMeta={postsMeta}
        postTitles={postTitles}
      />
      {allowAutomatedSynthesis ? (
        <AllDocumentActivities
          sessionId={sessionId}
          session={session!}
          summaries={summaries}
          sessionTemplate={sessionTemplate!}
          marginClasses={marginClasses}
        />
      ) : null}
      {_.size(topics) > 0 ? (
        <BestPostsByTopics
          sessionId={sessionId}
          allowAutomatedSynthesis={allowAutomatedSynthesis}
          postsSummaries={rephrasedPosts}
          posts={posts}
          postsMeta={postsMeta}
          postTitles={postTitles}
          votes={votes}
          participants={participants}
          topics={topics}
          groupOfUser={activitiesData?.share?.grouping?.groupOfUser}
          marginClasses={marginClasses}
        />
      ) : (
        <BestPosts
          sessionId={sessionId}
          allowAutomatedSynthesis={allowAutomatedSynthesis}
          postsSummaries={rephrasedPosts}
          posts={posts}
          votes={votes}
          postTitles={postTitles}
          participants={participants}
          groupOfUser={activitiesData?.share?.grouping?.groupOfUser}
          marginClasses={marginClasses}
        />
      )}
      <Focus
        sessionId={sessionId}
        session={session!}
        allowAutomatedSynthesis={allowAutomatedSynthesis}
        posts={posts}
        postTitles={postTitles}
        votes={votes}
        participants={participants}
        groupOfUser={activitiesData?.share?.grouping?.groupOfUser}
        shareDocs={activitiesData?.share?.productions?.experience}
        focusSummaries={summaries?._focus}
        marginClasses={marginClasses}
      />
      <BestFeedbacks
        feedbacks={feedbacks}
        participants={participants}
        marginClasses={marginClasses}
      />
    </>
  ) : (
    <Spinner className="mx-auto h-12 w-12 text-primary" />
  );
};

export default DebriefData;
