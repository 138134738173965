import { database as db } from 'services/firebase';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadableString, Session } from 'types/types';
import { Quote } from 'assets/icons';
import {
  getDoc,
  removeEmptyText,
  removeInstructions,
  textualizeDoc,
} from 'model/docManagement';
import { computeFocus } from 'services/tln';
import { Nullable } from 'types/typesUtils';

export type FocusBlocProps = {
  sessionId: string;
  session: Session;
  userId: string;
  userName: string;
  postTitle: Nullable<string>;
  docId: string;
  summary: LoadableString;
};

const FocusBloc = ({
  sessionId,
  session,
  userId,
  userName,
  postTitle,
  docId,
  summary,
}: FocusBlocProps) => {
  const { t } = useTranslation();

  const setFocus = useCallback(
    async (state: LoadableString) => {
      await db
        .ref(`sessionsNextData/${sessionId}/debrief/summaries/_focus/${userId}`)
        .set(state);
    },
    [sessionId, userId]
  );

  const computeFocusCb = useCallback(async () => {
    try {
      setFocus('loading');

      const doc = await getDoc(docId);

      if (doc?.content) {
        const text = textualizeDoc(
          removeEmptyText(removeInstructions(doc.content))
        );

        const focus = await computeFocus(session.language, userName, text);

        await setFocus(focus);
      } else {
        throw new Error('doc is not present');
      }
    } catch (e) {
      console.error(e);
      setFocus('error');
    }
  }, [session.language, setFocus, docId, userName]);

  useEffect(() => {
    if (!summary) {
      computeFocusCb();
    }
  }, [summary, computeFocusCb]);

  return (
    <blockquote className="relative rounded-md bg-gray-50 px-8 py-4 text-lg italic space-y-4">
      <Quote className="absolute left-0 -top-1 h-10 w-10 text-primary" />
      {postTitle ? (
        <p className="text-gray-600 font-semibold">{postTitle}</p>
      ) : null}
      <p className="text-gray-600">
        {summary === 'loading'
          ? t('sessions:BeeingGenerated')
          : summary === 'error'
          ? t('sessions:SummaryError')
          : summary === null
          ? t('sessions:NoSummaryYet')
          : summary}
      </p>
    </blockquote>
  );
};

export default FocusBloc;
