import { database, fetchData, NOW } from 'services/firebase';

import {
  getDatabaseValueAtPath,
  generateRandomString,
  Alternatives,
  replaceText,
} from 'utils/utils';
import _ from 'lodash';

import {
  Activity,
  Community,
  Productions,
  Session,
  Sessions,
  SessionTemplate,
  SessionTemplateDescription,
  SessionStatusState,
  Users,
  Activities,
  MainGoalType,
  SessionLanguage,
  DurationValues,
  GroupingData,
} from 'types/types';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { addUserToCommunity } from './communitiesManagement';
import { useUsers } from './users';

import {
  DEFAULT_SESSION_TYPE_VALUE,
  THEME_MATCH_STRING_LIST,
  PEER_PROFILE_MATCH_STRING_LIST,
  POST_RESULT_DESCRIPTION_MATCH_STRING_LIST,
  MIN_BY_PERSON_STRING_LIST,
  DEFAULT_TEMPLATE_ID,
  SESSION_TEST_SUFFIX,
  COMMUNITY_ID_MATCH_STRING_LIST,
} from 'constants/AppConfig';
import { arrayToTrueSet } from 'utils/pureUtils';

import { postMessage } from 'services/slack';
import { domainUrl } from 'config';

import { deleteSession as pureDeleteSession } from './pure/sessions';
import { sanitizeProductions } from './productions';

export const getSessionTitle = (sessionId: string) =>
  getDatabaseValueAtPath(`sessionsNext/${sessionId}/title`);

export const getSessionIdFromAccessCode = async (
  accessCode: string
): Promise<string> => {
  const session: Record<string, Session> = (
    await database
      .ref('sessionsNext')
      .orderByChild('accessCode')
      .equalTo(accessCode)
      .limitToFirst(1)
      .once('value')
  ).val();

  return Object.keys(session || {})[0];
};

export const updateSession = async (
  sessionId: string,
  updatedSession: Partial<Session>
): Promise<void> => {
  await database.ref(`sessionsNext/${sessionId}/`).update(updatedSession);
};

export const updateMainQuestionExamples = async (
  sessionId: string,
  mainQuestionExamples: string
): Promise<void> => {
  await database
    .ref(
      `sessionsNextTemplates/${sessionId}/activities/post/screens/create/content/template/editableContent/description`
    )
    .set(mainQuestionExamples);
};

export const subscribeUserToSession = async (
  userId: string,
  sessionId: string,
  subscribeToCommunity: boolean,
  communityId?: string
) => {
  let isInSession = (
    await database.ref(`/sessionsOfUsers/${userId}/${sessionId}`).get()
  ).val();

  if (!isInSession) {
    await Promise.all([
      database.ref().update({
        [`/sessionsOfUsers/${userId}/${sessionId}`]: true,
      }),
      subscribeToCommunity && communityId
        ? addUserToCommunity(userId, communityId)
        : Promise.resolve(),
    ]);
  }
};

export const removeParticipantFromSession = async (
  sessionId: string,
  userId: string
) => {
  const productions: Productions = await fetchData<Productions>(
    `sessionsNextData/${sessionId}/activities/post/productions`,
    {}
  );

  // FIXME: handle genericity if needed in the future
  await Promise.all([
    database.ref(`sessionsOfUsers/${userId}/${sessionId}`).remove(),
    database
      .ref(`sessionsNextData/${sessionId}/users/participants/${userId}`)
      .remove(),
    ...Object.keys(productions).map((prodName) =>
      database
        .ref(
          `sessionsNextData/${sessionId}/activities/post/productions/${prodName}/${userId}`
        )
        .remove()
    ),
    database
      .ref(
        `sessionsNextData/${sessionId}/activities/choose/productions/vote/${userId}`
      )
      .remove(),
  ]);
};

export const userIsParticipant = async (userId: string, sessionId: string) => {
  let isParticipant = (
    await database
      .ref(`sessionsNextData/${sessionId}/users/participants/${userId}`)
      .get()
  ).val();
  if (!isParticipant) {
    await database.ref().update({
      [`sessionsNextData/${sessionId}/users/participants/${userId}`]: true,
    });
  }
};

export const addFacilitatorToSession = async (
  userId: string,
  sessionId: string
) => {
  await database
    .ref(`/sessionsNextData/${sessionId}/users/facilitators/${userId}`)
    .set(true);
  await database.ref(`/facilitations/${userId}/${sessionId}`).set(true);
  await subscribeUserToSession(userId, sessionId, false);
};

export const removeFacilitatorFromSession = async (
  userId: string,
  sessionId: string
) => {
  await database
    .ref(`/sessionsNextData/${sessionId}/users/facilitators/${userId}`)
    .remove();
  await database.ref(`/facilitations/${userId}/${sessionId}`).remove();
};

export const addOrganizerToSession = async (
  userId: string,
  sessionId: string
) => {
  await database
    .ref(`/sessionsNextData/${sessionId}/users/organizers/${userId}`)
    .set(true);
  await database.ref(`/organizations/${userId}/${sessionId}`).set(true);
  await subscribeUserToSession(userId, sessionId, false);
};

export const removeOrganizerFromSession = async (
  userId: string,
  sessionId: string
) => {
  await database
    .ref(`/sessionsNextData/${sessionId}/users/organizers/${userId}`)
    .remove();
  await database.ref(`/organizations/${userId}/${sessionId}`).remove();
};

const CUSTOM_ID_PREFIX = '!';

export const duplicateSession = async (
  sessionIdToDuplicate: string,
  newTitle: string,
  ownerId: string,
  isTemplate: boolean = false,
  additionalData: Partial<Session> = {},
  organizers: Record<string, boolean> = {},
  hidden: boolean = false,
  expertMode: boolean = false,
  customAccesCode?: string,
  customSessionId?: string,
  copyData?: boolean
): Promise<[string, string]> => {
  const newAccessCode = customAccesCode || generateRandomString(16, true);
  const newSessionId =
    customSessionId ||
    (customAccesCode
      ? `${CUSTOM_ID_PREFIX}${customAccesCode.toLocaleLowerCase()}`
      : database.ref('sessionsNext').push().key!);

  const sessionToDuplicate: Session = (
    await database.ref(`sessionsNext/${sessionIdToDuplicate}`).once('value')
  ).val();
  const templateToDuplicate: SessionTemplate = (
    await database
      .ref(`sessionsNextTemplates/${sessionIdToDuplicate}`)
      .once('value')
  ).val();

  const dataToDuplicate = copyData
    ? (
        await database
          .ref(`sessionsNextData/${sessionIdToDuplicate}`)
          .once('value')
      ).val()
    : null;

  const expertModeToDuplicate: boolean = await (
    await database
      .ref(`sessionsNextData/${sessionIdToDuplicate}/enableExpertMode`)
      .once('value')
  ).val();

  const ownerName = await fetchData<string>(`users/${ownerId}/name`);

  const newSessionExpertMode =
    expertMode ||
    !!(expertModeToDuplicate !== false && !sessionToDuplicate.isTemplate);

  // TODO: handle community

  const h2 = 2 * 3600000;
  const now = Date.now();

  const mainGoal =
    additionalData.mainGoal || sessionToDuplicate.mainGoal || 'action';

  const newSession: Session = {
    ..._.omit(sessionToDuplicate, [
      'theme',
      'postResultDescription',
      'peerProfile',
    ]),
    title: newTitle,
    type: DEFAULT_SESSION_TYPE_VALUE,
    createdAt: now,
    accessCode: newAccessCode,
    previewSessionId: sessionIdToDuplicate,
    scheduledAt: [now + h2, now + 2 * h2],
    ...additionalData,
    isTemplate,
    fromId: sessionIdToDuplicate,
    usesMailAutomation: false,
    hidden,
    internalVisio:
      additionalData.internalVisio || sessionToDuplicate.internalVisio || false,
    mainGoal: mainGoal,
  };

  await database
    .ref(`sessionsNext/${newSessionId}`)
    .set(_.mapValues(newSession, (val) => (val === undefined ? null : val)));

  const newOrganizers = _.uniq([ownerId, ...Object.keys(organizers)]);

  await Promise.all(
    newOrganizers.map((userId) => {
      return addOrganizerToSession(userId, newSessionId);
    })
  );

  const newSessionTemplate: SessionTemplate = {
    ...templateToDuplicate,
    activities: await sanitizeProductions(templateToDuplicate.activities),
  };

  await database
    .ref(`sessionsNextTemplates/${newSessionId}`)
    .set(newSessionTemplate);

  if (dataToDuplicate) {
    dataToDuplicate['activities'] = _.pick(
      dataToDuplicate['activities'] || {},
      ['post', 'choose']
    );
    await database.ref(`sessionsNextData/${newSessionId}`).set(dataToDuplicate);
  } else {
    const sessionState: SessionStatusState = 'kickoff';
    await database
      .ref(`sessionsNextData/${newSessionId}/state`)
      .set(sessionState);

    await database
      .ref(`sessionsNextData/${newSessionId}/enableFollowUp`)
      .set(true);

    await database
      .ref(`sessionsNextData/${newSessionId}/enableExpertMode`)
      .set(newSessionExpertMode);
  }

  if (!newSessionExpertMode) {
    generateActivities(
      newSessionId,
      mainGoal,
      null,
      additionalData.language || 'fr'
    );
  }

  postMessage(
    `🎊 Une nouvelle session a été crée ${
      ownerName ? `par ${ownerName}` : ''
    } => ${domainUrl}/${newAccessCode}`
  );

  return [newAccessCode, newSessionId];
};

export const deleteSession = pureDeleteSession(database);

export const updateKindsOfPublic = (
  sessionId: string,
  kinds: Record<string, true>
) => {
  return database.ref(`sessionsNext/${sessionId}/kindsOfPublic`).set(kinds);
};

export const useFullSessionFromAccessCode = (
  accessCode: string
): [
  string | undefined,
  Session | undefined,
  Community | null,
  SessionTemplateDescription | null,
  boolean
] => {
  useFirebaseConnect({
    path: 'sessionsNext',
    queryParams: [
      'orderByChild=accessCode',
      `equalTo=${accessCode}`,
      'limitToFirst=1',
    ],
  });

  const sessions: Sessions | null = useSelector(
    (state: any) => state.firebase.data.sessionsNext
  );

  const [sessionId, session]: [string | undefined, Session | undefined] =
    Object.entries(sessions || {}).find(
      ([, session]) => session?.accessCode === accessCode
    ) || [undefined, undefined];

  const refs = [
    ...(session?.communityId ? [`communities/${session.communityId}`] : []),
    ...(session?.fromId
      ? [`sessionTemplateDescriptions/${session.fromId}`]
      : []),
  ];

  useFirebaseConnect(refs);

  const community: Community | null = useSelector(
    (state: any) => state.firebase.data.communities?.[session?.communityId!]
  );

  const description: SessionTemplateDescription | null = useSelector(
    (state: any) =>
      state.firebase.data.sessionTemplateDescriptions?.[session?.fromId!]
  );

  return [sessionId, session, community, description, isLoaded(sessions)];
};

export const setSessionState = async (
  sessionId: string,
  state: SessionStatusState
): Promise<void> => {
  const db = await database.ref(`sessionsNextData/${sessionId}`);
  if (state === 'not_started') {
    await db.update({
      enabledActivities: {},
      activitiesMeta: {
        enabledAt: null,
        remainingTimestamp: null,
        remainingTimeMs: null,
        isPaused: null,
      },
      state: state,
    });
  } else {
    await db.update({ state: state });
  }
};

export const setSessionFollowUp = async (
  sessionId: string,
  enableFollowUp: boolean
): Promise<void> => {
  await database
    .ref(`sessionsNextData/${sessionId}/enableFollowUp`)
    .set(enableFollowUp);

  if (!enableFollowUp) {
    setSessionState(sessionId, 'not_started');
  }
};

export const setSessionExpertMode = async (
  sessionId: string,
  enableExpertMode: boolean
): Promise<void> => {
  await database
    .ref(`sessionsNextData/${sessionId}/enableExpertMode`)
    .set(enableExpertMode);
};

export const setSessionInAssistance = async (
  sessionId: string,
  inAssistance: boolean
): Promise<void> => {
  await database
    .ref(`sessionsNextData/${sessionId}/inAssistance`)
    .set(inAssistance);
};

export const useParticipants = (sessionId: string): [Users, boolean] => {
  useFirebaseConnect(`sessionsNextData/${sessionId}/users/participants`);

  const participantsSet = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNextData?.[sessionId]?.users?.participants
  );

  const [users, loaded] = useUsers(participantsSet || {});

  return [users, isLoaded(participantsSet) && loaded];
};

export const setUserActivityDraft = async (
  sessionId: string,
  activityName: string,
  productionName: string,
  userId: string,
  post: string
): Promise<void> => {
  return database
    .ref(
      `/sessionsNextData/${sessionId}/activities/${activityName}/drafts/${productionName}/${userId}`
    )
    .set(post);
};

export const getUserActivityDraft = async (
  sessionId: string,
  activityName: string,
  productionName: string,
  userId: string
): Promise<string | null> => {
  return fetchData(
    `/sessionsNextData/${sessionId}/activities/${activityName}/drafts/${productionName}/${userId}`
  );
};

export const useEstimatedParticipantsCount = (sessionId: string): number => {
  useFirebaseConnect(`sessionsNext/${sessionId}/estimatedParticipantCount`);

  const estimatedParticipantCount = useSelector(
    (state: any) =>
      state.firebase.data.sessionsNext?.[sessionId]?.estimatedParticipantCount
  );
  return estimatedParticipantCount || 40;
};

export const getParticipants = async (sessionId: string) => {
  return fetchData<Record<string, true>>(
    `sessionsNextData/${sessionId}/users/participants`
  );
};

export const isActivityEnabled = async (
  sessionId: string,
  activityName: string
) => {
  const enabled = await fetchData<boolean>(
    `sessionsNextData/${sessionId}/enabledActivities/${activityName}`
  );
  return !!enabled;
};

export const useInstruction = (
  sessionId: string,
  activity: Activity
): string => {
  const screenName = Object.values(activity.screens || {})[0]?.name || '';
  const instructionRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/${screenName}/content/template/editableContent/instructions`;

  useFirebaseConnect([instructionRef]);

  const instructions: string = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNextTemplates?.[sessionId]?.activities?.[
        activity.name
      ]?.screens?.[screenName]?.content.template.editableContent.instructions
  );

  return instructions;
};

export const useScreenContent = (
  sessionId: string,
  activity: Activity
): Record<string, string> => {
  const screenName = Object.values(activity.screens || {})[0]?.name || '';
  const instructionRef = `/sessionsNextTemplates/${sessionId}/activities/${activity.name}/screens/${screenName}/content/template/editableContent`;

  useFirebaseConnect([instructionRef]);

  const editableContent: Record<string, string> = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNextTemplates?.[sessionId]?.activities?.[
        activity.name
      ]?.screens?.[screenName]?.content.template.editableContent
  );

  return editableContent;
};

const buildTextReplaceFunction = (
  theme: string | null,
  peerProfile: string | null,
  postResultDescription: string | null,
  minutesByPerson: number | null,
  communityId: string | null
): ((text: string) => string) => {
  const alternatives: Alternatives<string> = [];
  if (theme) {
    alternatives.push({
      matches: THEME_MATCH_STRING_LIST,
      item: theme,
    });
  }

  if (peerProfile) {
    alternatives.push({
      matches: PEER_PROFILE_MATCH_STRING_LIST,
      item: peerProfile,
    });
  }

  if (postResultDescription) {
    alternatives.push({
      matches: POST_RESULT_DESCRIPTION_MATCH_STRING_LIST,
      item: postResultDescription,
    });
  }

  if (minutesByPerson) {
    alternatives.push({
      matches: MIN_BY_PERSON_STRING_LIST,
      item: `${minutesByPerson}`,
      render: (value, context) => {
        return context.matched?.replace('X', value).slice(1, -1) || value;
      },
    });
  }

  if (communityId) {
    alternatives.push({
      matches: COMMUNITY_ID_MATCH_STRING_LIST,
      item: communityId,
    });
  }

  return (text: string) => {
    return replaceText<string>(
      text,
      alternatives,
      (value, context, alternativeRender) => {
        return alternativeRender
          ? alternativeRender(value, context)
          : value || context.matched || '';
      },
      (values) => values.join(''),
      (value) => value
    );
  };
};

export const useTextReplaceSession = (
  sessionId: string,
  activityName: string,
  userId: string
): ((text: string) => string) => {
  useFirebaseConnect([
    { path: `/sessionsNext/${sessionId}/peerProfile` },
    { path: `/sessionsNext/${sessionId}/theme` },
    { path: `/sessionsNext/${sessionId}/postResultDescription` },
    { path: `/sessionsNext/${sessionId}/postResultDescription` },
    {
      path: `/sessionsNextTemplates/${sessionId}/activities/${activityName}/duration`,
    },
    {
      path: `/sessionsNextData/${sessionId}/activities/${activityName}/grouping`,
      storeAs: `/sessionsNextData/${sessionId}/activities/${activityName}/fullGrouping`,
    },
    { path: `/sessionsNext/${sessionId}/communityId` },
  ]);

  const theme: string | null = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNext?.[sessionId]?.theme || null
  );

  const peerProfile: string | null = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNext?.[sessionId]?.peerProfile || null
  );

  const postResultDescription: string | null = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNext?.[sessionId]?.postResultDescription ||
      null
  );

  const activityDuration: number | null = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNextTemplates?.[sessionId]?.activities?.[
        activityName
      ]?.duration || null
  );

  const grouping: GroupingData = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNextData?.[sessionId]?.activities?.[
        activityName
      ]?.fullGrouping || null
  );

  const communityId: string | null = useSelector(
    (state: any) =>
      state.firebase.data?.sessionsNext?.[sessionId]?.communityId || null
  );

  const groupId: string | null = grouping?.groupOfUser?.[userId] || null;
  let nbParticipantsByGroup: number | null = null;
  if (groupId) {
    nbParticipantsByGroup = _.size(grouping?.groups?.[groupId]?.users || {});
  }
  nbParticipantsByGroup = nbParticipantsByGroup || 5;

  return buildTextReplaceFunction(
    theme,
    peerProfile,
    postResultDescription,
    activityDuration
      ? Math.floor(activityDuration / nbParticipantsByGroup)
      : null,
    communityId
  );
};

export const getTextReplaceSession = async (
  sessionId: string
): Promise<(text: string) => string> => {
  const theme = await fetchData<string>(`/sessionsNext/${sessionId}/theme`);
  const peerProfile = await fetchData<string>(
    `/sessionsNext/${sessionId}/peerProfile`
  );
  const postResultDescription = await fetchData<string>(
    `/sessionsNext/${sessionId}/postResultDescription`
  );
  const communityId = await fetchData<string>(
    `/sessionsNext/${sessionId}/communityId`
  );

  return buildTextReplaceFunction(
    theme,
    peerProfile,
    postResultDescription,
    null,
    communityId
  );
};

export const generateActivities = async (
  sessionId: string,
  mainGoal: MainGoalType | null,
  duration: DurationValues | null,
  language: SessionLanguage
) => {
  const baseSessionId =
    language === 'fr' ? '!admin_activities_fr' : '!admin_activities_en';
  const baseActivities: Activities | null = await fetchData(
    `/sessionsNextTemplates/${baseSessionId}/activities`
  );

  if (baseActivities) {
    let newActivities: Activity[] = [
      baseActivities['post'],
      baseActivities['choose'],
    ];

    const shareActivity = baseActivities['share'];
    const collaborateActivity = baseActivities['collaborate'];
    const actionActivity = baseActivities['action'];
    const feedbackActivity = baseActivities['feedback'];
    const contentActivity = baseActivities['content'];

    if (mainGoal === 'reveal_knowledge') {
      if (duration === 60) {
        shareActivity.duration = 45;
        newActivities.push(shareActivity);
        newActivities.push(contentActivity);
        newActivities.push(feedbackActivity);
      } else if (duration === 90) {
        shareActivity.duration = 45;
        collaborateActivity.duration = 25;
        newActivities.push(shareActivity);
        newActivities.push(collaborateActivity);
        newActivities.push(contentActivity);
        newActivities.push(feedbackActivity);
      } /*(duration === 120)*/ else {
        shareActivity.duration = 60;
        collaborateActivity.duration = 30;
        feedbackActivity.duration = 5;
        newActivities.push(shareActivity);
        newActivities.push(collaborateActivity);
        newActivities.push(contentActivity);
        newActivities.push(feedbackActivity);
      }
    } else if (mainGoal === 'action' || mainGoal === 'other') {
      if (duration === 60) {
        shareActivity.duration = 30;
        actionActivity.duration = 15;
        newActivities.push(shareActivity);
        newActivities.push(actionActivity);
        newActivities.push(contentActivity);
        newActivities.push(feedbackActivity);
      } else if (duration === 90) {
        shareActivity.duration = 40;
        collaborateActivity.duration = 20;
        actionActivity.duration = 15;
        newActivities.push(shareActivity);
        newActivities.push(collaborateActivity);
        newActivities.push(actionActivity);
        newActivities.push(contentActivity);
        newActivities.push(feedbackActivity);
      } /*(duration === 120)*/ else {
        shareActivity.duration = 50;
        collaborateActivity.duration = 30;
        actionActivity.duration = 25;
        newActivities.push(shareActivity);
        newActivities.push(collaborateActivity);
        newActivities.push(actionActivity);
        newActivities.push(contentActivity);
        newActivities.push(feedbackActivity);
      }
    } else if (mainGoal === 'share_internal_knowledge') {
      /* Intentionnaly not defined => waiting for "mini-cours" activities" */
      throw Error(
        'The main goal "share_internal_knowledge" is not yet supported'
      );
    }

    const shollowCopyActivites: Activities = newActivities.reduce<Activities>(
      (prev, activity, index) => {
        activity.index = index + 1;
        prev[activity.name] = activity;
        return prev;
      },
      {}
    );

    const deepCopyActivites = await sanitizeProductions(shollowCopyActivites);

    await database
      .ref(`sessionsNextTemplates/${sessionId}/activities`)
      .set(deepCopyActivites);
  }
};

export const createSessionLite = async (
  userId: string,
  title: string,
  theme: string,
  peerProfile: string,
  callback?: (accessCode: string) => void
) => {
  const [accessCode] = await duplicateSession(
    DEFAULT_TEMPLATE_ID,
    title,
    userId,
    false,
    {
      theme: theme,
      peerProfile: peerProfile,
    },
    arrayToTrueSet([userId]),
    false,
    false
  );

  callback && callback(accessCode);
};

export const createTestSession = async (
  sessionId: string,
  accessCode: string,
  title: string,
  userId: string
): Promise<string> => {
  const [newAccessCode] = await duplicateSession(
    sessionId,
    `[TEST] : ${title}`,
    userId,
    false,
    { communityId: undefined },
    {},
    true,
    undefined,
    `${accessCode}${SESSION_TEST_SUFFIX}`,
    `${sessionId}${SESSION_TEST_SUFFIX}`,
    true
  );
  await database
    .ref(`tests/${sessionId}${SESSION_TEST_SUFFIX}`)
    .set(Date.now());
  return newAccessCode;
};

export const getGroupingSourceActivity = (
  activities: Activities,
  activityName: string | undefined
): string | undefined => {
  if (!activityName) {
    return activityName;
  }
  const activity = activities[activityName];
  if (!activity) {
    return activity;
  }
  if (activity.grouping.mode === 'All') {
    return undefined;
  }
  if (activity.grouping.settings.mode === 'same_as') {
    return getGroupingSourceActivity(
      activities,
      activity.grouping.settings.activity
    );
  }
  return activityName;
};

export const recordAllowSynthesis = async (
  sessionId: string,
  userId: string
) => {
  await database
    .ref(`sessionsNextData/${sessionId}/debrief/allowAutomatedSynthesis`)
    .set({
      userId,
      timestamp: NOW,
    });
};
