import { QueueList } from 'assets/icons';
import { LinkWithIcon } from 'components/Link';
import Modal from 'components/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GroupListView, { GroupListViewProps } from './GroupListView';

export type GroupListModalProps = {} & GroupListViewProps;

const GroupListModal = ({
  ...listViewProps
}: GroupListModalProps): JSX.Element => {
  const [displayModal, setDisplayModal] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Modal
        largeWidth
        fullHeight
        open={displayModal}
        onClose={() => setDisplayModal(false)}
        body={<GroupListView {...listViewProps} />}
      />
      <LinkWithIcon
        text={t('facilitator:ViewListView')}
        onClick={() => setDisplayModal(true)}
      >
        <QueueList className="h-6 w-6 shrink-0 stroke-3/2" />
      </LinkWithIcon>
    </>
  );
};

export default GroupListModal;
