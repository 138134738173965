import _ from 'lodash';
import { TrueSet } from '../types/baseTypes';

export const diff = <T>(
  oldArr: T[],
  newArr: T[]
): {
  adds: T[];
  dels: T[];
} => {
  const dels = _.difference(oldArr, newArr);
  const adds = _.difference(newArr, oldArr);
  return { adds, dels };
};

export const mapValuesAsync = async <V, C>(
  record: Record<string, V>,
  mapper: (val: V, key: string) => Promise<C>
): Promise<Record<string, C>> => {
  const entries = await Promise.all(
    Object.entries(record).map(async ([key, val]: [string, V]) => {
      const realVal = await mapper(val, key);
      return [key, realVal];
    })
  );
  return Object.fromEntries(entries);
};

export const mapFilterValuesAsync = async <V, C>(
  record: Record<string, V>,
  mapper: (val: V, key: string) => Promise<C | null>
): Promise<Record<string, C>> => {
  const entries = await Promise.all(
    Object.entries(record).map(async ([key, val]: [string, V]) => {
      const realVal = await mapper(val, key);
      return [key, realVal];
    })
  );

  const filtered = entries.filter(([, val]) => val !== null);

  return Object.fromEntries(filtered);
};

export const sequencialReduce = <V, A>(
  arr: V[],
  f: (prec: A, val: V, index: number) => Promise<A>,
  initial: A
): Promise<A> => {
  return arr.reduce(async (prec, val, index) => {
    const acc = await prec;
    return f(acc, val, index);
  }, Promise.resolve(initial));
};

export const sequencialForEach = <V>(
  arr: V[],
  f: (val: V, index: number) => Promise<void>
): Promise<void> => {
  return sequencialReduce(
    arr,
    (_ignored, val, index) => f(val, index),
    (() => {})()
  );
};

export const sequencialMap = <V, T>(
  arr: V[],
  f: (val: V, index: number) => Promise<T>
): Promise<T[]> =>
  sequencialReduce(
    arr,
    async (prec: T[], val, index) => {
      prec.push(await f(val, index));
      return prec;
    },
    []
  );

export const revertMaps = (
  input: Record<string, Record<string, string>>
): Record<string, Record<string, string>> => {
  const res: Record<string, Record<string, string>> = {};

  Object.entries(input).forEach(([key1, rec]) => {
    Object.entries(rec || {}).forEach(([key2, val]) => {
      const rec = res[key2];
      if (!rec) {
        res[key2] = { [key1]: val };
      } else {
        rec[key1] = val;
      }
    });
  });

  return res;
};

// type needs interface indirection to be recusive in some cases
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#more-recursive-type-aliases
// typing of this and following gfunction is inaccurate
export type DocTree = Record<string, true> | DeepDocTree;
interface DeepDocTree extends Record<string, DocTree> {}

export const flattenDocs = (docs: DocTree): Record<string, true> =>
  Object.entries(docs || {}).reduce(
    (prec: Record<string, true>, [key, val]) => {
      if (val === true) {
        prec[key] = true;
      } else {
        if (val && _.isObject(val)) {
          Object.assign(prec, flattenDocs(val as DocTree));
        }
      }
      return prec;
    },
    {}
  );

export const substract = <T, U>(
  a: Record<string, T>,
  b: Record<string, U>
): Record<string, T> => {
  return _.pickBy(a, (_val, key) => b[key] === undefined);
};

export const debugFunc = <T>(t: T, tag?: string): T => {
  console.log(`${tag ? `${tag}: ` : ''}${t}`);
  return t;
};

export const sleep = (durationMs: number) => {
  return new Promise<void>((resolve, _reject) => {
    setTimeout(() => {
      resolve();
    }, durationMs);
  });
};

export const isPromise = (promiseCandidate: any): boolean => {
  return !!promiseCandidate?.then && _.isFunction(promiseCandidate.then);
};

export const hashCode = (s: string): number => {
  let h = 1;
  for (let i = 0; i < s.length; i++)
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;

  return h;
};

export const circularIndexIncrement = <T>(list: T[], index: number) => {
  return index < list.length - 1 ? index + 1 : 0;
};

export const circularIndexDecrement = <T>(list: T[], index: number) => {
  return index > 0 ? index - 1 : list.length - 1;
};

export const arrayToTrueSet = (array: string[]): TrueSet =>
  array.reduce((set: TrueSet, key) => {
    set[key] = true;
    return set;
  }, {});

export const memoize = <T>(f: (id: string) => Promise<T>) => {
  const mem = new Map<string, T>();
  return async (id: string) => {
    if (mem.has(id)) {
      return mem.get(id)!;
    } else {
      const val = await f(id);
      mem.set(id, val);
      return val;
    }
  };
};

export const formatNumber = (num: number, lng: string): string => {
  return new Intl.NumberFormat(lng).format(num);
};

export const bucketFunction = <T extends any[], U>(
  fn: (...args: T) => Promise<U>,
  buckedSize = 10
): ((...args: T) => Promise<U>) => {
  let id = 0;
  const pendingArgs: {
    args: T;
    id: number;
    resolve: (val: U) => void;
    reject: (reason?: any) => void;
  }[] = [];
  const started: Map<number, Promise<void>> = new Map();

  const handleNext = () => {
    while (_.size(started) < buckedSize && pendingArgs.length > 0) {
      const { args, id, resolve, reject } = pendingArgs.shift()!;

      const promise = fn(...args)
        .then((res) => {
          resolve(res);
        })
        .catch((reason) => {
          reject(reason);
        })
        .finally(() => {
          started.delete(id);
          handleNext();
        });

      started.set(id, promise);
    }
  };

  return (...args: T) => {
    const promise = new Promise<U>((resolve, reject) => {
      pendingArgs.push({ args, id: id++, resolve, reject });
      handleNext();
    });
    return promise;
  };
};
