import Disclosure from 'components/Disclosure';
import React from 'react';

import { Typography } from 'antd';
const { Text } = Typography;

export type CardProps = {
  title: string;
  setTitle?: (title: string) => void;
  editable?: boolean;
  collapsable?: boolean;
  open: boolean;
  toggle?: (val: boolean) => void;
  children: React.ReactNode;
};

const Card = ({
  title,
  setTitle,
  editable,
  open,
  toggle,
  children,
  collapsable,
}: CardProps): JSX.Element => {
  const titleElem = editable ? (
    <Text
      className="text-2xl font-semibold uppercase text-black"
      editable={editable ? { onChange: setTitle } : undefined}
    >
      {title}
    </Text>
  ) : (
    <h3 className="text-2xl font-semibold uppercase text-black">{title}</h3>
  );
  return (
    <Disclosure
      defaultOpen={false}
      status={open}
      collapsable={collapsable}
      onStatusChange={toggle}
      title={titleElem}
      collapseOnTitle={!editable}
      type={'notion'}
      stickyPositionClassName={collapsable ? 'top-12' : 'top-0'}
    >
      <>{children}</>
    </Disclosure>
  );
};

export default Card;
