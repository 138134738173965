import { CSSProperties } from 'react';
import firebase from 'firebase/compat/app';

export type Nullable<T> = T | null | undefined;

export function createStyleMap<T extends { [name: string]: CSSProperties }>(
  cfg: T
): Record<keyof T, CSSProperties> {
  return cfg;
}

export const forceRecord = <T>(elem: Record<string, T>) => elem;

export type WithId<T extends object> = T & { id: string };

export type Modify<T, R> = Omit<T, keyof R> & R;

export type AllKeys<T> = T extends any ? keyof T : never;

export type PureDatabase = Pick<firebase.database.Database, 'ref'>;

export type KeyOfType<O, T> = {
  [K in keyof O]: O[K] extends T ? K : never;
}[keyof O];
