import { database as db } from 'services/firebase';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { computePostSummary } from 'services/tln';
import { ActionRecord, LoadableString, User } from 'types/types';
import { Exclamation } from 'assets/icons';
import { Nullable } from 'types/typesUtils';
import Tooltip from 'components/Tooltip';

export type PostBlockProps = {
  sessionId: string;
  userId: string;
  post: string;
  postTitle: Nullable<string>;
  postSummary: LoadableString;
  user: User | undefined;
  allowAutomatedSynthesis: Nullable<ActionRecord>;
};

const PostBlock = ({
  sessionId,
  userId,
  post,
  postSummary,
  postTitle,
  user,
  allowAutomatedSynthesis,
}: PostBlockProps) => {
  const { t } = useTranslation();

  const userName = user?.name || t('users:UnknownUser');

  const setSummary = useCallback(
    async (state: LoadableString) => {
      await db
        .ref(`sessionsNextData/${sessionId}/debrief/summaries/post/${userId}`)
        .set(state);
    },
    [sessionId, userId]
  );

  const computeSummary = useCallback(async () => {
    try {
      setSummary('loading');
      const summary = await computePostSummary(post, userName!);
      await setSummary(summary);
    } catch (e) {
      console.error(e);
      setSummary('error');
    }
  }, [post, userName, setSummary]);

  useEffect(() => {
    if (!postSummary && allowAutomatedSynthesis) {
      computeSummary();
    }
  }, [postSummary, computeSummary, allowAutomatedSynthesis]);

  return (
    <blockquote className="pt- relative rounded-md bg-gray-50 px-4 text-base italic">
      {/* <Quote className="absolute left-0 -top-2 h-6 w-6 text-primary" /> */}
      {postTitle ? (
        <p className="text-gray-600 font-semibold">{postTitle}</p>
      ) : null}

      {postSummary && postSummary !== 'error' ? (
        <div className="space-y-4">
          {/* <Copyable textToCopy={summaryToCopy}> */}
          <p className="text-gray-600">
            {postSummary === 'error'
              ? t('sessions:SummaryError')
              : postSummary === 'loading'
              ? t('sessions:BeeingGenerated')
              : postSummary}
          </p>
          {/* </Copyable> */}

          {/* {!_.includes([null, 'loading'], postSummary) ? (
            <Button
              color="gray"
              text={t('sessions:Regenerate')}
              onClick={() => setSummary(null)}
              size="sm"
            />
          ) : null} */}
        </div>
      ) : (
        <>
          <div className="flex flex-row items-center">
            <p className="text-gray-600 flex-grow">{post}</p>
            {postSummary === 'error' ? (
              <Tooltip content={t('sessions:SummaryError')}>
                <Exclamation className="w-6 h-6 ml-2 stroke-3/2 text-danger" />
              </Tooltip>
            ) : null}
          </div>
          <p className="text-sm capitalize text-primary">{userName}</p>
        </>
      )}
    </blockquote>
  );
};

export default PostBlock;
