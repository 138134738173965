import React from 'react';
import TextArea from 'frameworks/formik/TextArea';
import { Formik, Form } from 'formik';
import { Nullable } from 'types/typesUtils';
import { useTranslation } from 'react-i18next';
import AutoSave from 'frameworks/formik/AutoSave';

export type AugmentedPostProps = {
  post: Nullable<string>;
  setPost: (val: string) => void;
  postTitle: Nullable<string>;
  setPostTitle: (val: string) => void;
  titleLabel: string;
  setTitleLabel?: (val: string) => void;
  postLabel: string;
  setPostLabel?: (val: string) => void;
  editable?: boolean;
};

const AugmentedPost = ({
  post,
  postTitle,
  setPost,
  setPostTitle,
  editable,
  titleLabel = '',
  setTitleLabel,
  postLabel = '',
  setPostLabel,
}: AugmentedPostProps): JSX.Element => {
  const { t } = useTranslation();

  const initialPost = post || '';
  const initialPostTitle = postTitle || '';

  return (
    <Formik
      initialValues={{
        post: initialPost,
        postTitle: initialPostTitle,
      }}
      onSubmit={async (values) => {
        if (values.post !== post) {
          setPost(values.post);
        }
        if (values.postTitle !== postTitle) {
          setPostTitle(values.postTitle);
        }
      }}
      enableReinitialize
    >
      {({ values }) => (
        <Form className="space-y-4 mt-4">
          <AutoSave debounceType="throttle" />
          <TextArea
            name="postTitle"
            label={titleLabel}
            placeholder={t('common:WriteHere')}
            editable={editable}
            setLabel={setTitleLabel}
          />
          <TextArea
            name="post"
            label={postLabel}
            placeholder={t('common:WriteHere')}
            editable={editable}
            setLabel={setPostLabel}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AugmentedPost;
