import React, { useMemo } from 'react';
import { Documents } from 'assets/undraw';
import Disclosure from 'components/Disclosure';
import ContentCardsEditor from 'componentsOld/Content/ContentCardsEditor';
import MDReactComponent from 'componentsOld/MDReactComponent';
import { useTranslation } from 'react-i18next';
import { flattenDocs, templateReplace } from 'utils/utils';
import { useInstruction, useTextReplaceSession } from 'model/sessions';
import { useContext } from 'model/dataHooks';
import { Activity, User } from 'types/types';
import { WithId } from 'types/typesUtils';
import _ from 'lodash';
import { GroupingFacilitationChildrenArguments } from './GroupingFacilitationLayout';

export type DocumentFacilitationViewProps = {
  sessionId: string;
  activity: Activity;
  groupPrefix: string | undefined;
  user: WithId<User>;
} & GroupingFacilitationChildrenArguments;

export const DocumentFacilitationView = ({
  sessionId,
  activity,
  groupPrefix,
  impersonatedUserId,
  user,
  prods,
  participantsAsGroups,
  participantsInGroups,
  selectedUserId,
  selectedGroupId,
}: DocumentFacilitationViewProps): JSX.Element => {
  const { t } = useTranslation();

  const instruction = useInstruction(sessionId, activity);
  const context = useContext(sessionId, impersonatedUserId, activity, {
    groupPrefix: groupPrefix,
  });

  const textReplaceSession = useTextReplaceSession(
    sessionId,
    activity.name,
    impersonatedUserId
  );

  const flattenedDocs = useMemo(
    () =>
      flattenDocs(
        participantsInGroups
          ? prods[selectedGroupId || '']
          : participantsAsGroups
          ? prods[selectedUserId || '']
          : prods
      ),
    [
      prods,
      selectedGroupId,
      participantsInGroups,
      selectedUserId,
      participantsAsGroups,
    ]
  );

  return (
    <>
      <Disclosure
        defaultOpen
        collapsable={false}
        title={
          <div className="flex items-center space-x-2">
            <h2 className="text-2xl font-semibold uppercase text-black">
              {t('editor:goals')}
            </h2>
          </div>
        }
      >
        <MDReactComponent
          text={textReplaceSession(templateReplace(instruction, context))}
          editable={false}
          setText={async () => {}}
        />
      </Disclosure>

      {_.size(flattenedDocs) > 0 ? (
        <ContentCardsEditor
          editable={false}
          docs={flattenedDocs}
          user={user}
          setDocName={() => {}}
          docName=""
        />
      ) : (
        <div className="space-y-4">
          <h2 className="mt-10 px-5 text-center text-xl text-black-soft md:px-10 md:text-2xl xl:px-20 xl:text-3xl">
            {t('facilitator:NoDocumentCreatedYet')}
          </h2>
          <Documents className="mx-auto h-48 fill-current text-primary" />
        </div>
      )}
    </>
  );
};

export default DocumentFacilitationView;
