import SelectorInput from 'components/SelectorInput';
import Spinner from 'components/Spinner';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirebaseConnect } from 'react-redux-firebase';
import { Communities, Session, Sessions, Users } from 'types/types';
import _ from 'lodash';
import SessionsList from 'blocks/SessionsList';
import { WithId } from 'types/typesUtils';
import { useTranslation } from 'react-i18next';
import CommunitiesList from 'blocks/CommunitiesList';

export const AppAdminUserActivityScreen = () => {
  const { t } = useTranslation();

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  useFirebaseConnect([
    {
      path: `/users`,
      storeAs: 'allUsers',
    },
    {
      path: `/sessionsNext`,
      storeAs: 'allSessions',
    },
    {
      path: `/communities`,
      storeAs: 'allCommunities',
    },
    {
      path: `/sessionsOfUsers/${selectedUserId || 'NONEXISTENT'}`,
      storeAs: 'allSessionsOfUser',
    },
    {
      path: `/communitiesOfUsers/${selectedUserId || 'NONEXISTENT'}`,
      storeAs: 'allCommunitiesOfUser',
    },
  ]);

  const users: Users | null = useSelector(
    (state: any) => state.firebase.data.allUsers
  );

  const allSessions: Sessions | null = useSelector(
    (state: any) => state.firebase.data.allSessions
  );

  const allCommunities: Communities | null = useSelector(
    (state: any) => state.firebase.data.allCommunities
  );

  const sessions: Record<string, true> | null = useSelector(
    (state: any) => state.firebase.data.allSessionsOfUser
  );

  const communities: Record<string, true> | null = useSelector(
    (state: any) => state.firebase.data.allCommunitiesOfUser
  );

  const describedUser = _.mapValues(users, (user) => ({
    ...user,
    description: `${user.name} (${user.email})`,
  }));

  const selectedSessionArray: WithId<Session>[] = Object.entries(
    allSessions || {}
  )
    .filter(([sessionId]) => !!sessions?.[sessionId])
    .map(([sessionId, session]) => ({
      id: sessionId,
      ...session,
    }));

  const selectedCommunities = _.pickBy(
    allCommunities,
    (_ignored, communityId) => communities && communities[communityId]
  );

  return (
    <div className="w-full flex-col items-stretch space-y-8">
      {isLoaded(users) ? (
        <>
          <SelectorInput
            options={describedUser}
            descriptionKey="description"
            value={selectedUserId || 'NONEXISTENTID'}
            onChange={(userId) => setSelectedUserId(userId)}
            displayLimit={20}
          />
          {selectedUserId && isLoaded(sessions, allCommunities) ? (
            <div>
              <h2 className="mb-4 text-2xl font-semibold">
                {t('sessions:Sessions')}
              </h2>
              <SessionsList
                userId={selectedUserId}
                sessions={selectedSessionArray}
                communities={allCommunities || {}}
              />
            </div>
          ) : null}
          {selectedUserId && isLoaded(communities, allCommunities) ? (
            <div>
              <h2 className="mb-4 text-2xl font-semibold">
                {t('communities:Communities')}
              </h2>
              <CommunitiesList communities={selectedCommunities} />
            </div>
          ) : null}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
