import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Combobox } from '@headlessui/react';

import { RSet } from 'types/types';

import { Check, Selector as SelectorIncon, XCircle } from 'assets/icons';
import { useLocalSearch } from 'services/localSearching';
import { useDeepEqualMemo } from 'utils/utils';

export type Options<T extends object> = RSet<T>;

export type BaseSelectorProps<T extends object> = {
  compact?: boolean;
  placeholder?: string;
  allowClear?: boolean;
  options: Options<T>;
  descriptionKey: keyof T;
  displayLimit?: number;
};

export type SelectorInputProps<T extends object> = {
  value: string;
  onChange: (value: string) => void;
} & BaseSelectorProps<T>;

const SelectorInput = <T extends object>({
  displayLimit,
  compact = false,
  allowClear = false,
  placeholder,
  options,
  descriptionKey,
  onChange,
  value,
}: SelectorInputProps<T>): JSX.Element => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>('');

  const searchKeys: (keyof T)[] = useMemo(
    () => [descriptionKey],
    [descriptionKey]
  );

  const optionsMemoize = useDeepEqualMemo(options) || {};

  const filteredOptions = useLocalSearch(optionsMemoize, searchKeys, filter);

  const optionsArray = _.sortBy(Object.entries(filteredOptions || {}), [
    function ([, option]) {
      return `${option[descriptionKey]}`.toLowerCase();
    },
  ]).slice(0, displayLimit);

  const resetInput = () => {
    setFilter('');
    onChange('');
  };

  return (
    <Combobox value={value} onChange={onChange}>
      {({ open }) => (
        <div className={`relative z-20  ${compact ? '' : 'mt-1'}`}>
          <div
            className={`relative flex w-full cursor-default flex-col rounded-md border border-surfaces-divider bg-white ${
              compact ? 'p-2' : 'p-3'
            } text-left focus:outline-none sm:text-sm`}
          >
            {open && (
              <p className="absolute left-3 -top-2 bg-white px-1 text-xs text-primary">
                {optionsMemoize[value]?.[descriptionKey] || ''}
              </p>
            )}
            <Combobox.Button className="flex w-full cursor-pointer items-center">
              <Combobox.Input
                className={`flex min-w-0 grow ${
                  compact ? 'text-md' : 'text-lg'
                } placeholder-black-soft outline-none`}
                displayValue={(key: string) =>
                  `${optionsMemoize[key]?.[descriptionKey] || ''}`
                }
                onChange={(event) => setFilter(event.target.value)}
                placeholder={placeholder}
              />
              {allowClear && !!value ? (
                <XCircle
                  className="h-6 w-6 shrink-0 stroke-2 text-black-soft"
                  onClick={resetInput}
                />
              ) : (
                <SelectorIncon
                  className="h-6 w-6 shrink-0 stroke-2 text-black-soft"
                  aria-hidden="true"
                />
              )}
            </Combobox.Button>
          </div>

          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md border bg-white py-1 shadow-lg">
            {optionsArray.length === 0 && filter !== '' ? (
              <div className="space-y-2 py-2 px-3 text-lg">
                {<p className="">{t('common:NoResult')}</p>}
              </div>
            ) : (
              optionsArray.map(([optionKey, option]) => (
                <Combobox.Option
                  key={optionKey}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-primary-soft text-primary' : 'text-black'
                    } ${compact ? 'text-md' : 'text-base'}`
                  }
                  value={optionKey}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option[descriptionKey]}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 text-primary`}
                        >
                          <Check
                            className="h-6 w-6 stroke-2"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  );
};

export default SelectorInput;
