import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form, Field, FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { NB_MAX_CRITERIA } from 'constants/AppConfig';

import { Mode, Navigation, Criterium } from 'types/ui';
import { Activity } from 'types/types';
import {
  setUserActivityDraft,
  getUserActivityDraft,
  useTextReplaceSession,
} from 'model/sessions';
import {
  getCriterias,
  addOrEditCriteriaActivity,
  removeCriteriaFromActivity,
} from 'model/criteriaManagement';
import { sequencialForEach } from 'utils/utils';

import LayoutFormPreview from 'blocks/LayoutFormPreview';
import AutoSave from 'frameworks/formik/AutoSave';
import CongratulationsView from 'blocks/CongratulationView';

import Button from 'components/Button';
import Checkbox from 'frameworks/formik/CheckboxInput';
import Listbox, { Item } from 'components/Listbox';
import TextArea from 'frameworks/formik/TextArea';
import Input from 'frameworks/formik/Input';
import ActivityCriteriaForm from 'blocks/ActivityCriteriaForm';
import Tooltip from 'components/Tooltip';
import Modal from 'components/Modal';

type Criteria = {
  currentValue?: string;
  set: (value: string) => Promise<void>;
  instruction: string;
  setInstruction: (value: string) => Promise<void>;
  options: string[];
  setOptions: (value: string[]) => Promise<void>;
};

type PostData = {
  response: string;
  forcePost: boolean;
  criterias: string[];
};

type PostScreenViewProps = {
  mode?: Mode;
  question: string;
  description: string;
  answerPrompt: string;
  forceNoPost: string;
  allowedNotToPost: boolean;
  criteriaRequired: boolean;
  post?: string;
  setPost: (value: string) => Promise<void>;
  afterSave?: () => void;
  savePost?: (post: string) => void;
  more: string;
  textReplaceSession: (value: string) => string;
};

const RESPONSE_LIMIT = 300;

type CriteriasCompProps = {
  criterias: Criteria[];
};

const Criterias = ({ criterias }: CriteriasCompProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue, errors, touched } =
    useFormikContext<PostData>();
  const formIsTouched = !_.isEmpty(touched);
  return criterias && criterias.length > 0 ? (
    <FieldArray
      name="criterias"
      render={(arrayHelpers) =>
        criterias.map((criteria, index) => {
          const error = errors.criterias?.[index];
          const hasError = !!error && formIsTouched;
          return (
            <div key={index}>
              <label htmlFor={`criterias.${index}`} className="text-base">
                {criteria.instruction}
              </label>
              <Field name={`criterias.${index}`}>
                {() => (
                  <Listbox<string>
                    errorMode={hasError}
                    className="mt-2"
                    maxHClass={
                      index === criterias.length - 1 ? 'max-h-32' : undefined
                    }
                    defaultValue={values.criterias[index]}
                    setValue={(value) =>
                      setFieldValue(`criterias.${index}`, value)
                    }
                    items={
                      criteria.options
                        ? criteria.options.map<Item<string>>((option) => ({
                            description: option,
                            value: option,
                          }))
                        : []
                    }
                    placeholder={t('common:selectOption')}
                  />
                )}
              </Field>
              {hasError ? (
                <div className="font-medium text-danger">{error}</div>
              ) : null}
            </div>
          );
        })
      }
    />
  ) : null;
};

export const PostScreenView = ({
  mode,
  question,
  description,
  answerPrompt,
  forceNoPost,
  criterias,
  allowedNotToPost,
  criteriaRequired,
  post,
  setPost,
  afterSave,
  more,
  savePost,
  textReplaceSession,
}: PostScreenViewProps & { criterias: Criteria[] }): JSX.Element => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [forcePost, setForcePost] = useState(false);

  const memoizedSavedPost = useCallback(
    async (values: PostData) => {
      savePost && savePost(values.response);
    },
    [savePost]
  );

  return (
    <Formik
      enableReinitialize
      validateOnMount
      initialValues={{
        response: post || '',
        criterias: criterias.map((criteria) => criteria.currentValue || ''),
      }}
      validationSchema={Yup.object({
        response: !forcePost
          ? Yup.string()
              .required(t('form:fieldRequired'))
              .min(10, t('sessions:postErrorMinChar'))
              .max(
                RESPONSE_LIMIT,
                t('sessions:postErrorMaxChar', { maxLimit: RESPONSE_LIMIT })
              )
          : Yup.string(),
        criterias: Yup.array().of(
          !!criteriaRequired
            ? Yup.string().required(t('form:fieldRequired'))
            : Yup.string()
        ),
      })}
      onSubmit={async (values) => {
        const promises = [];
        promises.push(setPost(forcePost ? '' : values.response));
        values.criterias.forEach((criteriaValue, index) => {
          const setter = criterias[index].set;
          if (setter) {
            promises.push(setter(criteriaValue));
          }
        });

        try {
          setLoading(true);
          await Promise.all(promises);
          setLoading(false);
          afterSave?.();
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }}
    >
      {({ values }) => (
        <Form className="space-y-4">
          <div>
            <div className="hidden">
              <AutoSave<PostData>
                ignoreErrors
                handleSubmit={memoizedSavedPost}
              />
            </div>
            <div className="mb-4 text-xl font-semibold">
              <p className="whitespace-pre-line">
                {textReplaceSession(question)}
              </p>
            </div>
            <TextArea
              limit={RESPONSE_LIMIT}
              value={values.response}
              name="response"
              disabled={forcePost}
              placeholder={answerPrompt}
              expandable={false}
            />
            <p className="whitespace-pre-line text-sm">{description}</p>
          </div>
          {more && values.criterias.length > 0 ? (
            <h2 className="pt-8 text-lg font-semibold">{more}</h2>
          ) : null}
          <Criterias criterias={criterias} />
          <div
            className={`flex items-center ${
              allowedNotToPost ? 'justify-between' : 'justify-end'
            } pt-12 pb-4`}
          >
            {allowedNotToPost ? (
              <Checkbox name="forcePost" setValue={setForcePost}>
                <span>{forceNoPost}</span>
              </Checkbox>
            ) : null}
            <Button
              type="submit"
              text={t('common:share')}
              disabled={mode === 'editor' || mode === 'preview'}
              loading={loading}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

type PostScreenFormProps = {
  question: string;
  setQuestion: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  answerPrompt: string;
  setAnswerPrompt: (value: string) => void;
  postExample: string;
  forceNoPost: string;
  setForceNoPost: (value: string) => void;
  allowedNotToPost: boolean;
  more: string;
  setMore: (value: string) => void;
};

const PostScreenForm = ({
  question,
  setQuestion,
  description,
  setDescription,
  answerPrompt,
  setAnswerPrompt,
  postExample,
  forceNoPost,
  setForceNoPost,
  criterias,
  allowedNotToPost,
  more,
  setMore,
  addOrEditCriteriaActivity,
  removeCriteriaFromActivity,
  sessionId,
}: PostScreenFormProps & {
  sessionId: string;
  criterias: Criterium[];
  addOrEditCriteriaActivity: (
    criterium: Criterium,
    criteria: Criterium[]
  ) => void;
  removeCriteriaFromActivity: (
    criteriaName: string,
    criteria: Criterium[]
  ) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const commonLabelClassName = 'text-lg text-primary';

  return (
    <Formik
      initialValues={{
        question: question,
        description: description,
        answerPrompt: answerPrompt,
        example: postExample,
        noPostDescription: forceNoPost,
        criterias: criterias,
        more: more,
      }}
      onSubmit={async (values) => {
        if (values.question !== question) {
          setQuestion(values.question);
        }
        if (values.description !== description) {
          setDescription(values.description);
        }
        if (values.answerPrompt !== answerPrompt) {
          setAnswerPrompt(values.answerPrompt);
        }
        if (values.noPostDescription !== forceNoPost) {
          setForceNoPost(values.noPostDescription);
        }

        if (values.more !== more) {
          setMore(values.more);
        }

        const addedCriteria = _.differenceBy(
          values.criterias,
          criterias,
          'name'
        );
        const removedCriteria = _.differenceBy(
          criterias,
          values.criterias,
          'name'
        );
        const updatedCriteria = _.intersectionBy(
          values.criterias,
          criterias,
          'name'
        );

        await sequencialForEach(
          [...addedCriteria, ...updatedCriteria],
          async (criterium) => {
            await addOrEditCriteriaActivity(criterium, values.criterias);
          }
        );
        await sequencialForEach(removedCriteria, async (criterium) => {
          await removeCriteriaFromActivity(criterium.name, values.criterias);
        });
      }}
    >
      {({ values }) => {
        return (
          <Form className="space-y-4">
            <div className="hidden">
              <AutoSave />
            </div>
            <div>
              <TextArea
                name="question"
                label={t('sessions:postQuestionLabel')}
                labelClassName={commonLabelClassName}
                textAreaClassName="h-40"
              />
            </div>
            <div>
              <TextArea
                name="description"
                label={t('sessions:postExampleLabel')}
                labelClassName={commonLabelClassName}
              />
            </div>
            <div>
              <Input
                name="answerPrompt"
                label={t('sessions:postAnswerPromptLabel')}
                labelClassName={commonLabelClassName}
              />
            </div>
            {allowedNotToPost ? (
              <div>
                <Input
                  name="noPostDescription"
                  label={t('sessions:postNoPostLabel')}
                  labelClassName={commonLabelClassName}
                />
              </div>
            ) : null}

            <div>
              <Tooltip
                content={
                  values.criterias.length === 0
                    ? t('sessions:postMoreTooltip')
                    : undefined
                }
              >
                <Input
                  name="more"
                  label={t('sessions:postMoreLabel')}
                  labelClassName={commonLabelClassName}
                  disabled={values.criterias.length === 0}
                />
              </Tooltip>
            </div>

            <ActivityCriteriaForm name="criterias" sessionId={sessionId} />
          </Form>
        );
      }}
    </Formik>
  );
};

type CriteriasProps = {
  criteria0: string;
  criteria1: string;
  criteria2: string;
  setCriteria0: (value: string) => void;
  setCriteria1: (value: string) => void;
  setCriteria2: (value: string) => void;
  criteria0Instruction: string;
  criteria1Instruction: string;
  criteria2Instruction: string;
  setCriteria0Instruction: (value: string) => void;
  setCriteria1Instruction: (value: string) => void;
  setCriteria2Instruction: (value: string) => void;
  criteria0Options: string;
  criteria1Options: string;
  criteria2Options: string;
  setCriteria0Options: (value: string) => void;
  setCriteria1Options: (value: string) => void;
  setCriteria2Options: (value: string) => void;
};

type PostCreateProps = PostScreenFormProps &
  Omit<PostScreenViewProps, 'afterSave'> & {
    navigation: Navigation;
    mode: Mode;
    userId: string;
    sessionId: string;
    activity: Activity;
  } & CriteriasProps;

export const convertPostPropsToCriteria = (
  props: Record<string, any>
): Criteria[] => {
  const criterias: Criteria[] = [];
  for (let i = 0; i < NB_MAX_CRITERIA; i++) {
    if (
      _.get(props, `criteria${i}`) !== undefined &&
      _.get(props, `setCriteria${i}`) !== undefined
    ) {
      criterias.push({
        currentValue: _.get(props, `criteria${i}`),
        set: _.get(props, `setCriteria${i}`),
        instruction: _.get(props, `criteria${i}Instruction`),
        options: _.get(props, `criteria${i}Options`),
        setInstruction: _.get(props, `setCriteria${i}Instruction`),
        setOptions: _.get(props, `setCriteria${i}Options`),
      });
    }
  }

  return criterias;
};

const convertPostPropsToCriteriaForm = (
  props: Record<string, any>
): Criterium[] => {
  const dbCriteria = Object.values(getCriterias(props.activity));
  return dbCriteria.map((criterium, index) => {
    return {
      index: index,
      name: criterium.name,
      description: criterium.description || '',
      instruction: _.get(props, `criteria${index}Instruction`),
      options: criterium.type === 'enum' ? criterium.options : [],
    };
  });
};

const PostScreenCreate = (props: PostCreateProps): JSX.Element => {
  const { t } = useTranslation();
  const [postDraft, setPostDraft] = useState<string | null>(null);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const [ignoreRedirection, setIgnoreRedirection] = useState(false);

  const { post, mode, navigation, userId, sessionId, activity } = props;

  const textReplaceSession = useTextReplaceSession(
    sessionId,
    activity.name,
    userId
  );

  useEffect(() => {
    const getpostDraft = async () => {
      const draft = await getUserActivityDraft(
        sessionId,
        activity.name,
        'post',
        userId
      );
      setPostDraft(draft);
    };
    getpostDraft();
  }, [sessionId, userId, activity.name]);

  useEffect(() => {
    if (
      post &&
      !navigation.state &&
      mode === 'participant' &&
      !ignoreRedirection
    ) {
      navigation?.goToNext?.();
    }
  }, [post, mode, navigation, ignoreRedirection]);

  const savePost = (post: string) => {
    setIgnoreRedirection(true);
    setUserActivityDraft(sessionId, activity.name, 'post', userId, post);
  };

  const formProps: PostScreenFormProps = _.pick(props, [
    'question',
    'setQuestion',
    'description',
    'setDescription',
    'answerPrompt',
    'setAnswerPrompt',
    'postExample',
    'forceNoPost',
    'setForceNoPost',
    'allowedNotToPost',
    'more',
    'setMore',
  ]);

  const viewProps: PostScreenViewProps = {
    ..._.pick(props, [
      'mode',
      'question',
      'description',
      'answerPrompt',
      'forceNoPost',
      'allowedNotToPost',
      'criteriaRequired',
      'setPost',
      'more',
    ]),
    post: post || postDraft || '',
    afterSave: () => navigation?.goToNext?.(),
    textReplaceSession: textReplaceSession,
  };

  const criterias = convertPostPropsToCriteria(props);

  const onClose = () => {
    setShowCongratsModal(false);
    navigation?.goToNext?.();
  };

  return (
    <LayoutFormPreview
      mode={props.mode}
      form={
        <PostScreenForm
          {...formProps}
          sessionId={sessionId}
          criterias={convertPostPropsToCriteriaForm(props)}
          addOrEditCriteriaActivity={(
            criterium: Criterium,
            criteria: Criterium[]
          ) =>
            addOrEditCriteriaActivity(
              sessionId,
              activity.name,
              criterium,
              criteria
            )
          }
          removeCriteriaFromActivity={(
            criteriaName: string,
            criteria: Criterium[]
          ) =>
            removeCriteriaFromActivity(
              sessionId,
              activity.name,
              criteriaName,
              criteria
            )
          }
        />
      }
      view={
        <>
          <PostScreenView
            {...viewProps}
            criterias={criterias}
            savePost={savePost}
            afterSave={() => {
              setShowCongratsModal(true);
            }}
          />
          <Modal
            open={showCongratsModal}
            onClose={onClose}
            body={<CongratulationsView />}
            footer={<Button text={t('common:Close')} onClick={onClose} />}
          />
        </>
      }
    />
  );
};

export default PostScreenCreate;
