import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const passwordSchema = (
  t: TFunction,
  enforcedSecurity: boolean = false
) => {
  const minPasswordChar = enforcedSecurity ? 20 : 10;
  const maxPasswordChar = 255;

  const rule = Yup.string()
    .min(minPasswordChar, t('form:minPasswordChar'))
    .max(maxPasswordChar, t('form:maxPasswordChar'))
    .matches(/.*[a-z].*/, t('form:mustSatisfyComplexity'))
    .matches(/.*[A-Z].*/, t('form:mustSatisfyComplexity'))
    .matches(/.*[0-9].*/, t('form:mustSatisfyComplexity'))
    .matches(/.*[^0-9a-zA-Z].*/, t('form:mustSatisfyComplexity'))
    .required(t('form:fieldRequired'));

  return rule;
};

export const requiresHigherSecurity = (email: string) => {
  const securedDomains = [
    'thalesgroup.com',
    // 'wearepeers.com',
  ];

  return securedDomains.some((securedDomain) => email.endsWith(securedDomain));
};
