import Copyable from 'components/Copyable';
import React, { useCallback } from 'react';
import { Activity, Session } from 'types/types';
import SummaryBloc from './blocks/SummaryBloc';
import { database as db } from 'services/firebase';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import _ from 'lodash';

export type DocumentActivityProps = {
  sessionId: string;
  session: Session;
  activity: Activity;
  summary: string | null;
  marginClasses: string;
};

const DocumentActivities = ({
  sessionId,
  session,
  activity,
  summary,
  marginClasses,
}: DocumentActivityProps): JSX.Element => {
  const { t } = useTranslation();

  const resetSummary = useCallback(async () => {
    await db
      .ref(
        `sessionsNextData/${sessionId}/debrief/summaries/${activity.name}/full`
      )
      .remove();
  }, [sessionId, activity.name]);

  return (
    <div className={`${marginClasses} space-y-2`}>
      <Copyable top={0} textToCopy={summary || ''}>
        <div className="flex flex-row space-x-2">
          <h2 className="text-xl font-semibold">
            {activity.humanName || activity.name}
          </h2>

          <Button
            disabled={_.includes([null, 'loading'], summary)}
            loading={_.includes([null, 'loading'], summary)}
            color="gray"
            text={t('sessions:Regenerate')}
            onClick={() => resetSummary()}
            size="sm"
          />
        </div>
      </Copyable>
      <SummaryBloc
        sessionId={sessionId}
        session={session}
        activity={activity}
        activitySummary={summary}
      />
    </div>
  );
};

export default DocumentActivities;
