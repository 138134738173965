import React from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenContent } from 'model/sessions';
import { useContext } from 'model/dataHooks';
import { Activity, User } from 'types/types';
import { WithId } from 'types/typesUtils';
import { GroupingFacilitationChildrenArguments } from './GroupingFacilitationLayout';
import AugmentPostScreen from '../screens/AugmentPostScreen';
import { useSessionPosts } from 'model/productions';

export type AugmentedPostFacilitationViewProps = {
  sessionId: string;
  activity: Activity;
  groupPrefix: string | undefined;
  user: WithId<User>;
} & GroupingFacilitationChildrenArguments;

export const AugmentedPostFacilitationView = ({
  sessionId,
  activity,
  groupPrefix,
  impersonatedUserId,
  user,
  prods,
  selectedGroup,
}: AugmentedPostFacilitationViewProps): JSX.Element => {
  const { t } = useTranslation();

  const { instructions, experienceTitle, postLabel, titleLabel } =
    useScreenContent(sessionId, activity);

  const context = useContext(sessionId, impersonatedUserId, activity, {
    groupPrefix: groupPrefix,
  });

  const posts = useSessionPosts(sessionId);

  return (
    <AugmentPostScreen
      instructions={instructions}
      posts={posts}
      postTitles={prods}
      sessionId={sessionId}
      userId={impersonatedUserId}
      userName={user?.name || t('users:UnknownUser')}
      editable={false}
      context={context}
      mode={'participant'}
      activity={activity}
      group={selectedGroup}
      experienceTitle={experienceTitle}
      titleLabel={titleLabel}
      postLabel={postLabel}
    />
  );
};

export default AugmentedPostFacilitationView;
