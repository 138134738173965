import React, { forwardRef, useState } from 'react';
import { Field, useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { useOnChange } from './hooks';
import { Expand } from 'assets/icons';
import Modal from 'components/Modal';
import Text from 'antd/lib/typography/Text';

export type TextAreaProps = {
  name: string;
  value?: string;
  label?: string;
  setLabel?: (val: string) => void;
  disabled?: boolean;
  placeholder?: string;
  limit?: number;
  readOnly?: boolean;
  renderEmpty?: () => JSX.Element;
  onChangeValue?: (value: string, hasError: boolean) => void;
  labelClassName?: string;
  textAreaClassName?: string;
  expandable?: boolean;
  editable?: boolean;
};

const TextArea = forwardRef<HTMLInputElement, TextAreaProps>(
  (
    {
      value,
      name,
      label,
      setLabel = () => {},
      disabled = false,
      placeholder = '',
      limit,
      readOnly = false,
      renderEmpty = () => <></>,
      onChangeValue,
      labelClassName = 'text-lg font-semibold',
      textAreaClassName = '',
      expandable = true,
      editable = false,
    },
    ref
  ): JSX.Element => {
    const { t } = useTranslation();
    const [field, meta] = useField(name);
    const [expanded, setExpanded] = useState(false);
    const hasError = meta.touched && meta.error && !disabled;

    const nbChars = value?.length || 0;

    useOnChange(name, onChangeValue);

    const body = (
      <div className="relative flex h-full flex-col">
        {!expanded && expandable && (
          <div
            className={`group absolute right-px -top-4 flex cursor-pointer flex-row items-center space-x-1`}
            onClick={() => setExpanded(true)}
          >
            <p className="text-sm text-black-soft group-hover:text-black">
              {t('common:scaleUp')}
            </p>
            <Expand
              className={`h-5 w-5 cursor-pointer stroke-2 text-black-soft group-hover:scale-110 group-hover:text-primary`}
            />
          </div>
        )}
        <Field
          innerRef={ref}
          disabled={disabled}
          component="textarea"
          className={`mt-2 w-full grow rounded-md border p-3 text-lg ${
            hasError ? 'border-danger' : 'border-surfaces-divider'
          } ${textAreaClassName}`}
          placeholder={placeholder}
          {...field}
          value={field.value || ''}
        />
        <div className="flex shrink-0 flex-row-reverse flex-wrap">
          {limit ? (
            <div
              className={`text-right text-xs lg:grow-0 ${
                hasError ? 'text-danger' : ''
              }`}
            >
              {limit >= nbChars
                ? t('form:remainingChars', { count: limit - nbChars })
                : t('form:XTooManyChars', { count: nbChars - limit })}
            </div>
          ) : null}
          {hasError ? (
            <div className="flex-grow font-medium text-danger">
              {meta.error}
            </div>
          ) : null}
        </div>
      </div>
    );

    return (
      <div className={'w-full'}>
        {label! !== undefined && label !== null ? (
          <label className={labelClassName} htmlFor={name}>
            {editable ? (
              <Text
                editable={
                  editable && {
                    onChange: setLabel,
                  }
                }
              >
                {label}
              </Text>
            ) : (
              label
            )}
          </label>
        ) : null}
        {readOnly ? (
          field.value && field.value.length > 0 ? (
            <p className="mt-2 whitespace-pre-wrap text-lg font-light">
              {field.value}
            </p>
          ) : (
            renderEmpty()
          )
        ) : (
          body
        )}
        <Modal
          open={expanded}
          body={body}
          largeWidth
          className="h-5/6"
          onClose={() => {
            setExpanded(false);
          }}
        />
      </div>
    );
  }
);

export default TextArea;
