import { SessionLanguage } from 'types/baseTypes';
import { bucketFunction } from 'utils/utils';
import { functions } from './firebase';

const computePostSummaryFunction = functions.httpsCallable(
  'automation-rephrasePost'
);

const promptAI = functions.httpsCallable('automation-promptAI');
const detectGender = functions.httpsCallable('automation-detectGender');

const MAX_SIMULTANEOUS_REQUESTS = Number.MAX_SAFE_INTEGER;

const DUMMY_USER_FEMALE = 'Natasha Piaf';
const DUMMY_USER_MALE = 'Pierre Louchenko';

export const computePostSummary = bucketFunction(
  async (post: string, name: string): Promise<string> => {
    const result = await computePostSummaryFunction({
      post,
      name,
    });

    if (!result.data) {
      throw new Error('returned data is empty');
    }

    return result.data;
  },
  MAX_SIMULTANEOUS_REQUESTS
);

export const computeActivitySummary = bucketFunction(
  async (
    sessionTitle: string,
    sessionLanguage: SessionLanguage | undefined,
    activityName: string,
    content: string
  ): Promise<string> => {
    const promptEn = `
    Peoples shared their thought on the following topic: ${sessionTitle} throught several activities.

    During activity named "${activityName}", these notes where taken :
    "
    ${content}
    "

    Summarize these notes in 10 bullets.
  `;

    const promptFr = `
  Les pairs ont partagé sur le sujet "${sessionTitle}" au cours de différentes activités.

  Pendant l'activité intitulée "${activityName}", ces notes ont été prises :
  "
  ${content}
  "

  Résume ces notes en  10 points.
`;

    const result = await promptAI({
      prompt: sessionLanguage === 'en' ? promptEn : promptFr,
    });

    if (!result.data) {
      throw new Error('returned data is empty');
    }

    return result.data;
  },
  MAX_SIMULTANEOUS_REQUESTS
);

export const fixActivityDocument = bucketFunction(
  async (
    sessionLanguage: SessionLanguage | undefined,
    serializedDoc: string
  ): Promise<string> => {
    const promptEn = `
  Summarize this text in several bullets  removing "write here " mentions:
  

  "
  ${serializedDoc}
  "
  `;

    const promptFr = `
  Resumer ce texte en plusieurs points en éliminant les mentions "Ecrire ici":

  "
  ${serializedDoc}
  "
`;

    const result = await promptAI({
      prompt: sessionLanguage === 'en' ? promptEn : promptFr,
    });

    if (!result.data) {
      throw new Error('returned data is empty');
    }

    return result.data;
  },
  MAX_SIMULTANEOUS_REQUESTS
);

export const computeFocus = bucketFunction(
  async (
    sessionLanguage: SessionLanguage | undefined,
    name: string,
    content: string
  ): Promise<string> => {
    const gender = await computeGender(name);

    const dummyUser = gender === 'female' ? DUMMY_USER_FEMALE : DUMMY_USER_MALE;

    const promptEn = `
  Summarize the experience shared by ${name} à la troisième personne.
  
  "
  ${content}
  "
  `;

    const promptFr = `
  Résumer cette expérience partager par ${name} à la troisième personne:

  "
  ${content}
  "
`;

    const prompt = sessionLanguage === 'en' ? promptEn : promptFr;

    const anonymizedPrompt = prompt.replaceAll(name, dummyUser);

    const result = await promptAI({
      prompt: anonymizedPrompt,
    });

    if (!result.data) {
      throw new Error('returned data is empty');
    }

    return result.data.replaceAll(dummyUser, name);
  },
  MAX_SIMULTANEOUS_REQUESTS
);

export const computeGender = async (
  name: string
): Promise<'male' | 'female' | 'unknown'> => {
  const result = await detectGender({ name });

  return result.data;
};
