import { computePostSummary, fixActivityDocument } from 'services/tln';
import { SessionLanguage } from 'types/types';
import { memoizeInDB } from 'utils/utils';

export const getPostSummary = async (
  sessionId: string,
  _sessionLanguage: SessionLanguage | undefined,
  userId: string,
  post: string,
  name: string
): Promise<string> => {
  const path = `sessionsNextData/${sessionId}/debrief/summaries/post/${userId}`;
  const summaryFunc = memoizeInDB(path, () => computePostSummary(post, name));

  return summaryFunc();
};

export const correctActivityDocument = async (
  sessionId: string,
  sessionLanguage: SessionLanguage | undefined,
  docId: string,
  docContent: string
) => {
  const path = `sessionsNextData/${sessionId}/cache/doc/${docId}`;
  const jsonDoc = JSON.stringify(docContent);
  const summaryFunc = memoizeInDB(path, () =>
    fixActivityDocument(sessionLanguage, jsonDoc)
  );

  const res = await summaryFunc();

  return res;
};
